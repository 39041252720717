import { useCallback, useEffect, useState } from "react";
import { Empty, Row } from "antd";
import TemplateHeader from "./Components/TemplateHeader";
import "./index.less";
import TemplateContainer from "./Components/TemplateContainer";
import TemplateModal from "./Components/TemplateModal";
import { getMeasurements } from "../../api/attributeSettingsApis";
import {
  getAllExcelTemplates,
  getManufacturerExcelTemplates,
} from "../../api/excelTemplate";
import TemplatesLoader from "./Components/TemplatesLoader";
import { SIZE_FIELD } from "../../utils/excelTemplateHelpers";

const ExcelTemplates = ({ orgs }) => {
  const [templateType, setTemplateType] = useState("generic");
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [mode, setMode] = useState("create");
  const [uomData, setUomData] = useState({});
  const [templateData, setTemplateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const getUomData = useCallback(async () => {
    try {
      const res = await getMeasurements();
      if (res?.status === 200) {
        const data = res?.data;
        const updatedData = { ...data, ...SIZE_FIELD };
        setUomData(updatedData);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getTemplates = useCallback(() => {
    if (templateType === "generic") {
      // get generic templates
      getGenericTemplates();
    } else {
      // get org specific templates
      if (selectedOrg) {
        getManufacturerTemplates();
      } else {
        setTemplateData([]);
      }
    }
  }, [templateType, selectedOrg]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    getUomData();
  }, [getUomData]);

  const getGenericTemplates = async () => {
    setIsLoading(true);
    try {
      const res = await getAllExcelTemplates();
      if (res?.status === 200) {
        const data = res?.data?.data;
        const genericTemplates = data?.filter(
          (item) => item?.isGeneric && item?.status !== "archived"
        );
        setTemplateData(genericTemplates);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getManufacturerTemplates = async () => {
    setIsLoading(true);
    try {
      if (selectedOrg) {
        const res = await getManufacturerExcelTemplates(selectedOrg);
        if (res?.status === 200) {
          const data = res?.data?.data;
          const manufacturerTemplates = data?.filter(
            (item) => !item?.isGeneric && item?.status !== "archived"
          );
          setTemplateData(manufacturerTemplates);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <TemplateHeader
        {...{
          orgs,
          selectedOrg,
          setSelectedOrg,
          setIsTemplateModalVisible,
          templateType,
          setTemplateType,
        }}
      />
      {isLoading ? (
        <TemplatesLoader />
      ) : templateData?.length > 0 ? (
        <div className="excel-template-container-row">
          <Row>Showing {templateData?.length} template(s)</Row>
          {templateData?.map((template) => (
            <TemplateContainer
              template={template}
              key={template?.id}
              setIsTemplateModalVisible={setIsTemplateModalVisible}
              setMode={setMode}
              setSelectedTemplate={setSelectedTemplate}
            />
          ))}
        </div>
      ) : (
        (selectedOrg || templateType === "generic") && (
          <Empty className="excel-templates-empty-container" />
        )
      )}
      <TemplateModal
        {...{
          isTemplateModalVisible,
          setIsTemplateModalVisible,
          mode,
          setMode,
          uomData,
          selectedTemplate,
          setSelectedTemplate,
          selectedOrg,
          setTemplateData,
          templateData,
        }}
      />
    </>
  );
};

export default ExcelTemplates;
