import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Divider,
  Typography,
  Select,
  Switch,
  Collapse,
  message,
  List,
  Button,
  PageHeader,
} from "antd";
import API_KEY from "../../api";
import { useNavigate } from "react-router-dom";
import PYTHON_KEY from "../../api/pythonApis";
const { Title, Text } = Typography;
const { Option } = Select;

export default function Index({ orgs }) {
  const { Panel } = Collapse;
  const [org, setOrg] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [orgs, setOrgs] = useState([]);
  const [featureData, setFeatureData] = useState({});
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subCatModal, setShowSubCatModal] = useState(false);
  const [subCatDetails, setSubCatDetails] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [slugModal, setShowSlugModal] = useState(false);
  const [manufacturerSlug, setSlug] = useState("");

  // Modals
  const showModal = (id) => {
    setIsModalVisible(true);

    setOrganizationId(id);
    console.log(id);
  };

  const handleSlug = (record) => {
    setSlug(record.slug && record.slug !== "-" ? record.slug : "");
    setOrganizationId(record.id);
    setShowSlugModal(true);
  };

  const handleViewSubCat = (record) => {
    setSubCatDetails(record);
    setOrganizationId(record.id);
    setShowSubCatModal(true);
  };

  useEffect(() => {
    setOrg(
      orgs.map((org, index) => {
        return {
          key: index,
          id: org?.id,
          name: org?.name ?? " -- ",
          city: org?.city ?? " -- ",
          country: org?.country ?? " -- ",
          type: org?.type ?? "--",
          slug: org.slug ?? "-",
          recommendation_preferences: {
            ...org.recommendation_preferences,
            sub_category:
              org?.recommendation_preferences?.sub_category || false,
          },
        };
      })
    );
  }, [orgs]);

  const fetchActiveFeatures = useCallback(() => {
    if (selectedOrg) {
      setLoading(true);
      PYTHON_KEY.URL.get(PYTHON_KEY.path.getActiveFeatures(selectedOrg)).then(
        (res) => {
          const { permissions = [], enabled_features = [] } = res?.data;
          setFeatureData({ ...{ permissions, enabled_features } });
          setLoading(false);
        }
      );
    }
  }, [selectedOrg]);

  /// get all org api call
  useEffect(() => {
    fetchActiveFeatures();
  }, [fetchActiveFeatures]);

  // handle orgnization change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    setSelectedCategory(null);
  };

  const handleToggleFeature = (value, currentFeature) => {
    PYTHON_KEY.URL.post(PYTHON_KEY.path.toggleFeature, {
      mid: selectedOrg,
      toggle_value: value,
      feature_id: currentFeature?.id,
    })
      .then((res) => {
        const msg = value ? "Feature Enabled" : "Feature Disabled";
        message.success(msg);
        fetchActiveFeatures();
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.error(err);
      });
  };

  const renderSwitch = ({ enabledFeatures = [], currentFeature = {} }) => {
    const isEnabled = enabledFeatures
      .map((item) => item?.permission_type)
      .includes(currentFeature?.id);
    return (
      <Switch
        onChange={(value) => handleToggleFeature(value, currentFeature)}
        // disabled={isEnabled}
        checked={isEnabled}
      />
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            title={
              <Row>
                <Title level={3} style={{ fontFamily: "Gilroy" }}>
                  Features
                </Title>
              </Row>
            }
            subTitle="Enable features for specific accounts"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Select
            showSearch
            style={{ width: "80%" }}
            placeholder="Search by MID or name"
            onChange={handleOrgChange}
            filterOption={(input, option) => {
              return (
                option?.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0 || option.value.toString() === input
              );
            }}
            allowClear
            onClear={() => setSelectedOrg("")}
          >
            {org.map((org) => (
              <Option key={org?.id} value={org?.id}>
                {org?.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row style={{ height: "600px" }}>
        <Col span={24}>
          {Object.keys(featureData).length ? (
            <List>
              {(featureData?.permissions || []).map((item, index) => {
                return (
                  <List.Item
                    actions={[
                      selectedOrg
                        ? renderSwitch({
                            currentFeature: item,
                            enabledFeatures: featureData.enabled_features,
                          })
                        : null,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <>
                          <div>{item.label}</div>
                          <div></div>
                        </>
                      }
                      key={(index + 1).toString()}
                      description={
                        <ul>
                          {(item.info || []).map((info) => {
                            return (
                              <li>
                                <Text type="primary">{info}</Text>
                              </li>
                            );
                          }) || ""}
                        </ul>
                      }
                    ></List.Item.Meta>
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <div>
              <Text type="primary">Select a Manufacturer</Text>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
