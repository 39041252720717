import React, { useState,useCallback } from 'react';
import { Row, Typography,Col,Select, Divider} from 'antd';
import Button from "../AttributeSettingComponents/Button"
import { useDispatch ,useSelector } from 'react-redux';
import { getorgs } from '../../../redux/actions/attributeSettingActions';
import { useEffect } from 'react';


const { Title } = Typography;
const {Option} = Select

function AttributeSettingHeader({ setIsDrawerOpen, options }) {
  const [orgs, setOrgs] = useState([])
  const selectedOrg = useSelector(state => state.attributeSetting.getOrgs)
  const dispatch = useDispatch()

  const CreateNewAttribute = () => {
    setIsDrawerOpen(true);
  };

  const handleOrgChange = (value)=>{
    dispatch(getorgs(value))
  }


  const fetchOrgs = useCallback(() => {
    const mappedData = options.map((org, index) => {
      return {
        key: index,
        id: org?.id,
        name: org?.name ?? ' -- ',
        city: org?.city ?? ' -- ',
        country: org?.country ?? ' -- ',
        type: org?.type ?? '--',
        slug: org.slug ?? '-',
        recommendation_preferences: {
          ...org.recommendation_preferences,
          sub_category: org?.recommendation_preferences?.sub_category || false,
        },
      };
    });
    setOrgs(mappedData)
  }, [options]);


  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs])


  return (
    <>
      <Row justify="space-between">
        <Col>
          <Title level={4}>Attribute Setting</Title>
        </Col>

        <Col>
          <Button
            onClick={CreateNewAttribute}
            style={{ width: '140px' }}
            variant={selectedOrg ? 'dark' : ''}
            disabled={selectedOrg ? false : true}
          >
            Create new
          </Button>
        </Col>
      </Row>
      <p>Manage the system's attributes all at one place</p>
      <Row style={{ margin: "25px 0px" }}>
        <Col span={6}>
          <Select
            showSearch
            style={{ width: "80%" }}
            placeholder="Select Organization"
            optionFilterProp="children"
            onSelect={handleOrgChange}
          >
            {orgs.map((org) => (
              <Option key={org?.id} value={org?.id}>
                {org?.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider/>
    </>
  );
}

export default AttributeSettingHeader;
