import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Typography,
  Divider,
  Input,
  notification,
  Select,
  Checkbox,
} from "antd";
import API_KEY from "../../api/index";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
const { Title, Text } = Typography;

export default function UserForm() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isInternal, setIsInternal] = useState(true);
  const [isOtpEnabled, setIsOtpEnabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        limit: -1,
        fields:
          "id,name,secondary_categories.secondary_category_id.name,secondary_categories.secondary_category_id.id",
        filter: {
          _and: [
            {
              status: {
                _nin: ["archived"],
              },
            },
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },
            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
            {
              name: {
                _ncontains: "Test",
              },
            },
          ],
        },
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
      }
    });
  }, []);

  const onFinish = (values) => {
    const validEmail = values?.email?.toLowerCase();
    const payload = { ...values, email: validEmail };
    setLoading(true);
    API_KEY.URL.post(API_KEY.path.users, { ...payload, is_admin: isAdmin, is_system_user: isInternal, meta: { otp_verification: isOtpEnabled } })
      .then((res) => {
        if (res.status === 200) {
          form.resetFields();
          setLoading(false);
          notification.success({
            message: "Success",
            description: "User added successfully",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };
  const onFinishFailed = (errorInfo) => { };
  // handle org change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={
          <Row>
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Create User
            </Title>
            <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
              {/* ({total}) */}
            </Text>
          </Row>
        }
        subTitle="Create user"
      />
      <Divider />
      <Row style={{ minHeight: "75vh" }}>
        <Col span={12} offset={2}>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              role: "418bd52e-13b8-4500-a6a7-2a4587a1b62d",
            }}
          >
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="last_name" label="Last Name">
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, type: "email" }]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item name="org" label="Organization">
              <Select
                placeholder="Select Organization"
                onChange={handleOrgChange}
                value={selectedOrg}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allOrgsData.map((org, index) => (
                  <Select.Option value={org?.id} key={index}>
                    {org?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item hidden label="Role" name="role">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Is Admin ?" name="is_admin">
              <Checkbox
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              >
                Is Admin
              </Checkbox>
            </Form.Item>
            <Form.Item label="Is Internal ?" name="is_internal">
              <Checkbox
                checked={isInternal}
                onChange={(e) => setIsInternal(e.target.checked)}
              >
                Is Internal
              </Checkbox>
            </Form.Item>

            <Form.Item label="OTP enabled ?" name="otp_enabled">
              <Checkbox
                checked={isOtpEnabled}
                onChange={(e) => setIsOtpEnabled(e.target.checked)}
              >
                Otp Enabled
              </Checkbox>
            </Form.Item>

            <Form.Item label=" ">
              <Row justify="space-between">
                <Button size="large" onClick={() => navigate(-1)}>
                  Cancel
                </Button>

                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
