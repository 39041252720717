export const ActionTypes = Object.freeze({
  SELECT_TAB: "sidebarActions/SELECT_TAB",
});

export function selectTab(key) {
  return {
    type: ActionTypes.SELECT_TAB,
    payload: {key:key},
  };
}

const ACTION_HANDLERS = {
  ActionTypes,
  selectTab
};

export default ACTION_HANDLERS;
