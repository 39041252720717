import { Space, Row, Image } from 'antd';
import React from 'react';
import emptyAttribute from '../../../assets/images/emptyAttribute.svg';
import Button from "../AttributeSettingComponents/Button"
function EmptyAttribute({ handleOpenDrawer }) {
  return (
    <Row justify="center">
      <Space
        direction="vertical"
        className="emptyAttributeContainer"
        size="larger">
        <Image src={emptyAttribute} preview={false} />
        <h5 className="panelHeader">No attributes added</h5>
        <p>Create new or add existing attributes here</p>
        <Button onClick={() => handleOpenDrawer()} variant="primary" style={{ fontSize: '14px' }}>
          Create attribute
        </Button>
      </Space>
    </Row>
  );
}

export default EmptyAttribute;
