import { Row, Col, Tag, Divider } from "antd";

export default function ValidationDiv({ valid }) {
  return (
    <Row>
      <Col>
        <>
          {valid?.duplicate_product_ids?.length ? (
            <>
              <Row gutter={20}>
                <Col>Duplicate Products of type "product": </Col>
                <Col>
                  {valid?.duplicate_product_ids?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="red">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          ) : (
            <></>
          )}

          {valid?.empty_variant_id?.length ? (
            <>
              <Row gutter={20}>
                <Col>Variant Rows with empty Variant IDs: </Col>
                <Col>
                  {valid?.empty_variant_id?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="red">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          ) : (
            <></>
          )}

          {valid?.invalid_hinges?.length ? (
            <>
              <Row gutter={20}>
                <Col>Invalid Hinges: </Col>
                <Col>
                  {valid?.invalid_hinges?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="red">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          ) : (
            <></>
          )}

          {valid?.missing_columns?.length ? (
            <Row gutter={20}>
              <Col>Missing Columns: </Col>
              <Col>
                {valid?.missing_columns?.map((item) => (
                  <Tag style={{ marginTop: 5, marginRight: 5 }} color="magenta">
                    {item}
                  </Tag>
                ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {valid?.invalid_products?.length > 0 && (
            <>
              <Row gutter={20}>
                <Col>Invalid DB IDs: </Col>
                <Col>
                  {valid?.invalid_products?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="red">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          )}

          {valid?.extra_columns?.length > 0 && (
            <>
              <Row gutter={20}>
                <Col>Extra Columns: </Col>
                <Col>
                  {valid?.extra_columns?.map((item) => (
                    <Tag
                      style={{ marginTop: 5, marginRight: 5 }}
                      color="magenta"
                    >
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          )}
          {valid?.new_categories?.length > 0 && (
            <>
              <Row gutter={20}>
                <Col>New categories: </Col>
                <Col>
                  {valid?.new_categories?.map((item) => (
                    <Tag
                      style={{ marginTop: 5, marginRight: 5 }}
                      color="#2db7f5"
                    >
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          )}

          {valid?.no_main_product_found?.length > 0 && (
            <>
              <Row gutter={20}>
                <Col>No Main product with type "product": </Col>
                <Col>
                  {valid?.no_main_product_found?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="red">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          )}

          {valid?.invalid_category?.length > 0 && (
            <>
              <Row gutter={20}>
                <Col>Product and variant category mistmatch: </Col>
                <Col>
                  {valid?.invalid_category?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="red">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          )}

          {valid?.unusedAttributes?.length > 0 && (
            <>
              <Row gutter={20}>
                <Col>Unused Attributes: </Col>
                <Col>
                  {valid?.unusedAttributes?.map((item) => (
                    <Tag style={{ marginTop: 5, marginRight: 5 }} color="blue">
                      {item}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          )}
        </>

        {/* <div>
        <span>ExtraColumns in sheet: </span>{" "}
        <span>{valid?.extra_columns?.join(",")}</span>
      </div>
      <div>
        <span>Unused attributes: </span>{" "}
        <span>{valid?.unusedAttributes?.join(",")}</span>
      </div> */}
      </Col>
    </Row>
  );
}
