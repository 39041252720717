import { Card, Skeleton } from "antd";

const TemplatesLoader = () => {
  return (
    <Card className="excel-template-container-row">
      <Skeleton active />
    </Card>
  );
};

export default TemplatesLoader;
