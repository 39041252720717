import { Col, DatePicker, Input, InputNumber, Radio, Row, Select, Space, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import Title from "antd/lib/typography/Title";
import { CodePipeline } from "aws-sdk";
import moment from "moment";

export default function SelectPlanBody(
    { 
        selectedOrg, 
        selectedRow, 
        setSelectedRow, 
        setFinalData, 
        finalData, 
        selectedRowId,
        isPlanUpdating,
    }) {
    const [plans, setPlans] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [data, setData] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);
    const fetchPlans = (selectedOrg) => {
        setLoading(true);
        setPlans([]);
        axios.get(`${process.env.REACT_APP_INTERNAL_API}plan/upgrade`, 
                { 
                    params: {
                        "m_id":selectedOrg,
                        "access_token": user?.access_token
                    },
                    headers:{
                        "access-token":user?.access_token
                    }
                }
            ).then((res) =>{
                const plan_data = res?.data;
                if (res?.status === 200 && plan_data){
                    let unsorted_data = plan_data?.plan;
                    const temp_sorted_data = unsorted_data?.sort((a, b) =>{
                        return b?.priority - a?.priority;
                    });
                    let sorted_data = {}
                    const plan_list = temp_sorted_data?.map((item)=>{
                        sorted_data[item?.id] = item;
                        return {
                            label: `${item?.label} (${item?.name})`,
                            value: item?.id,
                            key: item?.id,
                        }
                    });
                    setPlans(plan_list);
                    setData(sorted_data);
                    if (Object.keys(sorted_data).includes(selectedRow)){
                        console.log(finalData);
                        if(!isPlanUpdating) {
                            setFinalData({...sorted_data[selectedRow], start_date: moment(Date.now()).format("YYYY-MM-DD"), end_date: moment(Date.now()).add(1,"year").format("YYYY-MM-DD")});
                        }
                    }

                    //getting discounts
                    axios.get(`${process.env.REACT_APP_INTERNAL_API}discount/`, 
                        { 
                            params: {
                                "access_token": user?.access_token
                            },
                            headers:{
                                "access-token":user?.access_token
                            }
                        }
                    ).then((res) => {
                        if(res?.status===200){
                            const discount_data = res?.data;
                            const discount_list = discount_data.map((item)=>{
                                return {
                                    label: `${item?.name} (${item?.description})`,
                                    value: item?.id,
                                    key: item?.id,
                                }
                            });
                            setDiscounts(discount_list);
                        }
                    }).catch((err)=>{
                        //setLoading(false);
                        console.log(err);
                    });

                    //getting currencies
                    axios.get(`${process.env.REACT_APP_INTERNAL_API}currency/`, 
                        { 
                            params: {
                                "access_token": user?.access_token
                            },
                            headers:{
                                "access-token":user?.access_token
                            }
                        }
                        ).then((res) => {
                            if(res?.status===200){
                                const currency_data = res?.data;
                                const currency_list = currency_data.map((item)=>{
                                    return {
                                        label: `${item?.code} (${item?.symbol})`,
                                        value: item?.id,
                                        key: item?.id,
                                    }
                                });
                                setCurrencies(currency_list);
                            }
                        }).catch((err)=>{
                            //setLoading(false);
                            console.log(err);
                        });
                    setLoading(false);
                }
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
        
    }
    useEffect(() => {
        console.log(finalData);
        debugger;
        setPlans([]);
        setSelectedRow(selectedRow);
        fetchPlans(selectedOrg);
    }, [selectedRowId]);
    
    useEffect(() => {
        console.log(finalData);
        if (!isPlanUpdating && Object.keys(data).includes(selectedRow)){
            setFinalData({...finalData, ...data[selectedRow]});
        }
        console.log(finalData);
    }, [selectedRow]);

    const updateFinalData = (key, val) => {
        console.log(finalData);
        
        let newData = finalData;
        if (key==="start_date" || key==="end_date"){
            newData = {...newData,[key]:moment(val).format("YYYY-MM-DD")};
        }else{
            if (newData){
                newData = {...newData,[key]:val}
            }else{
                newData = {[key]:val}
            }
        }
        setFinalData({...finalData,...newData});
    }

    const updateData = (key, val) => {
        
        if (val && key && data[selectedRow]){
            let newData = data[selectedRow];
            if(newData){
                newData = {...newData, [key]:val}
            }else{
                newData = {[key]:val}
            }
            setData({...data,selectedRow:{...data[selectedRow],...newData}});
        }
    }

    return (
        <>
            <Spin spinning={loading || !finalData} >
            <Space direction="vertical" style={{width:"100%", justifyContent:"space-between", lineHeight:2}} >
                <Row className="modal-row" >
                    <Col>
                        <Row>
                            <Text type="secondary" >
                                Plan:
                            </Text>
                        </Row>
                        <Row>
                            <Select 
                                options={plans} 
                                value={selectedRow} 
                                onChange={(e)=>{
                                    setSelectedRow(e);
                                    if(data[selectedRow]){
                                        setFinalData({...finalData,...data[selectedRow]})
                                    }else{
                                        setFinalData({start_date:finalData?.start_date, end_date:finalData?.end_date});
                                    }
                                }}
                                style={{width:"100%"}} 
                                placeholder="Select Plan"
                                disabled={loading || isPlanUpdating}
                                />
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-between" className="modal-row" >
                    <Col span={12} style={{paddingRight:5}} >
                        <Row>
                            <Text type="secondary" >
                                Start Date
                            </Text>
                        </Row>
                        <Row>
                            <DatePicker 
                                showToday 
                                style={{width:"100%"}} 
                                value={finalData?.start_date? moment(finalData?.start_date):""} 
                                disabled={loading}
                                onChange={(date, dateString)=>{
                                    if(date){
                                        updateFinalData("start_date", date);
                                    }
                                }} />
                        </Row>
                    </Col>
                    <Col span={12} style={{paddingLeft:5}} >
                        <Row>
                            <Text type="secondary" >
                                End Date
                            </Text>
                        </Row>
                        <Row>
                            <DatePicker 
                                showToday 
                                style={{width:"100%"}} 
                                value={finalData?.end_date? moment(finalData?.end_date):""} 
                                disabled={loading}
                                onChange={(date, dateString)=>{
                                    if(date){
                                        updateFinalData("end_date", date);
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col style={{width:"100%"}}>
                        <Row>
                            <Text type="secondary" >
                                Amount:
                            </Text>
                        </Row>
                        <Row>
                            <Input.Group compact >
                                <InputNumber 
                                    value={parseFloat(finalData?.amount)} 
                                    style={{width:"70%"}} 
                                    controls={false}
                                    disabled={loading}
                                    onChange={(val)=>{
                                        updateFinalData("amount", val);
                                    }}
                                    stringMode />
                                <Select 
                                    options={currencies} 
                                    style={{width:"30%"}}
                                    value={finalData?.currency_id}
                                    disabled={loading}
                                    onChange={(value)=>{
                                        updateFinalData("currency_id",value);
                                        updateData("currency_id",value);
                                    }}
                                />
                            </Input.Group>
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col>
                        <Row>
                            <Text type="secondary" >
                                Discount:
                            </Text>
                        </Row>
                        <Row>
                            <div style={{width:"100%", minWidth:"100%"}} >
                            <Select 
                                options={discounts} 
                                style={{width:"100%", minWidth:"100%"}}
                                placeholder="Select Discount"
                                disabled={loading}
                                value={finalData?.discount}
                                onChange={(value)=>{
                                    updateFinalData("discount",value);
                                }}
                            />    
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Space>
            </Spin>
        </>
    )
}