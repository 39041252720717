import React, { useEffect, useState } from 'react';
import { Modal, Typography, Row, Form, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdCheckCircle } from 'react-icons/md';
import { toggleModal,fetchData } from '../../../redux/actions/attributeSettingActions'
import Button from "../AttributeSettingComponents/Button"
import { deleteAttribute } from '../../../api/attributeSettingsApis';
import { openNotification } from '../AttributeSettingComponents/Notification';
const { Title } = Typography;

function AttributeModal() {
  const modalType = useSelector((state) => state.attributeSetting.modalType);
  const modalState = useSelector((state) => state.attributeSetting.modalState);
  const selectedOrg = useSelector(state => state.attributeSetting.getOrgs)
  const deleteAttributeElement = useSelector((state) => state.attributeSetting.deleteAttribute);
  const changeState = useSelector((state) => state.attributeSetting.fetchData);
  const [loading, SetLoading] = useState(false);
  const [checkBox, SetCheckBox] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  const handleDeleteAttribute = () => {
    let id = deleteAttributeElement?.id;
    let type = "product";
    handleDeleteApi(id,type,selectedOrg)
  };

  const handleDeleteApi = async (id,type,manufacturerId)=>{ 
    SetLoading(true);
    deleteAttribute(id,"product",manufacturerId)
      .then((res) => {
        if (res?.status == 200) {
          SetLoading(false);
          openNotification(
            'top',
            'Attribute deleted successfully',
            <MdCheckCircle style={{ color: 'rgb(0,119,100)' }} />
          );
          dispatch(fetchData(!changeState));
          dispatch(
            toggleModal({ modalType: 'deleteAttribute', modalState: false })
          );
          SetCheckBox(false);
        }
      })
      .catch((err) => {
        SetLoading(false);
        console.error(err);
        openNotification(
          'top',
          'Something went wrong please try again',
          <IoMdCloseCircle style={{ color: 'red' }} />
        );
      });
  }

  const handleCancel = () => {
    dispatch(toggleModal({ modalState: false, modalType: 'deleteAttribute' }));
    SetCheckBox(false);
  };

  const handleModal = () => {
    if (modalType === 'deleteAttribute') {
      return (
        <>
          <Title level={4}>Delete {deleteAttributeElement?.name?.toLowerCase()}</Title>
          <Row className="deleteAttribute">
            <p className="deleteAttributeText">
              Deleting attribute will remove this detail from all products
              permanently. This can't be undone. Are you sure you want to
              delete?
            </p>
            <Checkbox
              name="delete"
              checked={checkBox}
              onChange={(e) =>
                e.target.checked ? SetCheckBox(true) : SetCheckBox(false)
              }>
              Yes, I am sure
            </Checkbox>
          </Row>
          <Row justify="space-between">
            <Button
              style={{ width: '160px' }}
              onClick={handleCancel}
              variant={'outline_gray'}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={handleDeleteAttribute}
              style={{ width: '160px' }}
              variant={checkBox && 'outline_red'}
              disabled={checkBox ? false : true}>
              Delete
            </Button>
          </Row>
        </>
      );
    }
  };

  const handleClose = () => {
    dispatch(toggleModal({ modalType: 'createSection', modalState: false }));
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      centered
      className="attributeSettingsModal"
      visible={modalState}
      onCancel={handleClose}
      closable={false}
      closeIcon={null}>
      {handleModal()}
    </Modal>
  );
}

export default AttributeModal;
