import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Typography,
  Popconfirm,
  Spin,
  Select,
} from "antd";
import { DeleteFilled, SettingFilled } from "@ant-design/icons";
import API_KEY from "../../api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const { Title, Text } = Typography;

export default function Categories() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [orgs, setOrgs] = useState([]);
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const filters = () => {
    var filters;
    if (selectedCategory) {
      filters = { secondary_category: { id: { _eq: selectedCategory } } };
    }
    if (selectedOrg) {
      filters = { manufacturer_id: { _eq: selectedOrg } };
    }

    if (selectedCategory && selectedOrg) {
      filters = {
        // "product_id": { "_eq": "12023" },
        secondary_category: { id: { _eq: selectedCategory } },
        manufacturer: { _eq: selectedOrg },
      };
    }
    return filters;
  };

  const fetchCategories = (page, selectedCategory, selectedOrg) => {
    setLoading(true);
    API_KEY.URL.get(API_KEY.path.categories, {
      params: {
        fields:'id,manufacturer_id.name,secondary_category_id.name',
        // limit: -1,
        meta: "*",
        // sort:"sort,-date_created",
        page: page,
        filter: filters(),
      },
    })
      .then((res) => {
        if (res?.data?.meta) {
          setTotal(res.data.meta.filter_count);
        }
        if (res?.data?.data) {
          console.log("data", res?.data?.data);
          setProducts(
            res?.data?.data.map((cat, index) => {
              return {
                key: index,
                id: cat?.id,
                name: cat?.secondary_category_id?.name,
                manufacturer: cat?.manufacturer_id?.name,
               
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }

  /// get all products api call
  useEffect(() => {
    fetchCategories(page, selectedCategory, selectedOrg);
  }, [page, selectedCategory, selectedOrg]);

  // get all orgnizations api call
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        fields:
          "id,name,secondary_categories.secondary_category_id.name,secondary_categories.secondary_category_id.id",
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
        setOrgs(
          res?.data?.data.map((org) => {
            return {
              label: `${org.name}`,
              value: org.id,
              key: org.id,
            };
          })
        );
      }
    });
  }, []);

  // create cloumns for table
  const columns = [
    
   
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
  
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      align: "center",
    }
  ];

  // handle delete product
  const handleDelete = (record) => {
  
    API_KEY.URL.delete(API_KEY.path.products + "/" + record?.id).then((res) => {
      console.log(res);
      fetchCategories(page, selectedCategory, selectedOrg);
    });
  };

  // handle orgnization change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    setSelectedCategory(null);

    const findCategories = allOrgsData.find((org) => org.id === value);

    setCategories(
      findCategories.secondary_categories.map((category) => {
        return {
          label: category?.secondary_category_id?.name,
          value: category?.secondary_category_id?.id,
          key: category?.secondary_category_id?.id,
        };
      })
    );
  };

  // handle category change
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      key: record.key,
    }),
  };
const handleDeleteAll = () => {
  setLoading(true);
  API_KEY.URL.delete(API_KEY.path.products, {
    data: 
   selectedRows.map((row) => row.id),
    
  }).then((res) => {
    setLoading(false);
    console.log(res);
    setSelectedRows([]);
    fetchCategories(page, selectedCategory, selectedOrg);
   

  }).catch(
    (err) => {
      setLoading(false);
      console.log(err);
    }
  )
}
  return (
    <div>
      {/* page header starts here */}
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={
              <Row>
                <Title level={3} style={{fontFamily:'Gilroy'}}>Categories</Title>
                <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
                  ({total})
                </Text>
              </Row>
            }
            subTitle="List of all categories in the system and you can do operations on them"
            extra={
              <Button
                type="primary"
                onClick={() => navigate("/category-form")}
              >
              Create New
              </Button>
            }
          />
        </Col>
      </Row>
      {/* page header ends here */}
      {/* filter header starts here */}
      <Row>
        <Col span={6}>
          <Select
            style={{ width: "80%" }}
            placeholder="Select Organization"
            options={orgs}
            onChange={handleOrgChange}
            showSearch
            filterOption={(input, option) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          ></Select>
        </Col>
       
       
      </Row>
      {/* filter header ends here */}
      <Divider />
      {/* table header starts here */}
      <Row>
        <Col span={24}>
          <Spin spinning={loading} size="large">
            <Table
              dataSource={products}
              columns={columns}
              bordered
              size="large"
              pagination={{ pageSize: pageSize, current: page, total: total }}
              onChange={(pagination, filters, sorter) => {
                setPageSize(pagination?.pageSize);
                setPage(pagination?.current);
              }}
            />
          </Spin>
        </Col>
      </Row>
      {/* table header ends here */}
    </div>
  );
}
