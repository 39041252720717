import { useDispatch,useSelector} from 'react-redux';
import AttributeSection from './AttributeSection';
import { useEffect, useState } from 'react';
import { getAttributeSectionData,getAllAttributesData} from '../../../redux/actions/attributeSettingActions';
import { getAllAttribute } from '../../../api/attributeSettingsApis';
import { getTabName } from '../utils';

function AttributeSettingSectionPage({ handleOpenDrawer }) {
  const [loading, setLoading] = useState(false);
  const selectedOrg = useSelector(state => state.attributeSetting.getOrgs)
  const fetchData = useSelector((state) => state.attributeSetting.fetchData);
  const dispatch = useDispatch();

  useEffect(() => {
    if(selectedOrg){
      getAttributesData(selectedOrg);
    }
  }, [selectedOrg,fetchData]);

  const getAttributesData = async (id) => {
      setLoading(true);
      getAllAttribute(id).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          handleSorting(res?.data);
          dispatch(getAllAttributesData(res?.data));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
   };

  const handleSorting = (data) => {
    let sorted = data.sort((a, b) => (b.priority - a.priority))
    handleDtypeName(sorted);
  }

  const handleDtypeName = (data) => {
    let dTypeNameData = data?.map((item) => {
      let dType = item?.dtype;
      switch (dType) {
        case 'textarea':
          return {
            ...item,
          dTypeName: 'Long text',
          };
        case 'select':
          let modeType = '';
          if (item?.default?.mode === 'single') {
            modeType = 'Single Select';
          } else {
            modeType = 'Multi Select';
          }
          return {
            ...item,
            dTypeName: modeType,
          };
        case 'multi_select':
          return {
            ...item,
            dTypeName: 'Multi Select',
          };
        case 'integer':
        case 'decimal':
          if (item?.default?.precision >= 1) {
            return {
              ...item,
              dTypeName: 'Decimal',
            };
          } else {
            return {
              ...item,
              dTypeName: 'Integer',
            };
          }
          case 'size':
            return {
              ...item,
              dTypeName: 'Dimension',
            };
          case 'url':
            return {
              ...item,
              dTypeName: 'URL',
            };
          case 'uom':
            return {
              ...item,
              dTypeName: 'UOM',
            };  
        default: {
          return {
            ...item,
            dTypeName: dType,
          };
        }
      }
    });
    filterAttributeData(dTypeNameData);
  };


  const filterAttributeData = (data) => {
    let sectionData = [];
    getTabName.forEach((element, index) => {
      sectionData.push({
        id: index,
        name: element.label,
        attributes: data?.filter((items) => items.tabname === element.tabname),
      });
    });
    dispatch(getAttributeSectionData(sectionData));
  };

  return (
    <div className="attributeContainer">
      <AttributeSection loading={loading} handleOpenDrawer={handleOpenDrawer}/>
    </div>
  );
}

export default AttributeSettingSectionPage;
