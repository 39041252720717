import React, { useState } from "react";
import {
  Button,
  Modal,
  Input,
  DatePicker,
  Form,
  Select,
  Space,
  message,
} from "antd";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { PlusCircleFilled } from "@ant-design/icons";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import axios from "axios";
export const CreateInvoice = ({ selectedOrg, setDataSource }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (values) => {
    const user = JSON.parse(localStorage.getItem("userData") || "");
    setBtnLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_INTERNAL_API}invoice/`,
        { ...values, customer_id: selectedOrg },
        {
          headers: {
            "access-token": user?.access_token,
          },
        }
      )
      .then((res) => {
        const newRowItem = Object.keys(res.data).reduce((agg, item) => {
          return {
            ...agg,
            [item]:
              item === "invoice_date"
                ? moment(res.data[item]) || null
                : res.data[item],
          };
        }, {});
        setDataSource((state) => {
          return [newRowItem, ...state];
        });
        setResetFields(true);
        setBtnLoading(false);
        setIsModalVisible(false);
        message.success("Invoice created successfully");
      })
      .catch((err) => {
        setBtnLoading(false);
        message.error("Error creating invoice");
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button disabled={!selectedOrg} onClick={showModal} type="link">
        <PlusCircleFilled /> Add New
      </Button>
      <InvoiceDetailsModal
        {...{
          isModalVisible,
          handleCancel,
          handleOk,
          setIsModalVisible,
          setResetFields,
          resetFields,
          isCreate: true,
          btnLoading,
          setBtnLoading,
          invoiceDetails: null,
        }}
      />
    </>
  );
};
