export const adminMails = [
  "ashmit@sourcewiz.co",
  "saurabh.verma@sourcewiz.co",
  "sachet@sourcewiz.co",
  "shrenivass@sourcewiz.co",
  "shailesh@sourcewiz.co",
  "deepam@sourcewiz.co",
  "mayur.sonawane@sourcewiz.co",
  "utasav@sourcewiz.co",
  "saumya@sourcewiz.co",
  "kalpesh@sourcewiz.co",
];

export const adminMailsForOtp = [
  "shailesh@sourcewiz.co",
  "kritagya@sourcewiz.co",
  "deepam@sourcewiz.co",
  "shrenivass@sourcewiz.co",
  "kalpesh@sourcewiz.co",
];
