import { Card, Col, Collapse, Row } from 'antd';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useSelector } from 'react-redux';
import ManageAttributes from '../CreateAttribute';
import SkeletonLoader from '../AttributeSettingComponents/SkeletonLoader';

const { Panel } = Collapse;

function AttributeSection({loading,handleOpenDrawer}) {
  const attributeSectionData = useSelector(
    (state) => state.attributeSetting.attributeSectionData
  );
  
  const iconStyle = {
    backgroundColor: 'rgba(230, 231, 231, 1)',
    borderRadius: '50%',
    padding: '5px',
    color: '#363E3D',
  };

  const panelHeader = (title, count) => (
    <Row>
      <Col className="panelHeader" span={24}>
        {title}
      </Col>
      <Col span={24}>
        <p>{count} attribute(s)</p>
      </Col>
    </Row>
  );

  return (
    <>
      {loading ? (
        <SkeletonLoader row={10} />
      ) : (
        <Collapse
          className="collapse"
          expandIcon={({ isActive }) =>
            isActive ? (
              <MdKeyboardArrowUp style={iconStyle} size={32} />
            ) : (
              <MdKeyboardArrowDown style={iconStyle} size={32} />
            )
          }
          expandIconPosition="end"
          ghost>
          {attributeSectionData?.map((ele, index) => {
            return (
              <Panel
                header={panelHeader(ele?.name, ele?.attributes?.length)}
                key={index}>
                <Card style={{ marginBottom: '20px' }}>
                  <ManageAttributes
                    handleOpenDrawer={handleOpenDrawer}
                    sourceData={ele?.attributes}
                    sectionName={ele?.name}
                    attributeCount={ele?.attributes.length}
                  />
                </Card>
              </Panel>
            );
          })}
        </Collapse>
      )}
    </>
  );
}

export default AttributeSection;
