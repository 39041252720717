import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Select,
  PageHeader,
  Space,
  Divider,
  Form,
  Button,
  Switch,
} from "antd";
import Uploader from "./UploadExcel";
import Validator from "./Validator";
import API_KEY from "../../api/index";
import ValidatorDiv from "./ValidationDiv";
import { useNavigate } from "react-router-dom";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import axios from "axios";
const { Title, Text } = Typography;
const CardComponents = ({ title, count }) => {
  return (
    <Card
      bordered
      hoverable
      style={{ borderRadius: 8, backgroundColor: "#f1f1f1" }}
    >
      <Row>
        <Col span={24}>
          <Title type="secondary" level={4}>
            {title}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>{count}</Title>
        </Col>
      </Row>
    </Card>
  );
};
export default function Index() {
  const currentEmail = JSON.parse(localStorage.getItem("userLog"));
  const [productCount, setProductCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [userData, setUserData] = useState([]);
  const [collectionCount, setCollectionCount] = useState(0);
  const [orgCount, setOrgCount] = useState(0);
  const [orgData, setOrgData] = useState([]);
  const [hasVariants, setHasVariants] = useState(false);
  const [manufacturerID, setManufacturerID] = useState("");
  const [filterMap, setFilterMap] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [isValidated, setValidated] = useState(false);
  const [upload, setUpload] = useState({});
  const [email, setEmail] = useState(currentEmail || null);
  const [uploadSheet, setUploadSheet] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const navigate = useNavigate();

  const [validationResponse, setValidation] = useState({});
  const { Option } = Select;

  const fetchProductCount = () => {
    API_KEY.URL.get(API_KEY.path.products, {
      params: {
        meta: "*",
        limit: 1,
      },
    })
      .then((res) => {
        setProductCount(res?.data?.meta?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUsers = () => {
    API_KEY.URL.get(API_KEY.path.users, {
      params: {
        meta: "*",
        limit: -1,
      },
    })
      .then((res) => {
        const userData = res?.data?.data?.filter((item) => item.org !== null);
        setUserCount(userData.length);
        setUserData(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateExcel = () => {
    setIsValidating(true);
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("uploadFile", uploadSheet);
    axios
      .post(
        `${process.env.REACT_APP_IMPORTLIB_API}validate?mid=${manufacturerID}&hasVariants=${hasVariants}`,
        fmData,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setValidation(response?.data);
        }
        setIsValidating(false);
      })
      .catch((err) => {
        // setError("Some'thing went wrong");
        // onError({ err });
      });
    // Simply Print the Base64 Encoded String,
    // without additional data: Attributes.
    // console.log(
    //   "Base64 String without Tags- ",
    //   base64String.substr(base64String.indexOf(", ") + 1)
    // );
  };

  const uploadExcel = () => {
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("uploadFile", uploadSheet);

    const manufacturer =
      filterMap.filter((item) => item.id?.toString() === manufacturerID)[0] ||
      {};
    const userEmail = manufacturer?.users?.filter(
      (item) => item.id === selectedUser
    )[0]?.email;

    axios
      .post(
        `${process.env.REACT_APP_IMPORTLIB_API}products/add-new?mid=${manufacturerID}&hasVariants=${hasVariants}&user_id=${selectedUser}&manufacturerName=${manufacturer.orgName}&userEmail=${userEmail}&notifyEmail=${email}`,
        fmData,
        config
      )
      .then((response) => {
        if (response?.data?.id) {
          setValidation(response?.data);
          // onSuccess("Ok");

          console.log(response);
        }
      })
      .catch((err) => {
        // setError("Some'thing went wrong");
        // onError({ err });
      });
  };

  const fetchCollectionCount = () => {
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        meta: "*",
        limit: 1,
      },
    })
      .then((res) => {
        setCollectionCount(res?.data?.meta?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOrgCount = () => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        meta: "*",
        limit: -1,
        filter: {
          _and: [
            {
              status: {
                _nin: ["archived"],
              },
            },
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },
            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
          ],
        },
      },
    })
      .then((res) => {
        setOrgCount(res?.data?.meta?.total_count);
        setOrgData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setManufacturer = (value) => {
    setManufacturerID(value);
    const manufacturerDetails = filterMap.filter(
      (item) => item?.id?.toString() === value
    );
    setUserList(manufacturerDetails[0]?.users || []);
  };

  useEffect(() => {
    fetchUsers();
    fetchOrgCount();
  }, []);
  // useEffect(() => {
  //     const timer = setInterval(fetchOrgCount, 10000);
  //     return () => clearInterval(timer);
  // }, []);
  // useEffect(() => {
  //     const timer = setInterval(fetchUserCount, 10000);
  //     return () => clearInterval(timer);
  // }, []);

  useEffect(() => {
    if (validationResponse?.is_valid) {
      setValidated(true);
    }
  }, [validationResponse]);

  useEffect(() => {
    //create a map of orgIDs and all emails tied to them
    if (userData.length && orgData.length) {
      const dataMap = orgData?.map((item) => {
        return {
          id: item.id,
          orgName: item.name,
          users: userData.filter((user) => user?.org === item?.id) || [],
        };
      });

      //filter map only for mIDS that have user associated with them
      const filteredMap = dataMap?.filter((item) => item.users.length !== 0);
      // setFilterMap
      // console.log(filteredMap);
      setFilterMap(filteredMap);
      // setUserCount(filterMap.length);
    }
  }, [userData, orgData]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={
          <Row>
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Bulk Upload
            </Title>
            <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
              {/* ({total}) */}
            </Text>
          </Row>
        }
        subTitle="Select the organization and category to upload the products, you can also download the sample file to upload the products"
      />
      <Divider />
      <Row style={{ minHeight: "75vh" }} gutter={32}>
        <Col span={4}>
          <CardComponents title={"Users"} count={filterMap.length} />
        </Col>
        {userCount && (
          <Col span={12}>
            <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Form.Item label="Select Organization">
                <Select
                  style={{ width: "100%" }}
                  // filterOption={true}

                  optionFilterProp={"email"}
                  onChange={(e) => setManufacturer(e)}
                  placeholder="Select Manufacturer to proceed"
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {filterMap?.map((item) => (
                    <Option
                      key={item?.id}
                      email={item?.orgName}
                      title={item?.orgName}
                    >
                      {item?.orgName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Select User">
                <Select
                  style={{ width: "100%" }}
                  // filterOption={true}
                  optionFilterProp={"email"}
                  onChange={(e) => setSelectedUser(e)}
                  placeholder="Select User to proceed"
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {userList?.map((item) => (
                    <Option
                      key={item?.id}
                      email={item?.email}
                      title={item?.email}
                    >
                      {item?.email}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Email">
                <Input
                  type="email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Does this upload have Variants?">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={setHasVariants}
                />
              </Form.Item>

              <Form.Item label="Upload sheet">
                <Validator
                  manufacturerID={manufacturerID}
                  user={selectedUser}
                  setValidation={setValidation}
                  setUploadSheet={setUploadSheet}
                />
              </Form.Item>

              {/* <Form.Item label="Upload sheet">
                <Uploader
                  isValidated={isValidated}
                  manufacturerID={manufacturerID}
                  user={selectedUser}
                  setValidation={setValidation}
                  filterMap={filterMap}
                  email={email}
                />
              </Form.Item> */}
              <Row
                justify="space-between"
                style={{ margin: "0 0 20px 0", width: "50%" }}
              >
                <Col>
                  <Button
                    disabled={!(manufacturerID && uploadSheet)}
                    loading={isValidating}
                    onClick={validateExcel}
                    type="primary"
                  >
                    Validate
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={!(manufacturerID && uploadSheet && isValidated)}
                    onClick={uploadExcel}
                    type="primary"
                  >
                    Confirm and start upload
                  </Button>
                </Col>
              </Row>
            </Form>

            {!validationResponse?.is_valid ? (
              <Space>
                <CloseCircleFilled style={{ fontSize: 22, color: "red" }} />
                <Text type="danger" style={{ fontSize: 18, color: "red" }}>
                  Please check uploaded sheet
                </Text>
              </Space>
            ) : (
              <>
                {" "}
                <Space>
                  <CheckCircleFilled style={{ fontSize: 22, color: "green" }} />{" "}
                  <Text style={{ fontSize: 18, color: "green" }}>
                    Sheet is valid
                  </Text>
                </Space>
              </>
            )}
          </Col>
        )}

        {validationResponse?.id ? (
          <Row align="top">
            <Space>
              <Col>Upload Started </Col>
              <Button
                href={`http://64.227.164.236:5555/task/${validationResponse?.id}`}
                target="_blank"
                type="primary"
              >
                Check Status
              </Button>
            </Space>
          </Row>
        ) : (
          <></>
        )}

        <Col span={8}>
          <Row>
            {validationResponse && Object.keys(validationResponse).length ? (
              <ValidatorDiv valid={validationResponse} />
            ) : (
              ""
            )}
          </Row>
          <Row>
            {upload && Object.keys(upload).length
              ? `Validation reponse: ${JSON.stringify(upload)}`
              : ""}
          </Row>
        </Col>
      </Row>
    </>
  );
}
