import React from 'react';
import { Col, Form, Input, message, Row, Select, Typography } from 'antd';
import { dtypes } from '../dtypes';
import { useSelector } from 'react-redux';
import { tabs } from '../utils';
const { Option } = Select;

function BasicForm({ form, selectedDtype, setSelectedDtype, data }) {
  const { Title } = Typography;
  const attributeSectionData = useSelector((state) => state.attributeSetting.allAttributesData);


  const allProductAttributes = attributeSectionData?.filter((item) =>
  item?.type?.includes('product')
);

  const publishedAttributes = allProductAttributes?.filter(
    (item) => item?.status === 'published'
  );


  const handleClickDtype = (item) => {
    if (data?.id) {
      return message.error('Attribute format can not be changed');
    }
    setSelectedDtype(item?.name);
  };

  const handleDisable = () => {
    if (data.attributeCount) {
      if (data.attributeCount > 1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <Title level={5}>Add Details</Title>
      <p>Fill the basic details of the new attribute</p>
      <Form style={{ marginTop: '2rem' }} form={form} layout={'vertical'}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please enter attribute name',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (publishedAttributes?.filter((item) => item?.name?.toLowerCase() === value?.toLowerCase())?.length && !data?.id){
                  return Promise.reject(
                    new Error('Attribute with same name already exists')
                  );
                }
                return Promise.resolve();
              },
            })
          ]}
          name={'name'}
          label="Name">
          <Input placeholder="Enter Attribute Name" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please select a value',
            },
          ]}
          name={'section'}
          label="Section">
          <Select disabled={handleDisable()}>
            <Option value="production">Product Info</Option>
            <Option value="internal">Internal Details</Option>
            <Option value="production_detail">Production Details</Option>
          </Select>
        </Form.Item>
        <div style={{ fontSize: '16px' }}> Select data type</div>
        <Row>
          {dtypes?.map((item) => {
            return (
              <div
                className={
                  selectedDtype === item?.name
                    ? 'drawer-selection-box-selected'
                    : 'drawer-selection-box'
                }
                onClick={() => handleClickDtype(item)}>
                <div style={{ textAlign: 'center' }}>
                  <Col>{item?.icon}</Col>
                  <Col>
                   <p>{item?.label}</p>
                  </Col>
                </div>
              </div>
            );
          })}
        </Row>
      </Form>
    </div>
  );
}

export default BasicForm;
