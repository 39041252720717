import { Button, Checkbox, Col, Modal, Row, Space } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { useState } from "react";

export default function RejectModal ({ onReject, onCancel, visible }) {
    const [confirmed, setConfirmed] = useState(false);
    return (
        <Modal
            visible={visible}
            closable={false}
            centered
            footer={(
                <Space direction="horizontal" style={{justifyContent:"space-between", width:"100%"}} >
                    <Button
                        key="cancel" 
                        style={{width:104, height:40, backgroundColor:"#FFFFFF", color:"#4F5655", left:0, alignSelf:"end "}} 
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                        {confirmed ? (
                            <Button 
                                key="resolve" 
                                style={{width:104, height:40, backgroundColor:"#1D2624", color:"#FFFFFF", right:0, alignSelf:"start"}} 
                                onClick={onReject}
                            >
                                Reject
                            </Button>):(
                                <Button 
                                    key="resolve" 
                                    style={{width:104, height:40, color:"#828786", background:"#E6E7E7", right:0, alignSelf:"start"}} 
                                    disabled={confirmed}
                                >
                                    Reject
                                </Button>
                            )
                        }
            </Space>)}
            width={512}
            style={{height:"320px"}}
            title={
                (
                    <Title style={{fontWeight:700, fontSize:20, margin:"auto"}}>
                        Reject request
                    </Title>
                )
            }
            afterClose={()=>{
                setConfirmed(false);
            }}
        >
            <Row style={{background: "#F5F5F5",borderRadius: "8px", padding:"2rem", justifyContent: "space-between"}}>
                <Row style={{marginBottom:"1em"}}>
                    <Text>
                    This request will be rejected and you CANNOT undo this action. Are you sure you want to continue?
                    </Text>
                </Row>
                <Row justify="start" >
                    <Col>
                        <Checkbox checked={confirmed} onClick={(e)=>{setConfirmed(e?.target?.checked);}} style={{marginRight:7}} /> Yes, I'm sure
                    </Col>
                </Row>
            </Row>

        </Modal>
    )
}