import { Button, Checkbox, Col, DatePicker, Input, InputNumber, Modal, Radio, Row, Select, Space, Spin, Table, Tooltip, notification } from "antd";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";

export default function AddUpdatePlanLineItem({ selectedOrg, lineItem, visibleModal, setVisibleModal }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(lineItem);
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);

    const updateFinalData = (key, val) => {
        let newData = data;
        if (key==="start_date" || key==="end_date"){
            newData = {...newData, [key]:moment(val).format("YYYY-MM-DD")};
        }else{
            if (newData){
                newData = {...newData, [key]:val}
            }else{
                newData = {[key]:val}
            }
        }
        setData({...data,...newData});
    }
    const onOk = () => {
        
        setLoading(true);
        let paylod = {
            start_date: data?.start_date,
            end_date: data?.end_date,
            type: data?.plan_type,
            last_reminder_date: data?.last_reminder_date,
            limit_type: data?.limit_type,
            plan_status: data?.plan_status,
            customer_id: selectedOrg,
            limit: data?.limit,
            plan_type: data?.plan_type
        }
        if(data?.customer_plan_id){
            paylod["customer_plan_line_item_id"]= data?.customer_plan_line_item_id
            axios.post(`${process.env.REACT_APP_INTERNAL_API}account/update/plan_line_item`, paylod,{
                headers:{
                    "access-token":`${user?.access_token}`
                },
                params: {
                    // request_id: uuid()
                }
            }).then((res) => {
                if (res && res?.status===200){
                    notification.success({
                        message: "Request has sent for approval"})
                    setVisibleModal(false);
                }else{
                    notification.warning({message:`Got response as ${res?.data?.details}`})
                }
                setLoading(false);
            }).catch(err => {
                
                console.log(err);
                setLoading(false);
            });
        }else{
            //should call here add plan line item  api
        }
        
    }
    
    const onCancel = () => {
        setVisibleModal(false);
    }

    const afterClose = () => {
        setData(lineItem);
    }

    useEffect(() => {
        setData(lineItem);
    }, [lineItem]);

    const plan_status_options = [
        {
            label: "Active",
            value: "active",
            key: "active",
        },
        {
            label: "Inactive",
            value: "inactive",
            key: "inactive",
        },
        {
            label: "Expired",
            value: "expired",
            key: "expired",
        },
    ]

    const plan_type_options = [
        {
            label: "Trial",
            value: "trial",
            key: "trial",
        },
        {
            label: "Plan",
            value: "plan",
            key: "plan",
        },
    ]

    return (
    <Modal 
        title={
            <div>
                <Space direction="vertical">
                    <Text style={{fontSize:18, fontWeight:700}} >
                        Plan Line Item
                    </Text>
                    <Text type="secondary" style={{fontSize:16}} >
                        {data?.customer_plan_id?"Update":"Add"} Plan Line Item
                    </Text>
                </Space>
            </div>
        }
        visible={visibleModal}
        okText={data?.customer_plan_id?"Update":"Add"}
        closable={false}
        afterClose={afterClose}
        footer={(
            <Space direction="horizontal" style={{justifyContent:"space-between", width:"100%"}} >
                <Button
                    key="cancel" 
                    style={{width:104, height:40, backgroundColor:"#FFFFFF", color:"#4F5655", left:0, alignSelf:"end "}} 
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button 
                    key="resolve" 
                    style={{width:104, height:40, backgroundColor:"#1D2624", color:"#FFFFFF", right:0, alignSelf:"start"}} 
                    onClick={onOk}
                >
                    {data?.customer_plan_id?"Update":"Add"}
                </Button>
            </Space>
        )}
        centered={true}
        width={400}
    >
        <Spin spinning={loading}>
            <Space direction="vertical" style={{width:"100%", justifyContent:"space-between", lineHeight:2}} >
                <Row className="modal-row">
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                                ID:
                            </Text>
                        </Row>
                        <Row>
                            <Input value={data?.id} style={{width:"100%"}} disabled />
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-between" className="modal-row" >
                    <Col span={12} style={{paddingRight:5}} >
                        <Row>
                            <Text type="secondary" >
                                Start Date
                            </Text>
                        </Row>
                        <Row>
                            <DatePicker 
                                showToday 
                                style={{width:"100%"}} 
                                value={data?.start_date? moment(data?.start_date):""} 
                                disabled={loading}
                                onChange={(date, dateString)=>{
                                    if(date){
                                        updateFinalData("start_date", date);
                                    }
                                }} />
                        </Row>
                    </Col>
                    <Col span={12} style={{paddingLeft:5}} >
                        <Row>
                            <Text type="secondary" >
                                End Date
                            </Text>
                        </Row>
                        <Row>
                            <DatePicker 
                                showToday 
                                style={{width:"100%"}} 
                                value={data?.start_date? moment(data?.end_date):""} 
                                disabled={loading}
                                onChange={(date, dateString)=>{
                                    if(date){
                                        updateFinalData("end_date", date);
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row">
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                                Plan Type
                            </Text>
                        </Row>
                        <Row>
                            <Select 
                                value={data?.plan_type} 
                                style={{width:"100%"}} 
                                options={plan_type_options}
                                onChange={(val)=>{
                                    updateFinalData("plan_type", val);
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row">
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                                Plan Status
                            </Text>
                        </Row>
                        <Row>
                            <Select 
                                value={data?.plan_status} 
                                style={{width:"100%"}} 
                                options={plan_status_options}
                                onChange={(val)=>{
                                    updateFinalData("plan_status", val);
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                               Has Limit:
                            </Text>
                        </Row>
                        <Row>
                            <Checkbox 
                                checked={data?.has_limit} 
                                // onClick={(e)=>{updateFinalData("has_limit",e?.target?.checked);console.log(e);}} 
                                disabled
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                                Limit Type:
                            </Text>
                        </Row>
                        <Row>
                            <Radio.Group 
                                value={data?.limit_type} 
                                style={{ 
                                    width:"100%", 
                                    justifyContent:"space-between", 
                                    alignContent:"center", 
                                    height:35
                                }}
                                disabled
                                // onChange={
                                //     (e)=>{
                                //         updateFinalData("limit_type",e?.target?.value);
                                //     }
                                // } 
                                >
                                    <Row style={{marginTop:5, justifyContent:"space-between", height:"100%"}}>
                                        <Col span={11} className="modal-radio" >
                                            <Radio value="recurring" className="radio-class" style={{ display:"block"}} >Recurring</Radio>
                                        </Col>
                                        <Col span={11} className="modal-radio" >
                                            <Radio value="one-time" style={{ display:"block"}}>One time</Radio>
                                        </Col>
                                    </Row>
                            </Radio.Group>
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col style={{width:"100%"}}>
                        <Row>
                            <Text type="secondary" >
                                Limit:
                            </Text>
                        </Row>
                        <Row>
                            <InputNumber 
                                value={parseInt(data?.limit)} 
                                style={{width:"100%"}} 
                                controls={false} 
                                disabled={loading || !data?.has_limit}
                                onChange={(val)=>{
                                    updateFinalData("limit", val);
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
            </Space>
        </Spin>
    </Modal>
    )
}