import { Button, Col, Drawer, message, Row, Select, Space, Table, Tooltip, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import style from "styled-components";
import copy from "copy-to-clipboard";
import StatusPill from "./StatusPill";
import { CloseOutlined } from '@ant-design/icons';
import RejectModal from "./RejectModal";

const { Title, Text } = Typography;
const PlanDiv = style.div`
    flex-direction: "row";
    border: 1px solid #E6E7E7;
    box-sizing: border-box;
    margin : 1rem 0 1rem 0;
    padding: 1rem;
    width: 100%;
    opacity: 1;
    mix-blend-mode: normal;
    border-radius: 0.5rem;`

const RequestDiv = style.div`
    flexDirection: "columns";
    border: 1px solid #E6E7E7;
    box-sizing: border-box;
    margin : 1rem 0 1rem 0;
    padding: 1rem;
    width: 100%;
    opacity: 1;
    mix-blend-mode: normal;
    border-radius: 0.5rem;`
export const Requests = ({
    selectedOrg, 
    visibleAddonModal, 
    visiblePlanModal, 
    selectedRow, 
    selectedRowId, 
    setVisibleModal,
    onCloseModal, 
    onAddonOk, 
    onCancel,
    setSelectedRow,
    setSelectedRowId,
    onPlanOk
}) => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [requestStatus, setRequestStatus] = useState("all");
    const [commentData, setCommentData] = useState({});
    const [visibleComment, setVisibleComment] = useState(false);
    const [visibleReject, setVisibleReject] = useState(false);
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);
    const statusOptions = [
        {
            label: "All",
            value: "all",
            key: "all",
        },
        {
            label: "Pending",
            value: "pending",
            key: "pending",
        },
        {
            label: "Approved",
            value: "approved",
            key: "approved",
        },
        {
            label: "Rejected",
            value: "rejected",
            key: "rejected",
        },
        {
            label: "Approval Pending",
            value: "pending-approval",
            key: "pending-approval",
        }
    ]
    const rejectRequest = (requestId) => {
            setLoading(true);
            axios.patch(`${process.env.REACT_APP_INTERNAL_API}user_request/update`, 
                {
                    user_request_id: requestId,
                    customer_id: selectedOrg,
                    status: "rejected",
                },
                { 
                    headers:{
                        "access-token":user?.access_token
                    }
                }
            ).then((res) =>{
                fetchRequests(selectedOrg);
                if (res?.status === 200){
                    setLoading(false);
                    setVisibleReject(false);
                }
            }).catch((err) => {
                setRequests([]);
                setLoading(false);
                console.log(err);
            })
    }
    const columns = [
        {
          title: "Id (Click to Copy)",
          dataIndex: "id",
          key: "id",
          align: "center",
          render: (_, record) => {
            return (
                <Row style={{flexDirection:"row", display:"flex"}} >
                    <Col span={24} style={{width:100, textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}}>
                        
                            <Text onClick={()=>{copy(record?.id);message.success("Id copied successfully")}}>
                                {record.id}
                            </Text>
                    </Col>
                </Row>
            );
          },
        },
        {
            title: "Email",
            dataIndex: "customer_email",
            key: "customer_email",
            align: "center",
            render: (_, record) => {
                return (
                            <Text >
                            {record.customer_email} 
                            </Text>
                   
                );
            },
        },
        {
            title: "Req. Type ",
            dataIndex: "request_type",
            key: "request_type",
            align: "center",
            render: (_, record) => {
                return (
                    
                    (record?.feature_type==="addon")?(
                        <Text>
                            {record?.addon_core?.label} {record?.addon_core?.limit && `(${record?.addon_core?.limit})` } 
                        </Text>
                    ):
                    (
                        <Text>
                           Plan {record?.plan?.label}
                        </Text>
                    )
                   
                );
            },
        },
        {
            title: "Mo. number",
            dataIndex: "meta",
            key: "meta",
            align: "center",
            render: (_, record) => {
                return (
                    <Text type="secondary">
                        {record?.meta?.contact_no}
                    </Text>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (_, record) => {
              return (
                  <Row style={{flexDirection:"row", display:"flex"}} >
                      
                      <Col span={24}>
                          <StatusPill status={record?.status} />
                      </Col>
                  </Row>
              );
            },
          },
        {
            title: "",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (_, record) => {
                return (
                                (record?.status==="approved"||record?.status==="rejected" || record?.status==='pending-approval')?(
                                    <Space direction="horizontal" style={{width:"100%",justifyContent:"space-evenly"}} >
                                    <Button 
                                        style={{width:87, color:"#828786", background:"#E6E7E7"}} 
                                        disabled
                                    >
                                        Reject
                                    </Button>
                                    <Button 
                                         style={{width:140, color:"#0B7764", background:"#F0F8F6", border:"1px solid #6DADA2"}}
                                        onClick={()=>{setCommentData(record);setVisibleComment(true);}}
                                    >
                                        See comments
                                    </Button>
                                    <Button 
                                        style={{width:84, color:"#828786", background:"#E6E7E7"}}
                                        disabled
                                    >
                                        Resolve
                                    </Button>
                                    </Space>
                                ):(
                                    <Space direction="horizontal" style={{width:"100%",justifyContent:"space-evenly"}} >
                                    <Button 
                                        onClick={()=>{setSelectedRowId(record?.id);setVisibleReject(true);}} 
                                        style={{width:87, color:"#4F5655", background:"#FFFFFF"}} 
                                    >
                                        Reject
                                    </Button>
                                    <Button 
                                        style={{width:140, color:"#0B7764", background:"#F0F8F6", border:"1px solid #6DADA2"}}
                                        onClick={()=>{setCommentData(record);setVisibleComment(true);}}
                                    >
                                        See comments
                                    </Button>
                                    <Button 
                                        onClick={()=>{setVisibleModal(true, record);}} 
                                        style={{width:84, color:"#FFFFFF", background:"#0B7764"}}
                                    >
                                        Resolve
                                    </Button>
                                    </Space>
                                )
                    )
            },
        },
      ];
    const fetchRequests = (selectedOrg) => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_INTERNAL_API}user_request/${requestStatus}/${selectedOrg}`, 
                { 
                    headers:{
                        "access-token":user?.access_token
                    }
                }
            ).then((res) =>{
                const data = res?.data;
                if (Array.isArray(data)){
                    setRequests([]);

                    let sortedData = res?.data?.map((req, index) => {
                        return {
                          key: index,
                          ...req
                        };
                      });
                    const tempData = sortedData?.sort((a,b) => {
                        return  b?.status - a?.status
                    })
                    setRequests(tempData);
                }
                if(res?.status === 200){
                    setLoading(false);
                }
        }).catch((err) => {
            setRequests([]);
            setLoading(false);
            console.log(err);
        })
    }
    useEffect(()=>{
        fetchRequests(selectedOrg);
    }, [selectedOrg]);
    useEffect(()=>{
        fetchRequests(selectedOrg);
    }, [requestStatus]);

    return (<div style={{marginTop:10}} >
    <RejectModal onCancel={()=>{setVisibleReject(false);}} onReject={()=>{rejectRequest(selectedRowId); setRequestStatus(requestStatus) }} rowId={selectedRowId} visible={visibleReject} />
     <Drawer 
        visible={visibleComment} 
        // footer={(
        //         <Space direction="horizontal" style={{justifyContent:"space-between", width:"100%"}} >
        //             <Button
        //                 key="cancel" 
        //                 style={{width:104, height:40, backgroundColor:"#FFFFFF", color:"#4F5655", left:0, alignSelf:"end "}} 
        //                 onClick={()=>{setVisibleComment(false);}}
        //             >
        //                 Cancel
        //             </Button>
        //             <Button 
        //                 key="resolve" 
        //                 style={{width:104, height:40, backgroundColor:"#1D2624", color:"#FFFFFF", right:0, alignSelf:"start"}} 
        //                 onClick={()=>{setVisibleModal(true, commentData);}}
        //             >
        //                 Resolve
        //             </Button>
        //         </Space>
        //         )
        // }

        title={(
            <Row style={{width:"100%", justifyContent:"space-between"}} >
                <Col>
                    <Text style={{fontSize:20, fontWeight:700}}>
                        Request details
                    </Text>
                </Col>
                <Col onClick={()=>{setVisibleComment(false);}} style={{cursor:"pointer"}} >
                    <CloseOutlined />
                </Col>
            </Row>
        )}
        width={400}
        zIndex={1000}
        onClose={()=>{
            if(visibleComment){
                setVisibleComment(false);
            }}
        }
        forceRender
    >
        <Space direction="vertical" >
            <Row className="comment-box-row">
                <Col>
                    <Text className="comment-text-title">Request id</Text>
                </Col>
                <Col>
                    <Text className="comment-text-value">{commentData?.id}</Text>
                </Col>
            </Row>

            <Row className="comment-box-row">
                <Col>
                    <Text className="comment-text-title">Mobile number</Text>
                </Col>
                <Col>
                    <Text className="comment-text-value">{commentData?.meta?.contact_no}</Text>
                </Col>
            </Row>

            <Row className="comment-box-row">
                <Col>
                    <Text className="comment-text-title">Comment</Text>
                </Col>
                <Col>
                    <Text className="comment-text-value">{commentData?.user_comments}</Text>
                </Col>
            </Row>
        </Space>
     </Drawer>
    <Row style={{justifyContent:"space-between", marginLeft:10}}>
        <Col>
            <Title level={5}>Requests</Title>
        </Col>
        <Col>
            <Select 
                defaultValue={requestStatus} 
                options={statusOptions} 
                onChange={(e)=>{setRequestStatus(e);}} 
                style={{minWidth:100}}
            />
        </Col>
    </Row>
    <PlanDiv>
        {
            <Table
                dataSource={requests}
                columns={columns}
                bordered={false}
                size="middle"
                pagination={{ position: ["topRight", "bottomRight"] }}
                loading={loading}
            />
        }
    </PlanDiv>
    </div>)
} 