import axios from "axios";
const userData = localStorage.getItem("userData");
const user = userData && JSON.parse(userData);

const PYTHON_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_INTERNAL_API,
    headers: {
      "access-token": user?.access_token,
    },
  }),
  path: {
    getActiveFeatures: (id) => `manufacturer/feature-permissions/${id}`,
    toggleFeature: "manufacturer/features",
    attributes: "attribute",
    getExcelTemplates: "excel/manufacturer/template",
    addExcelTemplate: "excel/template/upload",
    updateExcelTemplate: "excel/template/update",
  },
};


export default PYTHON_KEY