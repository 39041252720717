import { Card, Col, Image, Row, Space, Tag, Typography } from "antd";
import TemplateActions from "./TemplateActions";
import {
  defaultPreviewImg,
  getImageUrl,
} from "../../../utils/excelTemplateHelpers";

const { Text } = Typography;

const TemplateContainer = ({
  template = {},
  setIsTemplateModalVisible,
  setMode,
  setSelectedTemplate,
}) => {
  const handleDeleteTemplate = () => {
    setSelectedTemplate(template);
    setMode("delete");
    setIsTemplateModalVisible(true);
  };

  const handleEditTemplate = () => {
    setSelectedTemplate(template);
    setMode("edit");
    setIsTemplateModalVisible(true);
  };

  return (
    <Card className="excel-template-card" hoverable>
      <Row align="middle">
        <Col span={20}>
          <Image
            preview={false}
            height={100}
            src={getImageUrl(260, 230, 40, template?.previewImageUrl)}
            alt="template-preview-img"
            fallback={defaultPreviewImg}
          />
          <Space className="excel-template-name-space">
            <Text strong>{template?.name}</Text>
          </Space>
        </Col>
        <Col span={4}>
          <Row align="middle" justify="space-between">
            <Tag
              color="purple"
              style={{ padding: "0.5em 1.5em", borderRadius: "1em" }}
            >
              {template?.status?.toUpperCase()}
            </Tag>
            <TemplateActions
              handleDeleteTemplate={handleDeleteTemplate}
              handleEditTemplate={handleEditTemplate}
            />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TemplateContainer;
