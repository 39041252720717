import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Radio,
  Select,
  Collapse,
  Checkbox,
  Switch,
  Upload,
  Button,
  message,
  Modal,
  Input,
  Divider,
} from 'antd';
import {
  DownloadOutlined,
  UploadOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { BsEmojiExpressionless, BsEmojiSmile } from 'react-icons/bs';
import axios from 'axios';
import CollectionLinkModal from './CollectionLinkModal';

const BasicInformation = ({ orgs }) => {
  const { Option } = Select;
  const { Panel } = Collapse;
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = [
    'intro_slide',
    'after_intro_slide',
    'product_slide',
    'end_slides',
  ];

  const defaultCollectionID = '8c8t0j2';
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [currentUpload, setCurrentUpload] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [flag, setFlag] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(true);
  const [collectionLink, setCollectionLink] = useState(defaultCollectionID);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [details, setDetails] = useState({
    userType: 'generic',
    userId: null,
    slides: [],
    key: null,
  });
  const [payload, setPayload] = useState({
    manufacturer: null,
    intro_slide: {
      slide_shape: 'horizontal',
      ppt_slide: '',
    },
    after_intro_slide: {
      slide_shape: 'horizontal',
      ppt_slide: '',
    },
    product_slide: {
      slide_shape: 'horizontal',
      type_of_item: 'product',
      number_of_item: 0,
      preview_image: '',
      skeleton_image: '',
      ppt_slide: '',
      name: '',
      description: '',
      has_fallback: false,
      fallbacks: [],
    },
    end_slides: {
      slide_shape: 'horizontal',
      ppt_slide: '',
    },
  });
  const [updatePayload, setUpdatePayload] = useState(null);
  const [updatePayloadPreview, setUpdatePayloadPreview] = useState({});

  //get template details against manufacturer
  useEffect(() => {
    const manufacturer = details?.userId;
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_IMPORTLIB_API}ppt_settings?manufacturerID=${manufacturer}`
      )
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setPreview(res?.data);
          // setPayload({
          //   ...payload,
          //   intro_slide: res?.data?.data?.intro_slide?.id,
          //   end_slides: res?.data?.data?.end_slides?.id,
          //   after_intro_slide: res?.data.data?.after_intro_slide?.id,
          // })
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [details]);

  console.log(preview);
  // console.log(payload);

  const onChangeRadioUser = (e) => {
    setDetails({
      ...details,
      userType: e.target.value,
      userId: '',
    });
  };

  const onChangeDropdown = (value, key) => {
    setDetails({
      ...details,
      userId: value,
    });
    setPayload({
      ...payload,
      manufacturer: value,
    });
  };

  const onChangeSlide = (list) => {
    setDetails({
      ...details,
      slides: list,
    });
  };

  //img upload
  const handleChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
    }
    if (info.file.status === 'done') {
    }
  };
  const beforeUploadImg = (file) => {
    const isImg = [
      'image/jpg',
      'image/jpeg',
      'image/svg',
      'image/svg+xml',
      'image/png',
    ].includes(file.type);
    if (!isImg) {
      message.error(`${file.type} is not a image file`);
    }
    return isImg || Upload.LIST_IGNORE;
  };
  const uploadImg = async (options) => {
    const { file, onSuccess } = options;
    const fmData = new FormData();
    fmData.append('image', file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      axios
        .post(`${process.env.REACT_APP_KEY}files`, fmData)
        .then((response) => {
          onSuccess('Ok');
          setPayload((state) => {
            return {
              ...state,
              product_slide: {
                ...payload.product_slide,
                [currentUpload]: response?.data?.data?.id,
              },
            };
          });
          setCurrentUpload(null);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };

  // ppt upload
  const beforeUploadPpt = (file) => {
    const isPpt = file.name.includes('pptx');
    if (!isPpt) {
      message.error(`${file.name} ${file.type} is not a ppt file`);
    }
    return isPpt || Upload.LIST_IGNORE;
  };
  const uploadPpt = async (options) => {
    const { file, onSuccess } = options;
    const fmData = new FormData();
    var reader = new FileReader();
    fmData.append('uploadFile', file);
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      axios
        .post(`${process.env.REACT_APP_KEY}files`, fmData)
        .then((response) => {
          onSuccess('Ok');
          setPayload((state) => {
            return {
              ...state,
              [currentUpload]: {
                ...payload?.[currentUpload],
                ppt_slide: response?.data?.data?.id,
              },
            };
          });
          setCurrentUpload(null);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };

  //intro template
  const onChangeSwitchIntro = (checked) => {
    setPayload({
      ...payload,
      intro_slide: {
        ...payload?.intro_slide,
        slide_shape: checked ? 'vertical' : 'horizontal',
      },
    });
  };
  const propsUploadIntro = {
    beforeUpload: beforeUploadPpt,
    customRequest: uploadPpt,
    handleChangeUpload: handleChangeUpload,
  };

  //after intro template
  const onChangeSwitchAfterIntro = (checked) => {
    setPayload({
      ...payload,
      after_intro_slide: {
        ...payload?.after_intro_slide,
        slide_shape: checked ? 'vertical' : 'horizontal',
      },
    });
  };
  const propsUploadAfterIntro = {
    beforeUpload: beforeUploadPpt,
    customRequest: uploadPpt,
    handleChangeUpload: handleChangeUpload,
  };

  //end slide template
  const onChangeSwitchEnd = (checked) => {
    setPayload({
      ...payload,
      end_slides: {
        ...payload?.end_slides,
        slide_shape: checked ? 'vertical' : 'horizontal',
      },
    });
  };
  const propsUploadEnd = {
    beforeUpload: beforeUploadPpt,
    customRequest: uploadPpt,
    handleChangeUpload: handleChangeUpload,
  };

  //product slide template
  const onChangeSwitchProduct = (checked) => {
    setPayload({
      ...payload,
      product_slide: {
        ...payload?.product_slide,
        slide_shape: checked ? 'vertical' : 'horizontal',
      },
    });
  };
  const onChangeRadioProduct = (e) => {
    setPayload({
      ...payload,
      product_slide: {
        ...payload?.product_slide,
        type_of_item: e.target.value,
      },
    });
  };
  const handleProductChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPayload({
      ...payload,
      product_slide: {
        ...payload?.product_slide,
        [name]: value,
      },
    });
  };

  const propsUploadImage = {
    beforeUpload: beforeUploadImg,
    customRequest: uploadImg,
    handleChangeUpload: handleChangeUpload,
  };

  const propsUploadProduct = {
    beforeUpload: beforeUploadPpt,
    customRequest: uploadPpt,
    handleChangeUpload: handleChangeUpload,
  };

  //api post request
  const handleSubmit = () => {
    const finalPayload = {};
    for (var item in details.slides) {
      finalPayload[details.slides[item]] = payload[details.slides[item]];
    }
    finalPayload.manufacturer = details.userId;
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_IMPORTLIB_API}ppt_settings`, finalPayload)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          message.info('Template Updated successfully');
          setPayload({
            product_slide: {
              name: '',
            },
          });
          setFlag(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          `Something went wrong, try again: ${JSON.stringify(err)}`
        );
      });
  };

  //api preview request
  const handlePreview = () => {
    if (!payload.manufacturer || !collectionLink) {
      message.error('Manufacturer or collection link not provided');
      return;
    }
    const previewPayload = details?.slides?.reduce((prev, current) => {
      return { ...prev, [current]: payload[current] };
    }, {});

    previewPayload.manufacturer = payload.manufacturer;
    previewPayload.collection_link = collectionLink;

    const user = JSON.parse(localStorage.getItem('userData') || '');
    setLoading(true);
    setDataLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IMPORTLIB_API}ppt_settings/create/preview`,
        previewPayload
      )
      .then((res) => {
        if (res.status === 200) {
          axios
            .patch(
              `${process.env.REACT_APP_KEY}items/collections/${
                res?.data?.data?.collection_id || '9956'
              }?access_token=${user?.access_token}`,
              { ppt_export: res?.data?.data }
            )
            .then((res) => {
              if (res.status === 200) {
                axios
                  .get(
                    `${process.env.REACT_APP_INTERNAL_API}pptservice/?collection_link=${res.data.data.collection_link}&access-token=${user?.access_token}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      setDataLoading(false);
                      window.open(res?.data?.ppt_url, '_self');
                      setLoading(false);
                      message.info('Preview Template  downloaded successfully');
                      setFlag(false);
                    }
                  })
                  .catch((err) => {
                    setDataLoading(false);
                    setLoading(false);
                    message.error('Error generating PPT for this catalogue');
                  });
              }
            })
            .catch((err) => {
              setDataLoading(false);
              setLoading(false);
              message.error(
                'Error updating template settings of the collection link provided'
              );
            });
        }
      })
      .catch((err) => {
        setDataLoading(false);
        setLoading(false);
        message.error(
          `Something went wrong: ${
            err?.response?.data?.error ? err?.response?.data?.error : ''
          }`
        );
      });
  };

  //update product slide template
  const handlePreviewTemplate = (item) => {
    setTemplateId(item?.id);
    console.log(item);
    setUpdatePayload({
      slide_type: item?.meta?.type_of_item,
      template_settings: {
        preview_image: item?.meta?.preview_image,
        skeleton_image: item?.meta?.skeleton_image,
        ppt_slide: item?.ppt_slide,
        name: item?.name,
        description: item?.description,
        slide_shape: item?.meta?.slide_shape,
      },
    });
    setUpdatePayloadPreview({
      ...updatePayloadPreview,
      product_slide: {
        type_of_item: item?.meta?.type_of_item,
        preview_image: item?.meta?.preview_image,
        skeleton_image: item?.meta?.skeleton_image,
        ppt_slide: item?.ppt_slide,
        name: item?.name,
        description: item?.description,
        slide_shape: item?.meta?.slide_shape,
        has_fallback: false,
        number_of_item: item?.meta?.number_of_item,
      },
    });
    showModalUpdate();
  };

  const onChangeSwitchProductUpdate = (checked) => {
    setUpdatePayload({
      ...updatePayload,
      template_settings: {
        ...updatePayload?.template_settings,
        slide_shape: checked ? 'vertical' : 'horizontal',
      },
    });
    setUpdatePayloadPreview({
      ...updatePayloadPreview,
      product_slide: {
        ...updatePayloadPreview?.product_slide,
        slide_shape: checked ? 'vertical' : 'horizontal',
      },
    });
  };

  const onChangeRadioProductUpdate = (e) => {
    setUpdatePayload({
      ...updatePayload,
      slide_type: e.target.value,
    });
    setUpdatePayloadPreview({
      ...updatePayloadPreview,
      product_slide: {
        ...updatePayloadPreview.product_slide,
        type_of_item: e.target.value,
      },
    });
  };

  const handleProductChangeUpdate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUpdatePayload({
      ...updatePayload,
      template_settings: {
        ...updatePayload?.template_settings,
        [name]: value,
      },
    });
    setUpdatePayloadPreview({
      ...updatePayloadPreview,
      product_slide: {
        ...updatePayloadPreview?.product_slide,
        [name]: value,
      },
    });
  };

  //img update upload
  const beforeUploadImgUpdate = (file) => {
    const isImg = [
      'image/jpg',
      'image/jpeg',
      'image/svg',
      'image/svg+xml',
      'image/png',
    ].includes(file.type);
    if (!isImg) {
      message.error(`${file.type} is not a image file`);
    }
    return isImg || Upload.LIST_IGNORE;
  };
  const uploadImgUpdate = async (options) => {
    const { file, onSuccess } = options;
    const fmData = new FormData();
    fmData.append('image', file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      axios
        .post(`${process.env.REACT_APP_KEY}files`, fmData)
        .then((response) => {
          onSuccess('Ok');
          setUpdatePayload((state) => {
            return {
              ...state,
              template_settings: {
                ...state.template_settings,
                [currentUpload]: response?.data?.data?.id,
              },
            };
          });
          setUpdatePayloadPreview((state) => {
            return {
              ...state,
              product_slide: {
                ...state.product_slide,
                [currentUpload]: response?.data?.data?.id,
              },
            };
          });
          setCurrentUpload(null);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };

  // ppt update upload
  const beforeUploadPptUpdate = (file) => {
    const isPpt = file.name.includes('pptx');
    if (!isPpt) {
      message.error(`${file.name} ${file.type} is not a ppt file`);
    }
    return isPpt || Upload.LIST_IGNORE;
  };
  const uploadPptUpdate = async (options) => {
    const { file, onSuccess } = options;
    const fmData = new FormData();
    var reader = new FileReader();
    fmData.append('uploadFile', file);
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      axios
        .post(`${process.env.REACT_APP_KEY}files`, fmData)
        .then((response) => {
          onSuccess('Ok');
          setUpdatePayload((state) => {
            return {
              ...state,
              template_settings: {
                ...state.template_settings,
                [currentUpload]: response?.data?.data?.id,
              },
            };
          });
          setUpdatePayloadPreview((state) => {
            return {
              ...state,
              product_slide: {
                ...state.product_slide,
                [currentUpload]: response?.data?.data?.id,
              },
            };
          });
          setCurrentUpload(null);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };
  const propsUploadImageUpdate = {
    beforeUpload: beforeUploadImgUpdate,
    customRequest: uploadImgUpdate,
    handleChangeUpload: handleChangeUpload,
  };

  const propsUploadProductUpdate = {
    beforeUpload: beforeUploadPptUpdate,
    customRequest: uploadPptUpdate,
    handleChangeUpload: handleChangeUpload,
  };

  //api update request
  const handleUpdate = () => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_IMPORTLIB_API}ppt_settings/${templateId}`,
        { ...updatePayload, manufacturer_id: details.userId }
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          message.info('Template updated successfully');
          setIsModalUpdateVisible(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error('Error updating PPT template');
        setIsModalUpdateVisible(false);
      });
  };

  const handlePreviewUpdate = () => {
    const user = JSON.parse(localStorage.getItem('userData') || '');
    setLoading(true);
    setUpdateLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IMPORTLIB_API}ppt_settings/create/preview`,
        {
          ...updatePayloadPreview,
          manufacturer: details?.userId,
          collection_link: collectionLink,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          axios
            .patch(
              `${process.env.REACT_APP_KEY}items/collections/${
                res?.data?.data?.collection_id || '9956'
              }?access_token=${user?.access_token}`,
              { ppt_export: res?.data?.data }
            )
            .then((res) => {
              if (res.status === 200) {
                axios
                  .get(
                    `${process.env.REACT_APP_INTERNAL_API}pptservice/?collection_link=${res.data.data.collection_link}&access-token=${user?.access_token}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      window.open(res?.data?.ppt_url, '_self');
                      setLoading(false);
                      setUpdateLoading(false);
                      message.info(
                        'Update Preview PPT downloaded successfully'
                      );
                      setUpdateFlag(false);
                      setIsEdit(false);
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    setUpdateLoading(false);
                    setIsEdit(false);
                    message.error(
                      'Error generating PPT for this catalogue. \n Please check slide details'
                    );
                  });
              }
            })
            .catch((err) => {
              setLoading(false);
              setUpdateLoading(false);
              setIsEdit(false);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        setUpdateLoading(false);
        message.error('Something went wrong, try again');
      });
  };

  //modals
  const showModal = () => {
    setLoading(true);
    setIsModalVisible(true);
    setLoading(false);
  };

  const closeModal = () => {
    setLoading(true);
    setIsModalVisible(false);
    setLoading(false);
  };

  const showModalUpdate = () => {
    setLoading(true);
    setIsModalUpdateVisible(true);
    setLoading(false);
  };

  const closeModalUpdate = () => {
    setLoading(true);
    setIsModalUpdateVisible(false);
    setLoading(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
    setIsModalUpdateVisible(false);
    setShowCollectionModal(true);
  };

  return (
    <>
      <CollectionLinkModal
        handleOk={isEdit ? handlePreviewUpdate : handlePreview}
        showModal={showCollectionModal}
        setShowModal={setShowCollectionModal}
        defaultCollectionID={defaultCollectionID}
        handleCancel={() => setShowCollectionModal(false)}
        collectionLink={collectionLink}
        setCollectionLink={setCollectionLink}
      />
      <h1>
        <b>Templates</b>
      </h1>
      <Row className="spacing">
        <Col>
          <Row>PPT Template is applicable to?</Row>
          <Row className="inner">
            <Radio.Group
              defaultValue="generic"
              buttonStyle="solid"
              onChange={onChangeRadioUser}
            >
              <Radio.Button value="generic">Generic</Radio.Button>
              <Radio.Button value="userAccount">User Account</Radio.Button>
            </Radio.Group>
          </Row>
        </Col>
      </Row>
      {details?.userType === 'userAccount' && (
        <Row className="spacing">
          <Col>
            <Row>Select user account to apply this template to</Row>
            <Row className="inner">
              <Select
                showSearch
                placeholder="Search for Users..."
                optionFilterProp="children"
                onChange={onChangeDropdown}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
              >
                {orgs.map((el, index) => (
                  <Option value={el?.value} key={index}>
                    {el?.label}
                  </Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
      )}

      <Row className="spacing">
        <Col>
          <Row>Slides you want to add :</Row>
          <Row className="inner">
            <CheckboxGroup
              options={plainOptions}
              value={details?.slides}
              onChange={onChangeSlide}
            />
          </Row>
        </Col>
      </Row>

      <div className="spacing">
        <Collapse defaultActiveKey={[]}>
          {details?.slides?.map((el) =>
            el !== 'product_slide' ? (
              <Panel header={el} key={el}>
                {el === 'intro_slide' &&
                preview?.data?.intro_slide?.ppt_slide ? (
                  <Row>
                    <Col>
                      <Row className="inner">
                        <a
                          href={`${process.env.REACT_APP_KEY}assets/${preview?.data?.intro_slide?.ppt_slide}`}
                          download
                        >
                          <Button
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size={20}
                          >
                            Download
                          </Button>
                        </a>
                      </Row>
                      <Row className="inner-left">
                        <p>
                          <BsEmojiExpressionless /> Customizations Exists.
                          Download for preview
                        </p>
                      </Row>
                      <Divider />
                    </Col>
                  </Row>
                ) : el === 'after_intro_slide' &&
                  preview?.data?.after_intro_slide?.ppt_slide ? (
                  <Row>
                    <Col>
                      <Row className="inner">
                        <a
                          href={`${process.env.REACT_APP_KEY}assets/${preview?.data?.after_intro_slide?.ppt_slide}`}
                          download
                        >
                          <Button
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size={20}
                          >
                            Download
                          </Button>
                        </a>
                      </Row>
                      <Row className="inner-left">
                        <p>
                          <BsEmojiExpressionless /> Customizations Exists.
                          Download for preview
                        </p>
                      </Row>
                      <Divider />
                    </Col>
                  </Row>
                ) : el === 'end_slides' &&
                  preview?.data?.end_slides?.ppt_slide ? (
                  <Row>
                    <Col>
                      <Row className="inner">
                        <a
                          href={`${process.env.REACT_APP_KEY}assets/${preview?.data?.end_slides?.ppt_slide}`}
                          download
                        >
                          <Button
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size={20}
                          >
                            Download
                          </Button>
                        </a>
                      </Row>
                      <Row className="inner-left">
                        <p>
                          <BsEmojiExpressionless /> Customizations Exists.
                          Download for preview
                        </p>
                      </Row>
                      <Divider />
                    </Col>
                  </Row>
                ) : null}
                {el === 'intro_slide' ? (
                  <>
                    <Switch
                      defaultunchecked="true"
                      checkedChildren="Vertical"
                      unCheckedChildren="Horizontal"
                      onChange={onChangeSwitchIntro}
                    />
                    <Row className="inner">
                      <Upload {...propsUploadIntro}>
                        <Button
                          onClick={() => {
                            setCurrentUpload('intro_slide');
                          }}
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Row>
                  </>
                ) : el === 'after_intro_slide' ? (
                  <>
                    <Switch
                      defaultunchecked="true"
                      checkedChildren="Vertical"
                      unCheckedChildren="Horizontal"
                      onChange={onChangeSwitchAfterIntro}
                    />
                    <Row className="inner">
                      <Upload {...propsUploadAfterIntro}>
                        <Button
                          onClick={() => {
                            setCurrentUpload('after_intro_slide');
                          }}
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Row>
                  </>
                ) : (
                  <>
                    <Switch
                      defaultunchecked="true"
                      checkedChildren="Vertical"
                      unCheckedChildren="Horizontal"
                      onChange={onChangeSwitchEnd}
                    />
                    <Row className="inner">
                      <Upload {...propsUploadEnd}>
                        <Button
                          onClick={() => {
                            setCurrentUpload('end_slides');
                          }}
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Row>
                  </>
                )}
                <Row className="inner-left">
                  <p>
                    <BsEmojiSmile /> Upload new format
                  </p>
                </Row>
              </Panel>
            ) : (
              <Panel header={el} key={el}>
                <h3 className="inner" style={{ color: 'green' }}>
                  New Customization Templates :
                </h3>
                <Row>
                  <Button className="uploadButton" onClick={showModal}>
                    +<br></br>New<br></br>Template
                  </Button>
                  {payload?.product_slide?.name ? (
                    <Button className="previewCard" onClick={showModal}>
                      {payload?.product_slide?.name}
                      <br></br>
                      <p className="detail">Click here to view details.</p>
                    </Button>
                  ) : null}
                </Row>

                {Array.isArray(preview?.data?.product_slide) ? (
                  <h3 className="inner" style={{ color: 'red' }}>
                    Existing Customization Templates :
                  </h3>
                ) : null}

                {Array.isArray(preview?.data?.product_slide) ? (
                  <Row>
                    {preview?.data?.product_slide?.map((item, index) => (
                      <Button
                        className="previewCard"
                        key={index}
                        onClick={() => {
                          handlePreviewTemplate(item);
                        }}
                      >
                        {item?.name}
                        <br></br>
                        <p className="detail">Click here to view details.</p>
                      </Button>
                    ))}
                  </Row>
                ) : null}
                <Modal
                  title="Product Slide Template Upload"
                  visible={isModalVisible}
                  onCancel={closeModal}
                  width={700}
                  footer={null}
                >
                  <Form className="inner-left">
                    <Form.Item label="Slide Layout">
                      <Switch
                        defaultunchecked="true"
                        checkedChildren="Vertical"
                        unCheckedChildren="Horizontal"
                        onChange={onChangeSwitchProduct}
                      />
                    </Form.Item>
                    <Form.Item label="Item Type">
                      <Radio.Group
                        defaultValue="product"
                        buttonStyle="solid"
                        onChange={onChangeRadioProduct}
                      >
                        <Radio.Button value="product">
                          Multiple products in a slide
                        </Radio.Button>
                        <Radio.Button value="image">
                          Single Product in a slide
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Maximum number of images in a slide">
                      <Input
                        name="number_of_item"
                        value={payload?.product_slide?.number_of_item}
                        onChange={handleProductChange}
                        style={{ width: '30%' }}
                      />
                    </Form.Item>
                    <Form.Item label="Title of the template">
                      <Input
                        name="name"
                        value={payload?.product_slide?.name}
                        onChange={handleProductChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Form.Item label="Description of the template">
                      <Input
                        name="description"
                        value={payload?.product_slide?.description}
                        onChange={handleProductChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Row>
                      <Form.Item label="Skeleton Image">
                        <Upload {...propsUploadImage}>
                          <Button
                            onClick={() => {
                              setCurrentUpload('skeleton_image');
                            }}
                            icon={<UploadOutlined />}
                          >
                            Skeleton
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item label="Preview Image">
                        <Upload {...propsUploadImage}>
                          <Button
                            onClick={() => {
                              setCurrentUpload('preview_image');
                            }}
                            icon={<UploadOutlined />}
                          >
                            Preview
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item label="Template PPT">
                        <Upload {...propsUploadProduct}>
                          <Button
                            onClick={() => {
                              setCurrentUpload('product_slide');
                            }}
                            icon={<UploadOutlined />}
                          >
                            Template
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Row>
                    <Row align="end">
                      <Button
                        icon={<SendOutlined />}
                        className="button"
                        type="submit"
                        onClick={closeModal}
                      >
                        Save & Close
                      </Button>
                    </Row>
                  </Form>
                </Modal>
              </Panel>
            )
          )}
        </Collapse>
        {details?.slides.length ? (
          <Row className="inner">
            <Col>
              <Row>
                <Button
                  loading={dataLoading}
                  className="button"
                  type="submit"
                  disabled={
                    !details.slides.reduce((agg, item) => {
                      return agg && payload?.[item]?.ppt_slide;
                    }, true)
                  }
                  onClick={() => setShowCollectionModal(true)}
                >
                  PREVIEW
                </Button>
              </Row>
            </Col>
            <Col>
              <Row>
                <Button
                  disabled={flag ? true : false}
                  icon={<SendOutlined />}
                  className="button"
                  type="submit"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </Button>
              </Row>
            </Col>
          </Row>
        ) : null}
        <Modal
          title="Update Product Template"
          visible={isModalUpdateVisible}
          onCancel={closeModalUpdate}
          footer={null}
          mask={false}
        >
          <Form className="inner-left">
            <Form.Item label="Slide Layout">
              <Switch
                defaultunchecked="true"
                checkedChildren="Vertical"
                unCheckedChildren="Horizontal"
                onChange={onChangeSwitchProductUpdate}
              />
            </Form.Item>
            <Form.Item label="Item Type">
              <Radio.Group
                buttonStyle="solid"
                defaultValue={updatePayload?.slide_type}
                onChange={onChangeRadioProductUpdate}
              >
                <Radio.Button value="product">Product</Radio.Button>
                <Radio.Button value="image">Image</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Template Title">
              <Input
                name="name"
                value={updatePayload?.template_settings?.name}
                onChange={handleProductChangeUpdate}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label="Template Description">
              <Input
                name="description"
                value={updatePayload?.template_settings?.description}
                onChange={handleProductChangeUpdate}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Row>
              <Form.Item label="Skeleton Image">
                <a
                  className="inner-left"
                  href={`${process.env.REACT_APP_KEY}assets/${updatePayload?.template_settings?.skeleton_image}`}
                  download
                >
                  <Button
                    shape="round"
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: '0.4rem' }}
                  >
                    Download
                  </Button>
                </a>
                <Upload {...propsUploadImageUpdate} className="inner-left">
                  <Button
                    style={{
                      backgroundColor: '#000000',
                      color: '#ffffff',
                      marginLeft: '2.5rem',
                    }}
                    onClick={() => {
                      setCurrentUpload('skeleton_image');
                    }}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="Preview Image">
                <a
                  className="inner-left"
                  href={`${process.env.REACT_APP_KEY}assets/${updatePayload?.template_settings?.preview_image}`}
                  download
                >
                  <Button
                    shape="round"
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: '0.7rem' }}
                  >
                    Download
                  </Button>
                </a>
                <Upload {...propsUploadImageUpdate} className="inner-left">
                  <Button
                    style={{
                      backgroundColor: '#000000',
                      color: '#ffffff',
                      marginLeft: '2.5rem',
                    }}
                    onClick={() => {
                      setCurrentUpload('preview_image');
                    }}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="Template PPT">
                <a
                  className="inner-left"
                  href={`${process.env.REACT_APP_KEY}assets/${updatePayload?.template_settings?.ppt_slide}`}
                  download
                >
                  <Button
                    shape="round"
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: '1rem' }}
                  >
                    Download
                  </Button>
                </a>
                <Upload {...propsUploadProductUpdate} className="inner-left">
                  <Button
                    style={{
                      backgroundColor: '#000000',
                      color: '#ffffff',
                      marginLeft: '2.5rem',
                    }}
                    onClick={() => {
                      setCurrentUpload('ppt_slide');
                    }}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Row>
            <Row className="inner">
              <Col span={12}>
                <Row>
                  <Button
                    loading={updateLoading}
                    className="button"
                    type="submit"
                    onClick={() => {
                      handleEdit();
                    }}
                  >
                    PREVIEW
                  </Button>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Button
                    disabled={updateFlag ? true : false}
                    icon={<SendOutlined />}
                    className="button"
                    type="submit"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default BasicInformation;
