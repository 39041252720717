import React from 'react';
import { useState } from 'react';
import AttributeSettingHeader from './AttributeSettingComponents/Header';
import AttributeSettingSectionPage from './CreateSection';
import AttributeModal from './AttributeSettingComponents/Modal';
import AttributeDrawer from './AttributeSettingComponents/Drawer';

import './index.less';


function AttributeSetting({orgs}) {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editedData, setEditedData] = useState({});

  const handleOpenDrawer = (data = {}) => {
    setEditedData(data);
    setIsDrawerOpen(true);
  };


  return (
    <div className="attributeSetting">
       <AttributeSettingHeader
        options={orgs}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
       />
      <AttributeSettingSectionPage
       handleOpenDrawer={handleOpenDrawer}
      />
       <AttributeDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        data={editedData}
        setData={setEditedData}
      />
      <AttributeModal />
    </div>
  );
}

export default AttributeSetting;
