import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Typography, message } from "antd";
import { getTemplateModalTypeText } from "../../../utils/excelTemplateHelpers";
import TemplateForm from "./TemplateForm";
import {
  addExcelTemplate,
  updateExcelTemplate,
} from "../../../api/excelTemplate";

const { Title } = Typography;
const btnStyle = {
  width: "100%",
};

const TemplateModal = ({
  isTemplateModalVisible,
  setIsTemplateModalVisible,
  setMode,
  mode = "create",
  uomData = {},
  selectedTemplate = {},
  setSelectedTemplate,
  selectedOrg,
  setTemplateData,
  templateData = [],
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [previewImg, setPreviewImg] = useState({});
  const [excelFile, setExcelFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [excelFileType, setExcelFileType] = useState("existing");
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const excelFileTypeHandler = useCallback(() => {
    if (mode === "create") {
      setExcelFileType("new");
    } else {
      setExcelFileType("existing");
    }
  }, [mode]);

  useEffect(() => {
    excelFileTypeHandler();
  }, [excelFileTypeHandler]);

  const handleCloseModal = () => {
    setMode("create");
    setIsTemplateModalVisible(false);
    setSelectedTemplate({});
    setFileList([]);
    setExcelFile(null);
  };

  const handleFinish = () => {
    const { name, status, preview_img, excel_file, ...uomFields } =
      form.getFieldsValue() || {};
    const requestPayload = {
      name,
      previewImageUrl: previewImg?.id,
      defaultValues: uomFields,
      manufacturerId: selectedOrg,
      isGeneric: selectedOrg ? false : true,
    };
    if (!requestPayload?.manufacturerId) delete requestPayload?.manufacturerId;
    switch (mode) {
      case "delete": {
        // delete template
        handleDeleteTemplate(selectedTemplate);
        return;
      }
      case "create": {
        // create template
        handleCreateTemplate(requestPayload);
        return;
      }
      case "edit": {
        // edit template
        handleEditTemplate(requestPayload);
        return;
      }
      default:
        return;
    }
  };

  const handleDeleteTemplate = async (template) => {
    setBtnLoading(true);
    const { defaultValues, id, name, previewImageUrl } = template || {};
    const payload = {
      id,
      name,
      defaultValues,
      previewImageUrl,
      isFileUpdated: false,
      status: "archived",
    };
    try {
      const formData = new FormData();
      formData.append(
        "file",
        new File([""], "empty.txt", { type: "text/plain" })
      );
      formData.append("request", JSON.stringify(payload));
      const res = await updateExcelTemplate(formData);
      if (res?.status === 200) {
        const filteredTemplates = templateData?.filter(
          (template) => template.id !== selectedTemplate?.id
        );
        setTemplateData(filteredTemplates);
        message.success("Template deleted successfully");
        setBtnLoading(false);
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
    }
  };

  const handleEditTemplate = async (data = {}) => {
    const payload = { ...data, id: selectedTemplate?.id, status: "published" };
    setBtnLoading(true);
    const formData = new FormData();
    if (excelFileType === "new") {
      payload.isFileUpdated = true;
      formData.append("file", excelFile);
    } else {
      payload.isFileUpdated = false;
      formData.append(
        "file",
        new File([""], "empty.txt", { type: "text/plain" })
      );
    }
    formData.append("request", JSON.stringify(payload));
    try {
      const res = await updateExcelTemplate(formData);
      if (res?.status === 200) {
        const data = res?.data?.data;
        const filteredTemplates = templateData?.filter(
          (template) => template.id !== selectedTemplate?.id
        );
        const updatedTemplates = [data, ...filteredTemplates];
        setTemplateData(updatedTemplates);
        message.success("Template updated successfully");
        setBtnLoading(false);
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
    }
  };

  const handleCreateTemplate = async (payload) => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", excelFile);
      formData.append("request", JSON.stringify(payload));
      const res = await addExcelTemplate(formData);
      if (res?.status === 200) {
        const data = res?.data?.data;
        const updatedTemplates = [data, ...templateData];
        setTemplateData(updatedTemplates);
        message.success("Template created successfully");
        setBtnLoading(false);
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
    }
  };

  const renderBody = () => {
    return (
      <TemplateForm
        template={selectedTemplate}
        mode={mode}
        uomData={uomData}
        form={form}
        handleFinish={handleFinish}
        setPreviewImg={setPreviewImg}
        setExcelFile={setExcelFile}
        excelFile={excelFile}
        excelFileType={excelFileType}
        setExcelFileType={setExcelFileType}
        fileList={fileList}
        setFileList={setFileList}
        setIsChecked={setIsChecked}
      />
    );
  };

  const renderFooter = (
    <Row justify="space-between">
      <Col span={9}>
        <Button size="large" style={btnStyle} onClick={handleCloseModal}>
          Cancel
        </Button>
      </Col>
      <Col span={9}>
        <Button
          form="template-form"
          size="large"
          style={btnStyle}
          loading={btnLoading}
          type={mode === "delete" ? "danger" : "primary"}
          htmlType="submit"
          disabled={mode === "delete" && !isChecked}
        >
          {getTemplateModalTypeText(mode, true)} Template
        </Button>
      </Col>
    </Row>
  );

  const renderTitle = (
    <Row>
      <Title level={4}>{getTemplateModalTypeText(mode)} Template</Title>
    </Row>
  );

  return (
    <Modal
      open={isTemplateModalVisible}
      onCancel={handleCloseModal}
      closable={false}
      footer={renderFooter}
      bodyStyle={{ maxHeight: "35em", overflowY: "scroll", padding: "1em 2em" }}
      title={renderTitle}
      destroyOnClose
    >
      {renderBody()}
    </Modal>
  );
};

export default TemplateModal;
