import React, { useState } from "react";
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Typography,
  Divider,
  Input,
  notification,
  Upload,
  Progress
} from "antd";
import API_KEY from "../../api/index";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { UploadOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

export default function OrganizationForm({ setResetManufacturerData, orgs }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [logo, setLogo] = useState([]);
  const [banner, setBanner] = useState([])
  const [progressDataLogo, setProgressDataLogo] = useState({});
  const [progressDataBanner, setProgressDataBanner] = useState({});
  const userData = localStorage.getItem("userData");
  const user = userData && JSON.parse(userData);

  const [error, setError] = useState(null)
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      ...values,
      logo: logo?.[0]?.id,
      banner: banner?.[0]?.id,
      status: 'published'
    }

    API_KEY.URL.post(`${process.env.REACT_APP_INTERNAL_API}manufacturer/new/create`, data,
      {
        headers: {
          'access-token': user?.access_token
        }
      }
    ) 
      .then((res) => {
        if (res.status === 200) {
          form.resetFields();
          setLoading(false);
          notification.success({
            message: 'Success',
            description: 'Organization added successfully',
          });
          setResetManufacturerData(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };
  const onFinishFailed = (errorInfo) => { };

  const uploadImageLogo = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      // onUploadProgress: event => {
      //   const percent = Math.floor((event.loaded / event.total) * 100);
      //   console.log("percent",percent)
      // }
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgressDataLogo((prevState) => {
          return { percent };
        });
        if (percent === 100) {
          setTimeout(
            () =>
              setProgressDataLogo((prevState) => {
                return { ...prevState, percent };
              }),
            1000
          );
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("image", file);

    axios
      .post(process.env.REACT_APP_ARTIFACTS, fmData, config)
      .then((response) => {
        if (response.status === 200) {
          onSuccess("Ok");
          setLogo([response?.data?.data]);
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        onError({ err });
      });
  };
  const uploadImageBanner = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      // onUploadProgress: event => {
      //   const percent = Math.floor((event.loaded / event.total) * 100);
      //   console.log("percent",percent)
      // }
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgressDataBanner((prevState) => {
          return { percent };
        });
        if (percent === 100) {
          setTimeout(
            () =>
              setProgressDataBanner((prevState) => {
                return { ...prevState, percent };
              }),
            1000
          );
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("image", file);

    axios
      .post(process.env.REACT_APP_ARTIFACTSll, fmData, config)
      .then((response) => {
        if (response.status === 200) {
          onSuccess("Ok");
          setBanner([response?.data?.data]);
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        onError({ err });
      });
  };
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={
          <Row>
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Create Organization
            </Title>
            <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
              {/* ({total}) */}
            </Text>
          </Row>
        }
        subTitle="Create Organization"
      />
      <Divider style={{marginBottom:8}} />
       
    
      <Row style={{ minHeight: "55vh" }}>
        <Col span={12} offset={2}>
        {
  error && <div style={{color: 'red'}}>{error}</div>
}
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}

          >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Country" name="country">
              <Input />
            </Form.Item>

            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>

            <Form.Item label="Logo" name="logo">
              <Upload
                multiple
                customRequest={uploadImageLogo}
                className="upload-list-inline"
                showUploadList={true}
                fileList={logo}
              >
                <Button icon={<UploadOutlined />}>Upload Logo</Button>
              </Upload>
              {progressDataLogo?.percent &&
                <Row style={{ margin: "1em" }}>
                  <Progress
                    style={{
                      marginTop: "0.5rem",
                    }}
                    percent={progressDataLogo?.percent}
                    width={"2vh"}
                  />
                </Row>
              }
            </Form.Item>
            <Form.Item label="Banner" name="banner">
              <Upload
                multiple
                customRequest={uploadImageBanner}
                className="upload-list-inline"
                showUploadList={true}
                fileList={banner}
              >
                <Button icon={<UploadOutlined />}>Upload Banner</Button>
              </Upload>
              {progressDataBanner?.percent &&
                <Row style={{ margin: "1em" }}>
                  <Progress
                    style={{
                      marginTop: "0.5rem",
                    }}
                    percent={progressDataBanner?.percent}
                    width={"2vh"}
                  />
                </Row>
              }
            </Form.Item>

            <Form.Item label=" ">
              <Row justify="space-between">
                <Button size="large" onClick={() => navigate(-1)}>
                  Cancel
                </Button>

                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
