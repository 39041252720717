import React, { useCallback, useEffect, useState } from "react";
import {
  ChonkyActions,
  ChonkyFileActionData,
  FileBrowser,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
} from "chonky";
import path from "path";
// import { useStoryLinks } from '../util';
import AWS from "aws-sdk";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { Button } from "react-bootstrap";

const BUCKET_NAME = "sourcewizpublic";
const BUCKET_REGION = "sgp1";
const ACCESS_KEY_ID = "OHYWUSFSEPPUNKI6NPKV";
const SECRET_ACCESS_KEY = "mM5l7rMb3HsLRx7ZtlasF+xg9TUF+8AYQPhzzw7po74";

const spacesEndpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");
AWS.config.update({
  endpoint: spacesEndpoint,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3();

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const fetchS3BucketContents = (bucket, prefix) => {
  return s3
    .listObjectsV2({
      Bucket: bucket,
      Delimiter: "/",
      Prefix: prefix !== "/" ? prefix : "",
    })
    .promise()
    .then((response) => {
      const chonkyFiles = [];
      const s3Objects = response.Contents;
      const s3Prefixes = response.CommonPrefixes;

      if (s3Objects) {
        chonkyFiles.push(
          ...s3Objects.map((object) => ({
            id: object.Key,
            name: path.basename(object.Key),
            modDate: object.LastModified,
            size: object.Size,
          }))
        );
      }

      if (s3Prefixes) {
        chonkyFiles.push(
          ...s3Prefixes.map((prefix) => ({
            id: prefix.Prefix,
            name: path.basename(prefix.Prefix),
            isDir: true,
          }))
        );
      }

      return chonkyFiles;
    });
};

const storyName = "AWS S3 Browser";

const S3BrowserComponent = ({ setSelectedImages }) => {
  const [error, setError] = useState(null);
  const [folderPrefix, setKeyPrefix] = useState("/");
  const [files, setFiles] = useState([]);
  const fileBrowserRef = React.useRef(null);
  useEffect(() => {
    fetchS3BucketContents(BUCKET_NAME, folderPrefix)
      .then((res) => {
        console.log("res:", res);
        setFiles(
          res.map((file) => {
            return {
              ...file,
              thumbnailUrl: `https://${BUCKET_NAME}.sgp1.digitaloceanspaces.com/${file?.id}`,
            };
          })
        );
      })
      .catch((error) => setError);
  }, [folderPrefix, setFiles]);

  const folderChain = React.useMemo(() => {
    let folderChain;
    if (folderPrefix === "/") {
      folderChain = [];
    } else {
      let currentPrefix = "";
      folderChain = folderPrefix
        .replace(/\/*$/, "")
        .split("/")
        .map((prefixPart) => {
          currentPrefix = currentPrefix
            ? path.join(currentPrefix, prefixPart)
            : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          };
        });
    }
    folderChain.unshift({
      id: "/",
      name: BUCKET_NAME,
      isDir: true,
    });
    return folderChain;
  }, [folderPrefix]);

  const handleFileAction = useCallback(
    (data) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload.files && data.payload.files.length !== 1) return;
        if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;

        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        console.log(`Key prefix: ${newPrefix}`);
        setKeyPrefix(newPrefix);
      }
    },
    [setKeyPrefix]
  );

  return (
    <div className="story-wrapper">
      <div className="story-description">
        <h1 className="story-title">{BUCKET_NAME}</h1>
        {error && (
          <div className="story-error">
            An error has occurred while loading bucket: <strong>{error}</strong>
          </div>
        )}
      </div>
      <div style={{ height: "80vh", width: "100%" }}>
        <FileBrowser
          instanceId={storyName}
          files={files}
          folderChain={folderChain}
          onFileAction={handleFileAction}
          ref={fileBrowserRef}
        >
          <FileNavbar />
          <FileToolbar />
          <FileList style={{ background: "black" }} />
        </FileBrowser>
      </div>
      {/* <Button onClick={()=>setSelectedImages([...fileBrowserRef.current.getFileSelection()])}>Get selected</Button> */}
    </div>
  );
};

export default S3BrowserComponent;
