import PYTHON_KEY from "./pythonApis";

const getAllExcelTemplates = () => {
  return PYTHON_KEY.URL.get(PYTHON_KEY.path.getExcelTemplates, {
    headers: { application: "sourcewiz" },
  });
};

const getManufacturerExcelTemplates = (id) => {
  return PYTHON_KEY.URL.get(PYTHON_KEY.path.getExcelTemplates, {
    headers: { application: "sourcewiz" },
    params: { manufacturer_id: id },
  });
};

const addExcelTemplate = (payload) => {
  return PYTHON_KEY.URL.post(PYTHON_KEY.path.addExcelTemplate, payload, {
    headers: {
      application: "sourcewiz",
      "Content-type": "multipart/form-data",
    },
  });
};

const updateExcelTemplate = (data) => {
  return PYTHON_KEY.URL.put(PYTHON_KEY.path.updateExcelTemplate, data, {
    headers: {
      application: "sourcewiz",
      "Content-type": "multipart/form-data",
    },
  });
};

export {
  getAllExcelTemplates,
  getManufacturerExcelTemplates,
  addExcelTemplate,
  updateExcelTemplate,
};
