import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Select,
  PageHeader,
  notification,
  Row,
  Col,
  Typography,
  Divider,
  Input,
} from "antd";
import API_KEY from "../../api/index";

import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

export default function ProductForm() {
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        limit: -1,
        fields:
          "id,name,secondary_categories.secondary_category_id.name,secondary_categories.secondary_category_id.id",
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
      }
    });
  }, []);

  // handle org change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };

  const onFinish = (values) => {
    setLoading(true);
    console.log(values);
    API_KEY.URL.post(API_KEY.path.secondary_category, values)
      .then((res) => {
        if (res.status === 200) {
          const data = {
            manufacturer_id: values.manufacturer,
            secondary_category_id: res?.data?.data?.id,
          };

          API_KEY.URL.post(API_KEY.path.categories, data)
            .then((res) => {
              form.resetFields();
              setLoading(false);
              notification.success({
                message: "Success",
                description: "Orgnization added successfully",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={
          <Row>
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Create Category
            </Title>
            <Text type="secondary" style={{ margin: 4, fontSize: 14 }}></Text>
          </Row>
        }
        subTitle="Create Category"
      />
      <Divider />
      <Row style={{ minHeight: "70vh" }}>
        <Col span={12} offset={2}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              primary_category: 4,
            }}
          >
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>

            <Form.Item hidden name="primary_category" label="primary_category">
              <Input />
            </Form.Item>

            <Form.Item name="manufacturer" label="Organization">
              <Select
                placeholder="Select Organization"
                onChange={handleOrgChange}
                value={selectedOrg}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allOrgsData.map((org, index) => (
                  <Select.Option value={org?.id} key={index}>
                    {org?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label=" ">
              <Row justify="space-between">
                <Button size="large" onClick={() => navigate(-1)}>
                  Cancel
                </Button>

                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
