import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Table,
  Tag,
  Skeleton,
  message,
} from "antd";
import moment from "moment";

import style from "styled-components";
import "./payment_invoice.css";
import axios from "axios";
import { CreateInvoice } from "../Invoice/CreateInvoice";
import InvoiceDetailsModal from "../Invoice/InvoiceDetailsModal";
import { numberWithCommas } from "./numberWithCommas";

const { Title, Text } = Typography;

const Subdiv = style.div`
	flexDirection: "row";
	border: 1px solid #E6E7E7;
	box-sizing: border-box;
	margin : 1rem 0 1rem 0;
	padding: 1.3rem;
    width: 100%;
	opacity: 1;
  mix-blend-mode: normal;
  border-radius: 0.5rem;`;

const PlanDiv = style.div`
  width: 100%;
  margin-top: 1rem;
  padding: 1.7rem;
  border-radius: 0.5rem;
  background: linear-gradient(90.61deg, rgb(230, 223, 251, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
  mix-blend-mode: normal;`;

export const PaymentAndInvoice = ({ selectedOrg, user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (payload) => {
    setBtnLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_INTERNAL_API}invoice/${invoiceDetails.id}`,
        { ...payload, customer_id: selectedOrg },
        {
          headers: {
            "access-token": user?.access_token,
          },
        }
      )
      .then((res) => {
        setBtnLoading(false);
        setIsModalVisible(false);

        setDataSource((state) => {
          return state.map((item) => {
            return item.id === invoiceDetails.id ? res?.data : item;
          });
        });
        message.success("Invoice updated successfully");
      })
      .catch((err) => {
        setBtnLoading(false);
        message.error("Error updating invoice");
      });
  };

  const handleCancel = () => {
    setInvoiceDetails(null);
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Edit Details",
      dataIndex: "edit",
      key: "edit",
      render: (text, row) => {
        return (
          <Button
            onClick={() => {
              setInvoiceDetails(row);
              showModal();
            }}
          >
            Edit
          </Button>
        );
      },
    },
    { title: "Invoice", dataIndex: "name", key: "name" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Invoice date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text, record) => (
        <Text key={record?.invoice_date}>
          {new Date(record?.invoice_date)?.toLocaleString("en-IN", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "invoice_status",
      key: "invoice_status",
      align: "center",
      render: (text, record) => (
        <>
          <Tag
            key={record?.payment_status}
            className="table-payment-status"
            style={{
              color: `${
                record?.invoice_status === "pending" ? "#C23E3E" : "#78991A"
              }`,
              backgroundColor: `${
                record?.invoice_status === "pending" ? "#FDDBDB" : "#E6F4BE"
              }`,
            }}
          >
            {record?.invoice_status?.charAt(0).toUpperCase() +
              record?.invoice_status?.slice(1) || "Unknown"}
          </Tag>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "url",
      key: "url",
      align: "center",
      render: (text, record) => (
        <Button
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_KEY}assets/${record?.url}`,
              "_blank"
            )
          }
        >
          View invoice
        </Button>
      ),
    },
  ];
  const [paymentStatus, setPaymentStatus] = useState("");
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getSubscriptionData();
  }, [selectedOrg]);
  const paymentStatusChecker = (data) => {
    if (data) {
      let pendingData = data?.invoices?.filter(
        (el) => el.invoice_status === "pending"
      );
      if (pendingData.length > 0) {
        setPaymentStatus("pending");
      } else {
        setPaymentStatus("paid");
      }
    }
  };
  const getSubscriptionData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_INTERNAL_API}subscription/`, {
        headers: { "access-token": `${user?.access_token}` },
        params: {
          access_token: `${user?.access_token}`,
          m_id: `${selectedOrg}`,
        },
      })
      .then((res) => {
        setDataSource(res?.data?.invoices);
        setData(res?.data);
        if (res?.status === 200) {
          paymentStatusChecker(res?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return loading ? (
    <>
      <Row>
        <Skeleton active />
      </Row>
      <Row>
        <Skeleton active />
      </Row>
      <Row>
        <Skeleton active />
      </Row>
    </>
  ) : (
    <>
      <PlanDiv>
        <Row justify="space-between">
          <Col>
            <Row>Current plan</Row>
            <Title level={5} className="discount-top-margin">
              {data?.recurring_plan_details?.current_plan?.plan_label}
              {/* Sourcewiz GOLD */}
            </Title>
          </Col>
          <Col>
            <Row
              className="payment-status-div"
              style={{
                background: `${
                  paymentStatus === "pending"
                    ? "#F34D4D"
                    : "linear-gradient(92.85deg, #54A093 1.86%, #3C9283 110.57%)"
                }`,
              }}
            >
              <Text className="payment-text">Payment {paymentStatus}</Text>
            </Row>
          </Col>
        </Row>
      </PlanDiv>

      {/* Breakdown Section */}
      <Subdiv>
        <Title level={5}>Breakdown</Title>
        <br />
        <Row justify="space-between">
          <Col>
            <Text>
              Subscription for{" "}
              {data?.recurring_plan_details?.current_plan?.plan_name?.toUpperCase()}{" "}
              plan
            </Text>
          </Col>
          <Col className="text-bold">
            ₹{" "}
            {numberWithCommas(
              data?.recurring_plan_details?.current_plan?.amount
            )}{" "}
            / year
          </Col>
        </Row>
        {data?.recurring_plan_details?.current_plan?.discount_value &&
        <Row justify="space-between" className="discount-top-margin">
          <Col className="small-text">
            Discount (
            {data?.recurring_plan_details?.current_plan?.discount_value} %)
          </Col>
          <Col>
            - ₹{" "}
            {numberWithCommas(
              data?.recurring_plan_details?.current_plan?.amount_deduct
            )}
          </Col>
        </Row>}
        <br />
        {data?.recurring_plan_details?.recurring_addon?.map((e) => {
          return (
            <>
              <Row justify="space-between">
                <Col>
                  <Text>{e?.addon_label} ({e?.limit})</Text>
                </Col>
                <Col className="text-bold">
                  ₹ {numberWithCommas(e?.amount)} / year
                </Col>
              </Row>
              {e?.discount_value &&
              <Row justify="space-between" className="discount-top-margin">
                <Col className="small-text">
                  Discount ({e?.discount_value} %)
                </Col>
                <Col>- ₹ {numberWithCommas(e?.amount_deduct)}</Col>
              </Row>}
              <Row justify="space-between" className="discount-top-margin">
                <Col className="small-text">
                  Purchased on - {moment(e?.start_date).format("Do MMMM YYYY")}
                </Col>
              </Row>
              <br />
            </>
          );
        })}
        <Row justify="space-between" className="breakdown-total-row">
          <Col>Total amount</Col>
          <Col className="text-bold">
            ₹ {numberWithCommas(data?.recurring_plan_details?.total_amount)} /
            year
          </Col>
        </Row>
      </Subdiv>

      {/* OneTime Charges Section */}
      <Subdiv>
        <Title level={5}>One time charges</Title>
        <br />
        {data?.one_time_addons?.addon_list?.map((e) => {
          return (
            <>
              <Row justify="space-between">
                <Col>{e?.addon_label}</Col>
                <Col className="text-bold">₹ {numberWithCommas(e?.amount)}</Col>
              </Row>
              {e?.discount_value &&
              <Row justify="space-between" className="discount-top-margin">
                <Col className="small-text">
                  Discount ({e?.discount_value} %)
                </Col>
                <Col>- ₹ {numberWithCommas(e?.amount_deduct)}</Col>
              </Row>}
              <br />
            </>
          );
        })}
        <Row justify="space-between" className="onetime-total-row">
          <Col>Total amount</Col>
          <Col className="text-bold">
            ₹ {numberWithCommas(data?.one_time_addons?.total_amount)}
          </Col>
        </Row>
      </Subdiv>
      <br />

      {/* data?.INVOICES SECTION */}
      <Row style={{ justifyContent: "space-between", color: "#0B7764" }}>
        <Col>
          <Title level={5}>Invoices</Title>
        </Col>
        <Col>
          <CreateInvoice
            setDataSource={setDataSource}
            selectedOrg={selectedOrg}
          />
        </Col>
      </Row>

      <InvoiceDetailsModal
        {...{
          isModalVisible,
          handleCancel,
          handleOk,
          setIsModalVisible,
          isCreate: false,
          invoiceDetails,
          btnLoading,
          setBtnLoading,
        }}
      />

      <br />
      <Table
        id="invoice-table"
        columns={columns}
        style={{ width: "100%" }}
        dataSource={dataSource}
        pagination={false}
      ></Table>
    </>
  );
};
