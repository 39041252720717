import React, { useState, useEffect } from "react";
import BasicInformation from "./BasicInformation";
import "./styles.less";
import API_KEY from "../../api";

const Template = () => {
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);

  //api get request
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        limit: -1,
        fields:
          "id,name",
        filter: {
          _and: [
            {
              status: {
                _nin: ["archived"],
              },
            },
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },
            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
          ],
        },
      },
    }).then((res) => {
      if (res?.data?.data) {
        setOrgs(
          res?.data?.data.map((org) => {
            return {
              label: `${org.name}`,
              value: org.id,
              key: org.id,
            };
          })
        );
      }
    });
  }, []);

  return (
    <>
      <BasicInformation orgs={orgs} />
    </>
  );
};

export default Template;
