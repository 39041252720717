import { Button, Modal, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { useState } from "react";
import SelectAddonBody from "./SelectAddonBody";

export default function SelectAddonModal ({
    visibleAddonModal, 
    onCloseModal, 
    onAddonOk, 
    onCancel, 
    selectedOrg, 
    selectedRow, 
    setSelectedRow,
    selectedRowId,
    moduleId
}) {
    const [finalData, setFinalData] = useState({});
    return(
        <Modal
            title={
                <div>
                    <Space direction="vertical">
                        <Text style={{fontSize:18, fontWeight:700}} >
                            Resolve request
                        </Text>
                        <Text type="secondary" style={{fontSize:16}} >
                            Add details for the add on request
                        </Text>
                    </Space>
                </div>
            }
            visible={visibleAddonModal}
            okText="Add"
            closable={false}
            onCancel={onCancel}
            onOk={onAddonOk}
            width={400}
            centered
            afterClose={onCloseModal}
            footer={(
                <Space direction="horizontal" style={{justifyContent:"space-between", width:"100%"}} >
                    <Button
                        key="cancel" 
                        style={{width:104, height:40, backgroundColor:"#FFFFFF", color:"#4F5655", left:0, alignSelf:"end "}} 
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button 
                        key="resolve" 
                        style={{width:104, height:40, backgroundColor:"#1D2624", color:"#FFFFFF", right:0, alignSelf:"start"}} 
                        onClick={()=>{onAddonOk(finalData);}}
                    >
                        Resolve
                    </Button>
            </Space>
        )}
        >
            <SelectAddonBody
                selectedOrg={selectedOrg} 
                selectedRow={selectedRow} 
                setSelectedRow={setSelectedRow} 
                setFinalData={setFinalData} 
                finalData={finalData}
                selectedRowId={selectedRowId} 
                onCancel={onCancel}
                moduleId={moduleId}
            />
        </Modal>
    )
}