import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Form, Input, Button } from "antd";
import Logo from "../../assets/logo.png";
import { useGlobal } from "../../store/index"
import { useNavigate } from "react-router-dom";
import API_KEY from "../../api/index";

export default function SignIn() {
  const navigate = useNavigate();
  const [globalState, globalActions] = useGlobal();

  const onFinish = async (values) => {

    console.log("Success:", values);

    console.log(globalActions);

    API_KEY.URL.post(API_KEY.path.login, values)
      .then((userData) => {
        localStorage.setItem("userData", JSON.stringify(userData.data.data));
        localStorage.setItem("userLog", JSON.stringify(values.email));
        console.log(localStorage.getItem('userData'));
        if (localStorage.getItem('userData')) {
          console.log("exists");
          // navigate('/products');
          window.location.reload();
        }

        globalActions.signIn(userData);
      })


  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <Row
      style={{
        height: "100vh",
        background: "#F0F2F5"
        //   "radial-gradient( circle 610px at 5.2% 51.6%,  rgba(5,8,114,1) 0%, rgba(7,3,53,1) 97.5% )",
      }}
    >
      <Col span={24}>
        <div className="sign-in-container">
          <Row style={{ marginTop: "2em" }}>
            <Col offset={2}>
              <img src={Logo} alt="logo" width={40}></img>
            </Col>
            <Col push={1}>
              {" "}
              <Typography.Title level={2} style={{ fontFamily: "Gilroy" }}>
                Sourcewiz
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Typography.Title level={3} type="secondary">
                Admin Dashboard
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col offset={2} span={20}>
              <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={(e) => onFinish(e)}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ marginTop: "4em" }}
                >
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
