import { Layout, Menu, Typography, Row, Col, Button } from "antd";
import {
  MdHome,
  MdWork,
  MdSupervisorAccount,
  MdAddchart,
  MdInventory,
  MdUpcoming,
  MdImage,
  MdFeaturedPlayList,
} from "react-icons/md";
import { MdImageSearch, MdTaskAlt, MdOutlineRequestPage } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { FcApproval } from "react-icons/fc";
import RouteApp from "../../routes/index";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { useGlobal } from "../../store/index";
import { useEffect, useState } from "react";
import sidebarActions from "../../redux/actions/sidebarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { allowedUsers } from "../Approvals/allowedUsers";

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
function SZLayout({ selectedMenuItem, actions, allHits }) {
  const [isAllowed, setIsAllowed] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const logout = () => {
    window.location.reload();
    globalActions.signOut();
  };
  const currentUser = JSON.parse(localStorage.getItem("userLog"));
  useEffect(() => {
    if (currentUser && allowedUsers?.includes(currentUser)) {
      setIsAllowed(true);
    }
  }, [currentUser]);
  return (
    <div>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          style={{ backgroundColor: "#fff" }}
        >
          <span
            style={{
              marginTop: 8,
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-around",
              padding: 5,
              borderBottom: "1px solid lightgray",
            }}
          >
            <img src={Logo} width={32} height={32} alt="logo" />
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Sourcewiz
            </Title>
          </span>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedMenuItem]}
            onClick={(e) => {
              console.log("e::", selectedMenuItem);
              actions.selectTab(e?.key);
            }}
          >
            <Menu.Item key="1" icon={<MdHome style={{ fontSize: 20 }} />}>
              <Link to="/">
                {" "}
                <Title level={5} style={{ margin: 0 }}>
                  Home
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<MdWork style={{ fontSize: 20 }} />}>
              <Link to="/organizations">
                <Title level={5} style={{ margin: 0 }}>
                  Organisations
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<MdSupervisorAccount style={{ fontSize: 20 }} />}
            >
              <Link to="/users">
                {" "}
                <Title level={5} style={{ margin: 0 }}>
                  Users
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<MdAddchart style={{ fontSize: 20 }} />}>
              <Link to="/products">
                <Title level={5} style={{ margin: 0 }}>
                  Products
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<MdImage style={{ fontSize: 20 }} />}>
              <Link to="/image-browser">
                <Title level={5} style={{ margin: 0 }}>
                  Image Browser
                </Title>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="5" icon={<MdInventory style={{ fontSize: 20 }} />}>
              <Link to="/categories">
                <Title level={5} style={{ margin: 0 }}>
                  Categories
                </Title>
              </Link>
            </Menu.Item> */}

            <Menu.Item
              key="6"
              icon={<MdImageSearch style={{ fontSize: 20 }} />}
            >
              <Link to="/image-search">
                <Title level={5} style={{ margin: 0 }}>
                  Image Search
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item key="7" icon={<MdUpcoming style={{ fontSize: 20 }} />}>
              <Link to="/attributes">
                <Title level={5} style={{ margin: 0 }}>
                  Attributes
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item key="8" icon={<HiTemplate style={{ fontSize: 20 }} />}>
              <Link to="/templates">
                <Title level={5} style={{ margin: 0 }}>
                  Templates
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item key="9" icon={<HiTemplate style={{ fontSize: 20 }} />}>
              <Link to="/excel-templates">
                <Title level={5} style={{ margin: 0 }}>
                  Excel Templates
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="10"
              icon={<MdOutlineRequestPage style={{ fontSize: 20 }} />}
            >
              <Link to="/requests">
                <Title level={5} style={{ margin: 0 }}>
                  Requests
                </Title>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="11"
              icon={<HiOutlineCurrencyRupee style={{ fontSize: 20 }} />}
            >
              <Link to="/plans">
                <Title level={5} style={{ margin: 0 }}>
                  Plans
                </Title>
              </Link>
            </Menu.Item>
            {isAllowed && (
              <Menu.Item key="12" icon={<MdTaskAlt style={{ fontSize: 20 }} />}>
                <Link to="/approvals">
                  <Title level={5} style={{ margin: 0 }}>
                    Approvals
                  </Title>
                </Link>
              </Menu.Item>
            )}
            {isAllowed && (
              <Menu.Item
                key="13"
                icon={<MdFeaturedPlayList style={{ fontSize: 20 }} />}
              >
                <Link to="/features">
                  <Title level={5} style={{ margin: 0 }}>
                    Features
                  </Title>
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{
              padding: 0,
              backgroundColor: "#fff",
              borderBottom: "1px solid lightgray",
            }}
          >
            <Row align="middle" justify="space-between">
              <Col>
                {" "}
                <Title level={4} style={{ padding: 20 }} type="secondary">
                  Admin Dashboard
                </Title>
              </Col>
              <Col>
                <Button
                  type="ghost"
                  style={{ margin: 20, color: "gray" }}
                  onClick={() => logout()}
                >
                  Logout
                </Button>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: "24px 16px 0" }}>
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                // height: '90vh',overflowY:'scroll',

                backgroundColor: "#fff",
              }}
            >
              <RouteApp />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>Sourcewiz © 2021</Footer>
        </Layout>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedMenuItem: state.manageSideBar?.selectedMenuItem,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      selectTab: sidebarActions.selectTab,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SZLayout);
