import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Select,
  PageHeader,
  Upload,
  Row,
  Col,
  Typography,
  Divider,
  message,
  List,
} from "antd";
import API_KEY from "../../api/index";
import XLSX from "xlsx";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

export default function ProductForm() {
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        limit: -1,
        fields:
          "id,name,secondary_categories.secondary_category_id.name,secondary_categories.secondary_category_id.id",
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
      }
    });
  }, []);

  // handle org change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    setSelectedCategory(null);
    setCategoryName(null);
    setColumnList([]);
    const findCategories = allOrgsData.find((org) => org.id === value);
    setOrgName(findCategories);
    setCategories(findCategories.secondary_categories);
   
  };

  // handle category change
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    const findCategories = categories.find(
      (category) => category?.secondary_category_id?.id === value
    );
    setCategoryName(findCategories);
    console.log(findCategories);
    API_KEY.URL.get(`${API_KEY.path.secondary_category}/${value}`, {
      params: {
        fields:
          "attributes.attributes_id.name,attributes.attributes_id.id,attributes.attributes_id.dtype",
      },
    }).then((res) => {
      setAttributes(res?.data?.data?.attributes);
    });
  };

  // handle export file to excel
  const exportFile = (data) => {
    const filename = `${orgName?.name}-${categoryName?.secondary_category_id?.name}.xlsx`;
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet([data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, filename || "bulk-upload.xlsx");
  };

  // handle see list
  const handleSeeList = () => {
    const priceFields = attributes.filter(
      (attr) => attr?.attributes_id?.dtype === "price"
    );
    const addUnitFields = priceFields.map((attr) => {
      return {
        ...attr,
        attributes_id: {
          ...attr?.attributes_id,
          name: `${attr?.attributes_id?.name}_unit`,
        },
      };
    });
    const addCurrencyFields = priceFields.map((attr) => {
      return {
        ...attr,
        attributes_id: {
          ...attr?.attributes_id,
          name: `${attr?.attributes_id?.name}_currency`,
        },
      };
    });
    const nonPriceFields = attributes.filter(
      (attr) => attr.attributes_id.dtype !== "price"
    );
    // const staticFields = [
    //   { attributes_id: { name: "Filepath" } },
    //   { attributes_id: { name: "Category" } },
    //   { attributes_id: { name: "Product ID" } },
    // ];

    var arrays = [
      priceFields,
      addUnitFields,
      addCurrencyFields,
      nonPriceFields,
      // staticFields,
    ];

    var merged = arrays.reduce((acc, next) => acc.concat(next));

    

    const attrNames = merged.map((attr) => attr.attributes_id.name);

    var sorted = attrNames.sort();
    sorted = ["Product ID", "Filepath", "Category"].concat(sorted);
    var object = sorted.reduce(
      (obj, item) => Object.assign(obj, { [item]: "" }),
      {}
    );
    setColumnList(object);
    return object;
  };
  // handle download file and create columns for excel
  const handleDownload = () => {
    const object = handleSeeList();
    exportFile(object);
  };

  const beforeUpload = (file) => {
    console.log("file.type", file.type);
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      // if (file.type !== 'image/png') {
      message.error(`${file.name} is not a supported file`);
    }
    return file.type === "image/png" ? true : Upload.LIST_IGNORE;
  };
  const upload = (options) => {
    console.log("options", options);
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    console.log(file, file);
  };

  const handleChangeUpload = (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file, "here");
      // Get this url from response in real world.
    }
  };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: upload,
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: false,
      showRemoveIcon: true,
      removeIcon: false,
    },
  };
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={
          <Row>
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Bulk Upload
            </Title>
            <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
              {/* ({total}) */}
            </Text>
          </Row>
        }
        subTitle="Select the organization and category to upload the products, you can also download the sample file to upload the products"
      />
      <Divider />
      <Row style={{ minHeight: "75vh" }}>
        <Col span={12} offset={2}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
          >
            <Form.Item label="Organization">
              <Select
                placeholder="Select Organization"
                onChange={handleOrgChange}
                value={selectedOrg}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allOrgsData.map((org, index) => (
                  <Select.Option value={org?.id} key={index}>
                    {org?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Category">
              <Select
                placeholder="Select Category"
                // options={categories}
                onChange={handleCategoryChange}
                value={selectedCategory}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {categories.map((cat, index) => (
                  <Select.Option
                    value={cat?.secondary_category_id?.id}
                    key={index}
                    
                  >
                    {cat?.secondary_category_id?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label=" ">
              <Row justify="space-between">
                <Button
                  disabled={selectedOrg && selectedCategory ? false : true}
                  onClick={() => handleDownload()}
                >
                  Download Excel
                </Button>

                <Upload {...props}>
                  <Button
                    type="primary"
                    disabled={selectedOrg && selectedCategory ? false : true}
                  >
                    Upload Excel
                  </Button>
                </Upload>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        {
          categoryName && 
          <Col span={8} offset={2}>
          <List
            itemLayout="horizontal"
            bordered
            dataSource={Object.keys(columnList)}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text mark>{item}</Typography.Text>
              </List.Item>
            )}
            header={
              <Row justify="space-between">
                <Title level={5}>
                  {categoryName?.secondary_category_id?.name}
                </Title>
                <Button
                  type="link"
                  size="small"
                  disabled={selectedOrg && selectedCategory ? false : true}
                  onClick={() => handleSeeList()}
                >
                  See List
                </Button>
              </Row>
            }
          />
        </Col>
        }
      </Row>
    </>
  );
}
