import React, { useEffect, useState } from 'react';
import { Row, Table } from 'antd';
import {
  MdOutlineDragIndicator,
  MdDelete,
  MdEdit,
  MdCheckCircle
} from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import EmptyAttribute from './EmptyAttribute';
import {
  toggleModal,
  deleteAttribute,
} from '../../../redux/actions/attributeSettingActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { updateAttribute } from '../../../api/attributeSettingsApis';
import { openNotification } from '../AttributeSettingComponents/Notification';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DragHandle = SortableHandle(() => (
  <MdOutlineDragIndicator
    style={{ cursor: 'grab', color: 'rgba(155, 159, 158, 1)' }}
    size={30}
  />
));

function ManageAttributes({ sourceData, attributeCount, sectionName, handleOpenDrawer }) {
  const [attributeTableData, SetAttributeTabledata] = useState([]);
  const selectedOrg = useSelector(state => state.attributeSetting.getOrgs)

  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let length = attributeTableData.length;
    if (oldIndex !== newIndex) {
      const newArr = Array.from(attributeTableData);
      const [reOrderedArr] = newArr.splice(oldIndex, 1);
      newArr.splice(newIndex, 0, reOrderedArr);
      let updatedArr = newArr.map((item, index) => ({
        id: item?.id,
        priority: length - index,
      }));
      SetAttributeTabledata(newArr);
      handleUpdate(updatedArr, attributeTableData, selectedOrg);
    }
  };

  const handleUpdate = (data, oldArr, manufacturerId) => {
    updateAttribute(data, manufacturerId)
      .then((res) => {
        if (res?.status === 200) {
          openNotification(
            'top',
            'Attribute updated successfully',
            <MdCheckCircle style={{ color: 'rgb(0,119,100)' }} />
          );
        }
      })
      .catch((err) => {
        openNotification(
          'top',
          'Something went wrong please try again',
          <IoMdCloseCircle style={{ color: 'red' }} />
        );
        SetAttributeTabledata(oldArr);
        console.error(err);
      });
  }

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = attributeTableData.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const iconStyle = {
    border: '1px solid rgba(205, 207, 206, 1)',
    borderRadius: '4px',
    padding: '8px',
  };

  useEffect(() => {
    sourceData?.forEach((ele, index) => {
      SetAttributeTabledata((prev) => [
        ...prev,
        {
          ...ele,
          key: index,
          index: index,
          type: ele?.dTypeName,
        },
      ]);
    });
  }, []);

  const handleDisableButton = (name,dtype) => {
    if (name === 'base_selling_price' || name === 'calculated_selling_price' || name === 'description' ||  dtype === 'dropdown_percent') {
      return 'disableTableIcon';
    } else {
      return 'tableIcon';
    }
  };


  const handleDelete = (id) => {
    if (attributeCount > 1) {
      dispatch(toggleModal({ modalState: true, modalType: 'deleteAttribute' }));
      dispatch(deleteAttribute({ id: id, name: sectionName }));
    } else {
      openNotification(
        'top',
        'Every section must have at least 1 attribute',
        <IoMdCloseCircle style={{ color: 'red' }} />
      );
    }
  };

  const handleEdit = (data) => {
    handleOpenDrawer({ ...data, attributeCount: attributeCount });
  };

  const handleOnClick = (name,id,type,data) => {
    if (name === 'base_selling_price' || name === 'calculated_selling_price' || name === 'description' ||  data?.dtype === 'dropdown_percent') {
      return null;
    } else {
      if (type === 'delete') {
        handleDelete(id);
      } else {
        handleEdit(data);
      }
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: 350,
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      render: (name) => {
        return <span style={{ textTransform: 'capitalize' }}>{name}</span>;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (data) => {
        return (
          <Row style={{ gap: '8px' }} justify="center">
            <MdDelete
              className={handleDisableButton(data.internal_name,data.dtype)}
              style={iconStyle}
              size={35}
              onClick={() => handleOnClick(data.internal_name, data.id, 'delete', data)}
            />
            <MdEdit
              onClick={() => handleOnClick(data.internal_name, data.id, 'edit', data)}
              style={iconStyle}
              size={35}
              className={handleDisableButton(data.internal_name,data.dtype)}
            />
          </Row>
        );
      },
    },
  ];

  return (
    <>
      {attributeCount > 0 ? (
        <Table
          columns={columns}
          pagination={false}
          dataSource={attributeTableData}
          rowKey={'index'}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      ) : (
        <EmptyAttribute
          handleOpenDrawer={handleOpenDrawer}
        />
      )}
    </>
  );
}

export default ManageAttributes;
