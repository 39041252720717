import React from "react";
import { useState, useEffect } from "react";
import { InboxOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Modal,
  Space,
  Button,
  Typography,
  Input,
  DatePicker,
  Select,
  Skeleton,
  Divider,
  message,
  Upload,
} from "antd";
import moment from "moment";
import axios from "axios";

const InvoiceDetailsModal = ({
  isModalVisible,
  handleCancel,
  handleOk,
  isCreate = true,
  btnLoading,
  invoiceDetails = {},
  setResetFields,
  resetFields,
}) => {
  const userData = localStorage.getItem("userData");
  const user = userData && JSON.parse(userData);
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({});
  const [imageUploaded, setImageUploaded] = useState(false);
  const [currencies, setCurrencies] = useState([])

  const { Text, Title } = Typography;
  const { Option } = Select;

  const { Dragger } = Upload;

  useEffect(() => {
    if (resetFields) {
      setResetFields(false);
      form.resetFields();
    }
  }, [resetFields]);

  useEffect(() => {
    if (invoiceDetails) {
      const mappedValues = Object.keys(invoiceDetails).reduce((agg, item) => {
        return {
          ...agg,
          [item]:
            item === "invoice_date"
              ? moment(invoiceDetails[item]) || null
              : invoiceDetails[item],
        };
      }, {});
      setInitialValues({ ...mappedValues });
      form.setFieldsValue({
        ...mappedValues,
      });
    }
  }, [invoiceDetails]);

  useEffect(()=> {
    if (isModalVisible) {
      axios.get(`${process.env.REACT_APP_INTERNAL_API}currency/`, 
      { 
          params: {
              "access_token": user?.access_token
          },
          headers:{
              "access-token":user?.access_token
          }
      }
      ).then((res) => {
          if(res?.status===200){
              const currency_data = res?.data;
              const currency_list = currency_data.map((item)=>{
                  return {
                      label: `${item?.code} (${item?.symbol})`,
                      value: item?.id,
                      key: item?.id,
                  }
              });
              setCurrencies(currency_list);
          }
      }).catch((err)=>{
          //setLoading(false);
          console.log(err);
      });
    }
  }, [isModalVisible]);

  const props = {
    name: "image",
    multiple: false,
    showUploadList: false,
    action: `${process.env.REACT_APP_ARTIFACTS}`,
    accept: ".pdf",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        form.setFieldsValue({
          url: info?.file?.response?.data?.id,
        });
        setImageUploaded(true);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Modal
      visible={isModalVisible}
      style={{ marginBottom: 0, borderRadius: "8px" }}
      closable={false}
      width={460}
      destroyOnClose={true}
      onCancel={() => {
        form.resetFields();
      }}
      centered
      title={
        <div>
          <Space direction="vertical">
            <Text style={{ fontSize: 18, fontWeight: 700 }}>Add Invoice</Text>
          </Space>
        </div>
      }
      footer={
        <Space
          direction="horizontal"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <Button
            key="cancel"
            style={{
              width: 104,
              height: 40,
              backgroundColor: "#FFFFFF",
              color: "#4F5655",
              left: 0,
              alignSelf: "end ",
            }}
            onClick={() => {
              form.resetFields();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            key="addInvoice"
            style={{
              width: 150,
              height: 40,
              backgroundColor: "#1D2624",
              color: "#FFFFFF",
              right: 0,
              alignSelf: "start",
            }}
            loading={btnLoading}
            onClick={() => {
              form
                .validateFields()
                .then((res) => {
                  const payload = {
                    ...res,
                    invoice_date: res.invoice_date.format("YYYY-MM-DD"),
                  };
                  handleOk(payload);
                })
                .catch((err) => {
                  message.error("Please fill all mandatory fields");
                  return;
                });
            }}
          >
            {isCreate ? "Add Invoice" : "Update Invoice"}
          </Button>
        </Space>
      }
    >
      {((!isCreate && Object.keys(initialValues).length) || isCreate) && (
        <>
          <Form form={form} layout="vertical">
            <Form.Item
              name="url"
              rules={[{ required: true, message: "required" }]}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  {!imageUploaded ? <InboxOutlined /> : <CheckCircleOutlined />}
                </p>
                <p className="ant-upload-text">
                  {imageUploaded
                    ? "Invoice file uploaded"
                    : "Click or drag file to this area to upload"}
                </p>
                <p className="ant-upload-hint">
                  {!imageUploaded
                    ? isCreate
                      ? "Upload a new invoice"
                      : "Update and replace this existing invoice file"
                    : ""}
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "required" }]}
              name="name"
              label="Invoice name"
            >
              <Input defaultValue="" placeholder="Enter Name" />
            </Form.Item>

            <Form.Item name="comments" label="Comments">
              <Input placeholder="Enter comments" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "required" }]}
              name="invoice_date"
              label="Invoice date"
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "required" }]}
              name="currency_id"
              label="Currency"
            >
              <Select className="select-after" options={currencies} placeholder="Select currency">
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "required" }]}
              name="amount"
              label="Total amount"
            >
              <Input type="number" placeholder="Enter amount" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "required" }]}
              name="paid_amount"
              label="Amount paid"
            >
              <Input type="number" placeholder="Enter amount paid" />
            </Form.Item>

            <Form.Item name="others" label="Other amount (TDS)">
              <Input type="number" placeholder="Enter other amount" />
            </Form.Item>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default InvoiceDetailsModal;
