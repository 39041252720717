import { Button, Row, Typography, Select, Radio, Input } from "antd";

const { Title } = Typography;
const { Option } = Select;

const TemplateHeader = ({
  orgs,
  selectedOrg,
  setSelectedOrg,
  setIsTemplateModalVisible,
  templateType,
  setTemplateType,
}) => {
  const handleRadioChange = (e) => {
    const value = e?.target?.value;
    setTemplateType(value);
    if (value === "generic") setSelectedOrg(null);
  };

  const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };

  return (
    <>
      <Row justify="space-between">
        <Title level={4}>Excel Templates</Title>
        <Button
          onClick={() => setIsTemplateModalVisible(true)}
          disabled={templateType === "userAccount" && !selectedOrg}
          size="large"
        >
          Create New Template
        </Button>
      </Row>
      {/* Tabs */}
      <Row className="excel-templates-headers-row">
        <Radio.Group
          defaultValue="generic"
          onChange={handleRadioChange}
          buttonStyle="solid"
        >
          <Radio.Button value="generic">Generic</Radio.Button>
          <Radio.Button value="userAccount">User Account</Radio.Button>
        </Radio.Group>
      </Row>
      {/* Select organisation section */}
      {templateType === "userAccount" && (
        <Row className="excel-templates-headers-row">
          <Select
            showSearch
            style={{ width: "20%" }}
            placeholder="Select Organization"
            optionFilterProp="children"
            onSelect={handleOrgChange}
            filterOption={(input, option) => {
              return (
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0 ||
                option?.value?.toString() === input
              );
            }}
          >
            {orgs?.map((org) => (
              <Option key={org?.id} value={org?.id}>
                {org?.name}
              </Option>
            ))}
          </Select>
        </Row>
      )}
    </>
  );
};

export default TemplateHeader;
