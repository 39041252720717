import React, { useState, useEffect } from "react";
import {
  Divider,
  Form,
  Col,
  PageHeader,
  Row,
  Select,
  Typography,
  Button,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import API_KEY from "../../api/index";
const { Title, Text } = Typography;

function Edit() {
  const [loading, setLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [currentUser, setCurrentUser] = useState("");

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    API_KEY.URL.patch(`${API_KEY.path.users}/${values.user}`, {
      org: `${values.org}`,
    })
      .then((res) => {
        if (res.status === 200) {
          form.resetFields();
          setLoading(false);
          message.success("Manufacturer Mapping Updated");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.err("Error Occurred");
      });
  };

  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        limit: -1,
        fields:
          "id,name",
        filter: {
          _and: [
            {
              status: {
                _nin: ["archived"],
              },
            },
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },

            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
            {
              name: {
                _ncontains: "Test",
              },
            },
          ],
        },
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
      }
    });
  }, []);

  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.users, {
      params: {
        fields:
          "org.email,org.id,org.name,id,first_name,last_name,email,is_admin,is_system_user",
        limit: -1,
        filter: selectedOrg ? { org: { _eq: selectedOrg } } : null,
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllUsersData(res?.data?.data);
      }
    });
  }, []);

  const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };
  const handleUserChange = (value) => {
    setSelectedUser(value);
    allUsersData.map((res) => {
      if (res.id === value) {
        setCurrentUser(res?.org?.name);
      }
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={
          <Row>
            <Title level={3} style={{ fontFamily: "Gilroy" }}>
              Change Associated Account
            </Title>
            <Text type="secondary" style={{ margin: 4, fontSize: 14 }} />
          </Row>
        }
        subTitle="Change Associated Account"
      />
      <Divider />
      <Row style={{ minHeight: "75vh" }}>
        <Col span={12} offset={2}>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item name="user" label="Users">
              <Select
                placeholder="Select User"
                onChange={handleUserChange}
                value={selectedUser}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allUsersData.map((user, index) => (
                  <Select.Option value={user?.id} key={index}>
                    {user?.email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>
                Current Organization :{" "}
                <Text type="success">
                  {currentUser ? currentUser : "No such Org"}
                </Text>
              </Text>
            </Form.Item>

            <Form.Item name="org" label="Organization">
              <Select
                placeholder="Select Organization"
                onChange={handleOrgChange}
                value={selectedOrg}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allOrgsData.map((org, index) => (
                  <Select.Option value={org?.id} key={index}>
                    {org?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label=" ">
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Edit;
