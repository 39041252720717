import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Users from "../components/Users";
import Products from "../components/Products";
import Organizations from "../components/Organization";
import CreateNewUser from "../components/Users/CreateNew";
import Home from "../components/Home";
import ProductForm from "../components/Products/Form";
import BulkUpload from "../components/Products/BulkUpload";
import Categories from "../components/Categories";
import OrganizationForm from "../components/Organization/Form";
import CategoryForm from "../components/Categories/Form";
import S3BrowserComponent from "../components/S3Browser";
import S3SearchComponent from "../components/ImageSearch";
import ImageSearchBox from "../components/ImageSearch/ImageSearchBox";
import Template from "../components/Template";
import Edit from "../components/Users/Edit";
import Plans from "../components/Plans/index";
import { Invoice } from "../components/Invoice";
import Approvals from "../components/Approvals";
import { Requests } from "../components/Requests";
import Features from "../components/Features";
import BulkUpdate from "../components/Products/BulkUpdate";
import { allowedUsers } from "../components/Approvals/allowedUsers";
import AttributeSetting from "../components/AttributeSetting/index";
import axios from "axios";
import ExcelTemplates from "../components/ExcelTemplates";

export default function RouteApp() {
  const [isAllowed, setIsAllowed] = useState(false);
  const [resetManufacturerData, setResetManufacturerData] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const userData = localStorage.getItem("userData");
  const user = userData && JSON.parse(userData);
  const currentUser = JSON.parse(localStorage.getItem("userLog"));
  useEffect(() => {
    if (currentUser && allowedUsers?.includes(currentUser)) {
      setIsAllowed(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (resetManufacturerData) {
      setResetManufacturerData(false);
      axios
        .get(`${process.env.REACT_APP_INTERNAL_API}items/manufacturer`, {
          headers: { "access-token": user?.access_token },
        })
        .then((res) => {
          if (res?.data?.data) {
            setOrgs(
              (res?.data?.data || []).map((org) => {
                return org;
              })
            );
          }
        });
    }
  }, [resetManufacturerData]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/users" element={<Users orgs={orgs} />} />
      <Route path="/organizations" element={<Organizations orgs={orgs} />} />
      <Route path="/products" element={<Products orgs={orgs} />} />
      <Route path="/categories" element={<Categories orgs={orgs} />} />
      <Route path="/attributes" element={<AttributeSetting orgs={orgs} />} />
      <Route path="/create-new-user" element={<CreateNewUser orgs={orgs} />} />
      <Route path="/product-form" element={<BulkUpload orgs={orgs} />} />
      <Route
        path="/organization-form"
        element={
          <OrganizationForm
            orgs={orgs}
            setResetManufacturerData={setResetManufacturerData}
          />
        }
      />
      <Route path="/category-form" element={<CategoryForm orgs={orgs} />} />
      <Route
        path="/image-browser"
        element={<S3BrowserComponent orgs={orgs} />}
      />
      <Route path="/image-search" element={<S3SearchComponent orgs={orgs} />} />
      <Route
        path="image-search/:dir"
        element={<ImageSearchBox orgs={orgs} />}
      />
      <Route path="/update-form" element={<BulkUpdate />} />
      <Route path="/templates" element={<Template />} />
      <Route path="/plans">
        <Route path=":selectedOrg" element={<Plans />} />
        <Route path="" element={<Plans />} />
      </Route>
      <Route path="/users/change-asociated-account" element={<Edit />} />
      {isAllowed && <Route path="/approvals" element={<Approvals />} />}
      <Route path="/requests" element={<Requests />} />
      <Route path="/features" element={<Features orgs={orgs} />} />
      <Route path="/excel-templates" element={<ExcelTemplates orgs={orgs} />} />
    </Routes>
  );
}
