import React from "react";
import globalHook from "use-global-hook";

const initialState = {
  counter: 0,
  isLoggedIn: localStorage.getItem("userData"),
  userData: JSON.parse(localStorage.getItem("userData")) || {},
  isLoading: false,
  selectedMenuItem: "1",
};

const actions = {

  signIn: async (store, payload) => {

    // store.setState({ isLoading: true });
    // const userData = await API_KEY.URL.post(API_KEY.path.login, payload);

    if (payload?.data) {
      store.setState({ isLoggedIn: true, userData: payload.data.data });
      // localStorage.setItem("userData", JSON.stringify(userData.data.data));

    }
  },
  signOut: async (store) => {
    store.setState({ isLoggedIn: false, userData: {} });
    localStorage.clear();
  },
  setSelectedMenuItem: (store, payload) => {
    store.setState({ selectedMenuItem: payload });
  }
};

export const useGlobal = globalHook(initialState, actions);

