import React, { useEffect, useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import API_KEY from "../../api/index";
const { Title, Text } = Typography;
const CardComponents = ({ title, count }) => {
  return (
    <Card
      bordered
      hoverable
      style={{ borderRadius: 8, backgroundColor: "#f1f1f1" }}
    >
      <Row>
        <Col span={24}>
          <Title type="secondary" level={4}>
            {title}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>{count}</Title>
        </Col>
      </Row>
    </Card>
  );
};
export default function Index() {
  const [productCount, setProductCount] = useState(0);
  const [collectionCount, setCollectionCount] = useState(0);
  const [orgCount, setOrgCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const fetchProductCount = () => {
    API_KEY.URL.get(API_KEY.path.products, {
      params: {
        meta: "*",
        limit: 0,
      },
    })
      .then((res) => {
        setProductCount(res?.data?.meta?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCollectionCount = () => {
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        meta: "*",
        limit: 0,
      },
    })
      .then((res) => {
        setCollectionCount(res?.data?.meta?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOrgCount = () => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        meta: "*",
        limit: 0,
      },
    })
      .then((res) => {
        setOrgCount(res?.data?.meta?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserCount = () => {
    API_KEY.URL.get(API_KEY.path.users, {
      params: {
        meta: "*",
        limit: 0,
      },
    })
      .then((res) => {
        setUserCount(res?.data?.meta?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const timer = setInterval(fetchProductCount, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setInterval(fetchCollectionCount, 10000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(fetchOrgCount, 10000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(fetchUserCount, 10000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="site-card-wrapper">
      <Row gutter={32}>
        <Col span={6}>
          <CardComponents title={"Products"} count={productCount} />
        </Col>
        <Col span={6}>
          <CardComponents title={"Catalogue"} count={collectionCount} />
        </Col>
        <Col span={6}>
          <CardComponents title={"Organisation"} count={orgCount} />
        </Col>
        <Col span={6}>
          <CardComponents title={"Users"} count={userCount} />
        </Col>
      </Row>
    </div>
  );
}
