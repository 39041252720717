import {
  Button,
  Col,
  Drawer,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import Title from "antd/lib/skeleton/Title";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import API_KEY from "../../api";
import { numberWithCommas } from "../Plans/numberWithCommas";
import StatusPill from "../Plans/StatusPill";
import ShowRequestDetails from "./ShowRequestDetails";
export default function Approvals(manufacturers) {
  const [approvals, setApprovals] = useState([]);
  const [allOrgsData, setAllOrgsData] = useState({});
  const [status, setStatus] = useState("all");
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [RejectLoading, setRejectLoading] = useState(false);
  const [ApproveLoading, setApproveLoading] = useState(false);
  const userData = localStorage.getItem("userData");
  const user = userData && JSON.parse(userData);
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Text style={{ fontWeight: 700 }}>{record.id}</Text>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      align: "center",
      width: "200px",
      render: (text, record) => (
        <Tooltip title={record?.customer_idthis}>
          <Text style={{ fontWeight: 700 }}>
            {allOrgsData[parseInt(record?.customer_id)]?.name}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Request type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (text, record) => (
        <Text style={{ textTransform: "capitalize" }}>
          {record?.type?.replace("_", " ")}
        </Text>
      ),
    },
    {
      title: "User",
      dataIndex: "requested_by",
      key: "requested_by",
      align: "center",
      render: (text, record) => (
        <Text>{numberWithCommas(record?.requested_by)}</Text>
      ),
    },
    {
      title: "Request date",
      dataIndex: "created_by",
      key: "created_by",
      align: "center",
      render: (text, record) => (
        <Space>
          <Text style={{ textTransform: "capitalize" }}>
            {record?.created_by}
          </Text>
          <Text type="secondary">
            {moment
              .utc(record?.created_at)
              .local()
              .format("DD MMM YYYY, h:mm a")}
          </Text>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <StatusPill status={record?.status}>{record?.status}</StatusPill>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "view_button",
      align: "center",
      render: (text, record) => (
        <Button
          onClick={() => {
            // console.log(record, "DEEPAM");
            setSelectedRow(record);
            setVisibleDrawer(true);
          }}
          style={{
            width: 104,
            height: 40,
            backgroundColor: "#FFFFFF",
            color: "#4F5655",
          }}
        >
          View details
        </Button>
      ),
    },
  ];
  const status_options = [
    {
      label: `All`,
      value: "all",
      key: "all",
    },
    {
      label: `Pending`,
      value: "pending",
      key: "pending",
    },
    {
      label: `Rejected`,
      value: "rejected",
      key: "rejected",
    },
    {
      label: `Approved`,
      value: "approved",
      key: "approved",
    },
  ];
  const fetchApprovals = (status) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_INTERNAL_API}approval/${status}`, {
        headers: { "access-token": `${user?.access_token}` },
        params: { access_token: `${user?.access_token}` },
      })
      .then((res) => {
        if (res && res?.status === 200) {
          setApprovals(res?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        meta: "*",
        limit: -1,
        filter: {
          _and: [
            {
              status: {
                _nin: ["archived"],
              },
            },
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },
            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
          ],
        },
      },
    })
      .then((res) => {
        if (res?.data?.data) {
          const temp = res?.data?.data;
          let final = {};
          temp?.map((item) => (final[item?.id] = item));
          setAllOrgsData(final);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    fetchApprovals(status);
  }, []);

  const updateStatus = (status) => {
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);
    const message_ = status === "approve" ? "Approved" : "Rejected";
    axios
      .post(
        `${process.env.REACT_APP_INTERNAL_API}approval/${status}/${selectedRow?.id}`,
        {},
        {
          headers: { "access-token": `${user?.access_token}` },
          params: { access_token: `${user?.access_token}` },
        }
      )
      .then((res) => {
        if (res && res?.status === 200) {
          setRejectLoading(false);
          setApproveLoading(false);
          fetchApprovals("all");
          setVisibleDrawer(false);
          message.success(`Request ${message_}`);
        } else {
          message.error("Something went wrong");
          setRejectLoading(false);
          setApproveLoading(false);
          setVisibleDrawer(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setRejectLoading(false);
        setApproveLoading(false);
        setVisibleDrawer(false);
      });
  };

  return (
    <>
      <ShowRequestDetails
        {...{
          visibleDrawer,
          setVisibleDrawer,
          updateStatus,
          selectedRow,
          allOrgsData,
          RejectLoading,
          ApproveLoading,
          setApproveLoading,
          setRejectLoading,
        }}
      />
      <Row justify="space-between">
        <Col span={8}>
          <Title>Approvals</Title>
          <Text type="secondary">
            {approvals ? approvals?.length : 0} approvals showing
          </Text>
        </Col>
        <Col>
          <Text style={{ marginRight: 10 }}>Status</Text>
          <Select
            options={status_options}
            value={status}
            onChange={(value) => {
              setStatus(value);
              fetchApprovals(value);
            }}
            style={{ width: 100 }}
          />
        </Col>
      </Row>
      <Table columns={columns} dataSource={approvals} loading={loading} />
    </>
  );
}
