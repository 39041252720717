import AttributeSettingActions from '../actions/attributeSettingActions';
const { AttributeActions } = AttributeSettingActions;

const initialState = {
  modalState: false,
  modalType: '',
  attributeSectionData: [],
  deleteAttribute: {},
  getOrgs:null,
  fetchData: false,
  allAttributesData: [],
  selectedOption: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AttributeActions.TOGGLE_MODAL: {
      const { modalState, modalType } = action.payload;
      return {
        ...state,
        modalState: modalState,
        modalType: modalType,
      };
    }
    case AttributeActions.GET_ATTRIBUTE_SECTION_DATA: {
      return {
        ...state,
        attributeSectionData: action.payload,
      };
    }
    case AttributeActions.GET_ALL_ATTRIBUTE_DATA: {
      return {
        ...state,
        allAttributesData: action.payload,
      };
    }
    case AttributeActions.DELETE_ATTRIBUTE: {
      const { id, name } = action.payload;
      let [filteredData] = state.attributeSectionData?.filter((ele) => ele.name === name)
       let data = filteredData?.attributes?.filter((item) => item.id === id);
      return {
        ...state,
        deleteAttribute: data[0],
      };
    }
    case AttributeActions.GET_ORGS: {
      return {
        ...state,
        getOrgs: action.payload,
      };
    }
    case AttributeActions.TOGGLE_RADIOBUTTON: {
      return {
        ...state,
        selectedOption: action.payload,
      };
    }
    case AttributeActions.FETCH_DATA: {
      return {
        ...state,
        fetchData: action.payload,
      };
    }
    default:
      return state;
  }
}
