import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import s3ls from "s3-ls";
import AWS from "aws-sdk";
import s3tree from "s3-tree";
import traverse from "traverse";
import fs from "fs";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Typography,
  Empty,
  Input,
  Pagination,
  Image,
  Button,
} from "antd";
import { saveToLocalFile } from "../../utils/common";
import PouchDB from "pouchdb-browser";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { set } from "lodash";
// const parser = require("@rafaelcsva/parse_s3_to_tree_view");
const BUCKET_NAME = "sourcewizpublic";
const BUCKET_REGION = "sgp1";
const ACCESS_KEY_ID = "OHYWUSFSEPPUNKI6NPKV";
const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";
const SECRET_ACCESS_KEY = "mM5l7rMb3HsLRx7ZtlasF+xg9TUF+8AYQPhzzw7po74";
const ImageSearchBox = () => {
  const params = useParams();
  const limiter = params?.dir;
  const navigateTo = useNavigate();
  const { Title, Text } = Typography;
  const spacesEndpoint = new AWS.Endpoint(ENDPOINT);
  AWS.config.update({
    endpoint: spacesEndpoint,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3();
  const generator = s3tree({ bucket: BUCKET_NAME, s3: s3 });

  const db = new PouchDB("directories");

  const [paths, setPaths] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pageElements, setPageElements] = useState([]);
  const initPaths = () => {
    getPaths().then((res) => {
      setPaths(res);
      setIsLoading(false);

      // setFilteredResults(res);
      db.put({
        _id: limiter,
        paths: res,
      })
        .then(function (response) {
          // handle response
        })
        .catch(function (err) {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    setIsLoading(true);
    db.get(limiter)
      .then(function (doc) {
        if (doc.paths) {
          setPaths(doc.paths);
          setIsLoading(false);
          // setFilteredResults(doc.paths);
        } else {
          initPaths();
        }
      })
      .catch(function (err) {
        initPaths();
        // setIsLoading(false);
        console.log(err);
      });
  }, [params?.dir]);

  const getPaths = async () => {
    const tree = await generator.generate(limiter, -1);
    console.log(JSON.stringify(tree, null, 2));
    var leaves = traverse(tree).reduce(function (acc, x) {
      if (this.isLeaf && typeof x !== "object" && !x.includes("Thumbs.db"))
        acc.push(x);
      return acc;
    }, []);

    leaves.sort(function (a, b) {
      var aa = a.split("/"),
        bb = b.split("/");
      return aa[0] - bb[0] || aa[1] - bb[1] || aa[2] - bb[2];
    });

    let reversedleaves = leaves.reverse();
    // fs.writeFileSync("test.json", JSON.stringify(reversedleaves, null, 2));
    // let result = [];
    // let level = { result };

    // reversedleaves.forEach((path) => {
    //   let paths = path.split("/");
    //   paths.pop();
    //   paths.reduce((r, name, i, a) => {
    //     if (!r[name]) {
    //       r[name] = { result: [] };
    //       r.result.push({ name, children: r[name].result });
    //     }

    //     return r[name];
    //   }, level);
    // });

    return reversedleaves;
  };
  const syncSubFolder = () => {
    setIsLoading(true);
    getPaths().then((res) => {
      db.get(limiter)
        .then(function (doc) {
          return db.put({
            _id: limiter,
            _rev: doc._rev,
            paths: res,
          });
        })
        .then(function (response) {
          setIsLoading(false);
          setPaths(res);
        })
        .catch(function (err) {
          setIsLoading(false);
          console.log(err);
        });
    });
  };

  const searchImages = (search) => {
    if (search) {
      setIsLoading(true);
      const matchingImages = paths.filter((item) => {
        const imageDelimiter = item.lastIndexOf("/");
        const imageName = item.substring(imageDelimiter, item.length - 1);
        return imageName.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredResults(matchingImages);
      setIsLoading(false);
    } else {
      setFilteredResults([]);
    }
  };

  const { Search } = Input;

  return paths.length ? (
    !loading ? (
      <>
        <Row justify="space-between">
          <Col>
            <Search onSearch={searchImages} />
            <Text type="secondary">
              Search {paths.length} images in {params?.dir}
            </Text>
          </Col>
          <Col>
            <Button
              type="primary"
              size="large"
              loading={loading}
              onClick={syncSubFolder}
            >
              Sync {limiter}
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {filteredResults && (
            <>
              <Row>
                {filteredResults.map((image) => (
                  <Col span={6} style={{ padding: "5px" }}>
                    <Card>
                      <Image
                        width={220}
                        height={220}
                        placeholder={true}
                        src={`https://sgp1.digitaloceanspaces.com/sourcewizpublic/${image}`}
                      />
                      <Text
                        // style={{ whiteSpace: "pre", padding: "0 5px" }}
                        type="secondary"
                      >
                        Path: {image.substring(0, image.lastIndexOf("/") + 1)}
                      </Text>
                      <Text style={{ whiteSpace: "pre" }} type="primary">
                        {image.substring(
                          image.lastIndexOf("/") + 1,
                          image.length
                        )}
                      </Text>
                    </Card>
                  </Col>
                ))}
              </Row>
              {/* <Row>
                <Pagination defaultCurrent={1} total={filteredResults.length} />
              </Row> */}
            </>
          )}
        </Row>
      </>
    ) : (
      <>
        <Row>
          <Col>
            <Text type="secondary">
              Note: Time taken to fetch results is dictated by nesting of
              folders, not the total number of images
            </Text>
          </Col>
        </Row>
        <Row>
          <Skeleton active />
        </Row>
      </>
    )
  ) : !loading ? (
    <Empty />
  ) : (
    <>
      <Row>
        <Col>
          <Text type="secondary">
            Note: Time taken to fetch results is dictated by nesting of folders,
            not the total number of images
          </Text>
        </Col>
      </Row>
      <Row>
        <Skeleton active />
      </Row>
    </>
  );
};

export default ImageSearchBox;
