import React, { useEffect, useState } from 'react';
import { Drawer, Button, Col, Row, Form ,message} from 'antd';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdCheckCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { createNewAttribute,editAttribute} from '../../../api/attributeSettingsApis';
import { fetchData, toggleRadioButtonValue } from '../../../redux/actions/attributeSettingActions';
import { openNotification } from '../AttributeSettingComponents/Notification';
import BasicForm from '../CreateAttribute/BasicForm';
import ValuesForm from '../CreateAttribute/ValuesForm';
import {
  getAttributeFormValues,
  getAttributeSettingPayload,
  getSelectedDType,
  getDefaultsValue,
} from '../utils';
import '../index.less';

function AttributeDrawer({ isDrawerOpen, setIsDrawerOpen, data, setData }) {
  const selectedOrg = useSelector(state => state.attributeSetting.getOrgs)
  const [isBasicForm, setIsBasicForm] = useState(true);
  const [selectedDtype, setSelectedDtype] = useState('');
  const [allFormValues, setAllFormValues] = useState({});
  const [attributeState, setAttributeState] = useState({
    selectedOption: '',
    enteredValue: '',
    precision: 2,
    dropdownValues: [],
    boolTrueVal: '',
    boolFalseVal: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const changeState = useSelector((state) => state.attributeSetting.fetchData);

  useEffect(() => {
    if (Object.keys(data)?.length !== 0) {
      const values = getAttributeFormValues(data);
      const selectedType = getSelectedDType(data);

      //Form Field Setting
      form.setFieldsValue({
        name: data?.name || '',
        section: data?.tabname || '',
        is_internal: data?.is_internal || false,
        is_filter: data?.is_filter || false,
        is_mandatory: data?.compulsory || false,
        ...values,
      });
      setSelectedDtype(selectedType);
    }
    let dropDownValues = getDefaultsValue(data);
    dispatch(toggleRadioButtonValue(dropDownValues?.selectedOption));
  }, [data]);

  const clearState = () => {
    form.resetFields();
    setIsBasicForm(true);
    setSelectedDtype('');
    setAllFormValues({});
    setAttributeState({
      selectedOption: '',
      enteredValue: '',
      precision: 2,
      dropdownValues: [],
      boolTrueVal: '',
      boolFalseVal: '',
    });
    setIsDrawerOpen(false);
    setData({});
  };

  const save = () => {
    const getValues = form.getFieldsValue();
    const getAllValues = { ...allFormValues, ...getValues, ...attributeState };
    const payload = getAttributeSettingPayload(getAllValues);
   
    if (selectedDtype === 'boolean' && errorMessage) {
      return;
    }

    setBtnLoading(true);

    if (data?.id) {
      editAttribute(payload,data?.id,selectedOrg)
        .then((res) => {
          if (res?.status === 200) {
            setBtnLoading(false);
            openNotification(
              'top',
              'Attribute Updated Successfully',
              <MdCheckCircle style={{ color: 'rgb(0,119,100)' }} />
            );
            clearState();
            dispatch(fetchData(!changeState));
          }
        })
        .catch((err) => {
          setBtnLoading(false);
          console.error(err);
        });
    } else {
      createNewAttribute(payload,selectedOrg)
        .then((res) => {
          if (res?.status === 200) {
            setBtnLoading(false);
            openNotification(
              'top',
              'New Attribute Created Successfully',
              <MdCheckCircle style={{ color: 'rgb(0,119,100)' }} />
            );
            clearState();
            dispatch(fetchData(!changeState));
          }
        })
        .catch((err) => {
          setBtnLoading(false);
          console.error(err);
          openNotification(
            'top',
            'Something went wrong',
            <IoMdCloseCircle style={{ color: 'red' }} />
          );
        });
    }
  };

  const handleNext = async () => {
    const value = await form.validateFields();

    if (value?.errorFields?.length) {
      return;
    }

    if (
      !isBasicForm &&
      selectedDtype === 'uom' &&
      attributeState?.selectedOption === ''
    ) {
      return message.error('Please select a value');
    }

    if (form.getFieldValue('name')?.trim() === '') {
      return message.error('Please enter attribute name');
    }
    
    setAllFormValues((state) => {
      return { ...state, ...form.getFieldsValue(), dtype: selectedDtype };
    });

    if (selectedDtype === '') {
      return openNotification(
        'top',
        'Please Select Dtype',
        <IoMdCloseCircle style={{ color: 'red' }} />
      );
    }
    if (isBasicForm) {
      setIsBasicForm(false);
    } else {
      save();
    }
  };

  const handleCancelButton = () => {
    if (isBasicForm) {
      clearState();
    } else {
      setIsBasicForm(true);
    }
  };

  const footer = (
    <Row align="bottom">
      <Col>
        <Button onClick={() => handleCancelButton()} size="medium">
          {isBasicForm ? 'Cancel' : 'Previous'}
        </Button>
      </Col>
      <Col style={{ marginLeft: 'auto' }}>
        <Button
          className="attribute-setting-footer-button-save"
          onClick={() => handleNext()}
          loading={btnLoading}
          disabled={
            isBasicForm &&
            (selectedDtype === '' ||
              form.getFieldsError().some((errors) => errors.length))
          }
          variant={'dark'} 
          >
          {isBasicForm ? 'Next' : 'Save'}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer
      placement="right"
      visible={isDrawerOpen}
      closable={false}
      width={530}
      footer={footer}>
      {isBasicForm && (
        <BasicForm
          form={form}
          selectedDtype={selectedDtype}
          setSelectedDtype={setSelectedDtype}
          data={data}
        />
      )}
      {!isBasicForm && (
        <ValuesForm
          data={data}
          form={form}
          selectedDtype={selectedDtype}
          attributeState={attributeState}
          setAttributeState={setAttributeState}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </Drawer>
  );
}

export default AttributeDrawer;
