import { applyMiddleware, createStore } from 'redux';
// import { createLogger } from "redux-logger";
import { composeWithDevTools } from 'remote-redux-devtools';

import reducer from './reducer';

export default function configureStore(initialState, middlewareOptions) {
  // const loggerMiddleware = createLogger({
  // 	predicate: () =>  process.env.NODE_ENV === "development",
  // });
  // const middlewares = [loggerMiddleware];
  const middlewares = [];


  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  return { store };
}
