import { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Radio, Row, Select, Space, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFile } from "../../../api/fileUploaderApi";
import ExcelComponent from "./ExcelComponent";
import DeleteTemplate from "./DeleteTemplate";
import { getImageUrl } from "../../../utils/excelTemplateHelpers";

const { Option } = Select;

const TemplateForm = ({
  template = {},
  mode = "create",
  uomData = {},
  form,
  handleFinish,
  setPreviewImg,
  setExcelFile,
  excelFile,
  excelFileType,
  setExcelFileType,
  fileList,
  setFileList,
  setIsChecked,
}) => {
  const setTemplateInitialValues = useCallback(() => {
    if (Object.keys(template)?.length > 0) {
      const { previewImageUrl, defaultValues, templateUrl, ...otherFields } =
        template;
      const mappedDefaultValues = Object.fromEntries(
        Object.entries(defaultValues)?.map(([k, v]) => [k, v || "none"])
      );
      const mappedFile = {
        id: previewImageUrl,
        url: getImageUrl(260, 230, 40, previewImageUrl),
      };
      setFileList([mappedFile]);
      setPreviewImg(mappedFile);
      setExcelFile({ url: templateUrl, name: "Excel file" });
      form.setFieldsValue({ ...otherFields, ...mappedDefaultValues });
    } else {
      const uomInitialValues = Object.keys(uomData)?.reduce((acc, key) => {
        const uomCategory = key?.toLowerCase();
        acc[uomCategory] = "none";
        return acc;
      }, {});
      form.setFieldsValue({ ...uomInitialValues, status: "published" });
    }
  }, [template]);

  useEffect(() => {
    setTemplateInitialValues();
  }, [setTemplateInitialValues]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [template]);

  const handleUpload = async (options) => {
    const { onSuccess, onError, file } = options;

    try {
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      fmData.append("image", file);
      const res = await uploadFile(fmData, config);
      if (res?.status === 200) {
        onSuccess("Ok");
        const data = res?.data?.data;
        const newFile = {
          uid: "1",
          id: data?.id,
          url: `${process.env.REACT_APP_KEY}assets/${data?.id}`,
        };
        setPreviewImg(newFile);
      }
    } catch (err) {
      console.error(err);
      onError(err);
    }
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      id="template-form"
    >
      {mode === "delete" ? (
        <DeleteTemplate {...{ setIsChecked }} />
      ) : (
        <>
          <Form.Item
            name="name"
            label="Template Name"
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>
          <Form.Item
            name="preview_img"
            label="Preview Image"
            rules={[
              {
                required: !fileList?.length > 0 && true,
                message: "Please upload preview image!",
              },
            ]}
          >
            <Upload
              fileList={fileList}
              onChange={handleFileChange}
              customRequest={handleUpload}
              listType="picture"
              accept="image/png, image/jpeg"
              onRemove={() => setFileList([])}
            >
              {fileList?.length === 0 && (
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              )}
            </Upload>
          </Form.Item>
          <ExcelComponent
            {...{
              excelFileType,
              setExcelFileType,
              mode,
              setExcelFile,
              excelFile,
            }}
          />
          {Object.keys(uomData)?.map((uom, index) => (
            <Form.Item
              key={`${uom}--${index}`}
              name={uom?.toLowerCase()}
              label={uom}
            >
              <Select>
                <Option value="none">Don't transform</Option>
                {uomData?.[uom]?.map((item) => (
                  <Option key={item?.id} value={item?.short_name}>
                    {item?.short_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ))}
          {mode === "edit" && (
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please select status!",
                },
              ]}
            >
              <Select placeholder="Please select a status">
                <Option value="published">Published</Option>
                {/* <Option value="draft">Draft</Option> */}
              </Select>
            </Form.Item>
          )}
        </>
      )}
    </Form>
  );
};

export default TemplateForm;
