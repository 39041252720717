import React, { useState } from "react";
import { Upload, Button, message, Tag } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

export default function Validator({
  manufacturerID,
  user,
  setResponse,
  setValidation,
  setUploadSheet,
}) {
  const handleChangeUpload = (info) => {
    // setFileList(info.fileList);
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      //getBase64(info.file.originFileObj);

      setUploadSheet(info.file.originFileObj);
    }
  };
  const uploadImage = async ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const props = {
    beforeUpload: (file) => {
      const isExcel = [
        "xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].includes(file.type);
      if (!isExcel) {
        message.error(`${file.name} ${file.type} is not a excel file`);
      }
      return isExcel || Upload.LIST_IGNORE;
    },
    customRequest: uploadImage,
    onChange: handleChangeUpload,
    multiple: false,
  };

  return (
    <>
      <Upload style={{ width: "100%" }} className="upload-btn" {...props}>
        <Button
          disabled={manufacturerID ? false : true}
          className="upload-btn"
          style={{ width: "100%" }}
          icon={<UploadOutlined />}
        >
          Upload Excel Sheet
        </Button>
      </Upload>
    </>
  );
}
