import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Skeleton, Space, Table, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";

import "./style.less";
import Title from "antd/lib/typography/Title";

export default function ShowRequestDetails({
  visibleDrawer,
  setVisibleDrawer,
  updateStatus,
  selectedRow,
  allOrgsData,
  RejectLoading,
  ApproveLoading,
  setApproveLoading,
  setRejectLoading
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);

  // fetch request details
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);

    if (selectedRow) {
      setType(selectedRow?.type);
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_INTERNAL_API}approval/diff/${selectedRow?.id}`,
          {
            headers: { "access-token": `${user?.access_token}` },
            params: { access_token: `${user?.access_token}` },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setData(res?.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [selectedRow]);

  return (
    <Drawer
      visible={visibleDrawer}
      // is selectedrow status is pending then show the footer else hide the footer
      footer={
        selectedRow?.status === "pending" ? (<Footer {...{ updateStatus, setVisibleDrawer , RejectLoading, ApproveLoading, setApproveLoading,
          setRejectLoading}} />
        ) : null
      }
      title={<DrawerTitle {...{ allOrgsData, selectedRow, setVisibleDrawer }} />}
      width={"35%"}
      zIndex={2}
      onClose={() => {
        if (visibleDrawer) {
          setVisibleDrawer(false);
        }
      }}
      closable={false}
    >
      <Row>
        <Col span={24}>
          <Title level={5}>
            { type==="update_plan"?"Update to":"Upgrade to"}
          </Title>
        { loading ? <Skeleton active/> :  <UpgradeTo data={(type === "add_plan" || type === "update_plan") ? data?.request?.plan : data?.request} type={type}  />}
        </Col>
        <Divider/>
        <Col span={24}>
          <Title level={5}>
            Current
          </Title>
        { loading ? <Skeleton active/> :  <Current data={data?.current_plan} />}
        </Col>
      </Row>
    </Drawer>
  );
}

// title of the drawer
const DrawerTitle = ({ allOrgsData, selectedRow, setVisibleDrawer }) => (
  <Row style={{ width: "100%", justifyContent: "space-between" }}>
    <Col style={{ justifyContent: "space-between" }}>
      <Row>
        <Text style={{ fontSize: 18, fontWeight: 700 }}>
          {allOrgsData[parseInt(selectedRow?.customer_id)]?.name}
        </Text>
      </Row>
      <Row>
        <Text style={{ fontSize: 14 }} type="secondary">
          Approval for {selectedRow?.type?.replace("_", " ")}
        </Text>
      </Row>
    </Col>
    <Col
      onClick={() => {
        setVisibleDrawer(false);
      }}
      style={{ cursor: "pointer" }}
    >
      <CloseOutlined />
    </Col>
  </Row>
);

// footer of the drawer
const Footer = ({ updateStatus,  RejectLoading, ApproveLoading,setApproveLoading,
  setRejectLoading }) => (
  <Space
    direction="horizontal"
    style={{ justifyContent: "space-between", width: "100%" }}
  >
    <Button
      key="cancel"
      style={{
        width: 104,
        height: 40,
        backgroundColor: "#FFFFFF",
        color: "#4F5655",
        left: 0,
        alignSelf: "end ",
      }}
      onClick={() => {
        // setVisibleDrawer(false);
        setRejectLoading(true);
        updateStatus("reject");
      }}
      loading={RejectLoading}
    >
      Reject
    </Button>
    <Button
      key="resolve"
      style={{
        width: 104,
        height: 40,
        backgroundColor: "#1D2624",
        color: "#FFFFFF",
        right: 0,
        alignSelf: "start",
      }}
      loading={ApproveLoading}
      onClick={() => {
        // setVisibleDrawer(false);
        // resolveApproval();
        setApproveLoading(true);
        updateStatus("approve");

      }}
    >
      Resolve
    </Button>
  </Space>
);

// upgrade to section
const UpgradeTo = ({ data, type }) => {
  return (
    <Row className="upgrade-to">
      <Col span={24} className="card">
        {/* title row starts here */}
        <Row justify="space-between">
          <Col className="title">{data?.label} {data?.has_limit && `(${data?.limit})`}</Col>
          {data?.amount && (<Col>
            <Row className="title" >₹ {data?.amount} / year</Row>
            <Row>Exclusive of GST</Row>
          </Col>)}
        </Row>
        {/* title row ends here */}
        {/* discount row starts here */}
       { data?.discount && <Row justify="space-between">
          <Col>Discount ({data?.discount})</Col>
          <Col>
            <Row>-₹ {data?.amount_deduct}</Row>
          </Col>
        </Row>}
        {/* discount row ends here */}
        <Divider />
        {/* start date and end date */}
        <Row justify="space-between">
            <Col>Start Date</Col>
            <Col>
                <Row>{data?.start_date && moment(data?.start_date).format("DD MMM YYYY")}</Row>
                </Col>
        </Row>
        <Row justify="space-between">
            <Col>End Date</Col>
            <Col>
                <Row>{data?.end_date &&  moment(data?.end_date).format("DD MMM YYYY")}</Row>
                </Col>
        </Row>
        {type==="update_plan_line_item" &&
          (
            <Row>
              <Col span={24}>
              <Divider />
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    Plan Type
                  </Col>
                  <Col>
                    <Text style={{textTransform:"capitalize"}} >
                      {data?.plan_type}
                    </Text>
                  </Col>
                </Row>  
                <Row justify="space-between">
                  <Col>
                    Plan Status
                  </Col>
                  <Col>
                    <Text style={{textTransform:"capitalize"}} >
                      {data?.plan_status}
                    </Text>
                  </Col>
                </Row>  
              </Col>
            </Row>
          )
        }
        {/* start date and end date */}
        {
          data?.plan_id && (<Row justify="space-between">
              <Col>Plan Id</Col>
              <Col>
                  <Row>{data?.plan_id}</Row>
                  </Col>
          </Row>)
        }
        {/* plan id */}
      </Col>
    </Row>
  );
};


// current to section
const Current = ({ data }) => {
  
  return (
    <Row className="current">
      <Col span={24} className="card">
        {/* title row starts here */}
        <Row justify="space-between">
          <Col className="title">{data?.core_plan?.label}</Col>
          <Col>
            <Row className="title" >₹ {data?.plan?.amount} / year</Row>
            <Row>Exclusive of GST</Row>
          </Col>
        </Row>
        {/* title row ends here */}
        <Divider/>
        {/* start date and end date */}
        <Row justify="space-between">
            <Col>Start Date</Col>
            <Col>
                <Row>{data?.plan?.start_date && moment(data?.plan?.start_date).format("DD MMM YYYY")}</Row>
                </Col>
        </Row>
        <Row justify="space-between">
            <Col>End Date</Col>
            <Col>
                <Row>{data?.plan?.end_date &&  moment(data?.plan?.end_date).format("DD MMM YYYY")}</Row>
              </Col>
        </Row>
        {/* start date and end date */}
       
      </Col>
    </Row>
  );
};