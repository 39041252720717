import Text from "antd/lib/typography/Text";

export default function StatusPill ({status}) {
    const statusStyle = {
        
        color: status==="approved"?"#0B7764":(status==="rejected"?"#C23E3E":"#B37D36"),
        background: status==="approved"?"#F0F8F6":(status==="rejected"?"#FDDBDB":"#FFF0DB"),
    }
    return (
            <Text style={
                {
                    width:"110px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 32,
                    fontSize:12,
                    padding: "4px 24px",
                    textTransform:"capitalize",
                    ...statusStyle
                }} >
                {status}
            </Text>
    )
}