import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { defaultOptions } from '../utils'
const { Option } = Select;

function DefaultsValue({
  selectedDtype,
  dropdownValues,
  uomData,
  selectedOption,
}) {


  const preventDecimal = (e) => {
    if (e.key === '.' || e.charCode === 46) {
      e.preventDefault();
    }
  };

  switch (selectedDtype) {
    case 'uom':
      return (
        <Form.Item name={`${selectedDtype}:value`} noStyle>
          <Input
            type={'number'}
            style={{ width: '400px', margin: '1.5rem' }}
            size="large"
            min={0}
            placeholder="Enter Number"
            addonAfter={
              <Form.Item name={`${selectedDtype}:unit`} noStyle>
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{ width: 150, textAlign: 'left' }}>
                  {uomData[selectedOption]?.map((item, id) => (
                    <Option key={id} value={item?.short_name}>
                      {item?.short_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>
      );
    case 'price':
      return (
        <Form.Item name={`${selectedDtype}:value`}>
          <Input
            size="large"
            type="number"
            placeholder={`Enter Price`}
            onWheel={(e) => e.currentTarget.blur()}
            addonBefore={
              <Form.Item name={`${selectedDtype}:currency`} noStyle>
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{
                    width: 150,
                  }}
                  size="large"
                  placeholder={`Select Currency`}>
                  {defaultOptions?.price?.currency?.map(
                    (item, index) =>
                      item && (
                        <Option key={`${index}${item}`} value={item}>
                          {item}
                        </Option>
                      )
                  )}
                </Select>
              </Form.Item>
            }
            addonAfter={
              <Form.Item name={`${selectedDtype}:unit`} noStyle>
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{
                    width: 150,
                  }}
                  size="large"
                  placeholder={`Select Unit`}>
                  {defaultOptions?.price?.unit?.map(
                    (item, index) =>
                      item && (
                        <Option key={`${index}${item}`} value={item}>
                          {item}
                        </Option>
                      )
                  )}
                </Select>
              </Form.Item>
            }
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      );
    case 'size':
      return (
        <Row style={{ width: 500, margin: '1rem' }}>
          <Col span={4}>
            <Form.Item name={`${selectedDtype}:length`}>
              <Input placeholder="L" type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={`${selectedDtype}:width`}>
              <Input placeholder="B" type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={`${selectedDtype}:height`}>
              <Input placeholder="H" type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={`${selectedDtype}:unit`}>
              <Select placeholder={'unit'}>
                {defaultOptions?.size.unit?.map(
                  (item, index) =>
                    item && (
                      <Option key={`${index}${item}`} value={item}>
                        {item}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      );
    case 'text':
      return (
        <Form.Item name={`${selectedDtype}:value`} style={{ margin: '1rem' }}>
          <Input placeholder="enter text" style={{ width: 350 }} />
        </Form.Item>
      );
    case 'multi_select':
    case 'select':
      return (
        <Form.Item name={`select:value`} style={{ width: 350 }}>
          <Select
            mode={selectedDtype === 'multi_select' && 'multiple'}
            placeholder={'Select'}>
            {dropdownValues?.map((value) => (
              <Option value={value}>{value}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    case 'boolean':
      return (
        <Form.Item name={`${selectedDtype}:value`} style={{ width: 350,marginLeft: '2em'}}>
          <Select placeholder={'Select'}>
            <Option value="false">False</Option>
            <Option value="true">True</Option>
          </Select>
        </Form.Item>
      );
    case 'integer':
      return (
        <Form.Item name={`${selectedDtype}:value`} style={{ margin: '1rem' }}>
          <Input
            style={{ width: 350 }}
            onKeyDown={preventDecimal}
            type={'number'}
            placeholder="Enter Value"
          />
        </Form.Item>
      );
    default:
      return <div></div>;
  }
}

export default DefaultsValue;
