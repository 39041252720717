import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Typography } from 'antd';
import { getMeasurements } from '../../../api/attributeSettingsApis';
import { getDefaultsValue } from '../utils';
import '../index.less';
import DefaultsValue from './DefaultsValue';
import FormBanner from './FormBanner';

function ValuesForm({
  form,
  selectedDtype,
  data,
  attributeState,
  setAttributeState,
  errorMessage,
  setErrorMessage,
}) {
  const [showDefaultValue, setShowDefaultValue] = useState(false);
  const [uomData, setUOMData] = useState({});
  const { Title, Text } = Typography;

  useEffect(() => {
    handleMeasurement()
    handleDefaultValues()
  }, []);

  const handleMeasurement = async()=>{
    getMeasurements()
    .then((res) => {
      if (res?.status === 200) {
        setUOMData(res?.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });
  }

  const handleDefaultValues = ()=>{
    if (Object.keys(data)?.length !== 0) {
      setAttributeState((state) => {
        const defaultValues = getDefaultsValue(data);
        return {
          ...state,
          ...defaultValues,
        };
      });
      setShowDefaultValue(data?.default_values?.values?.trim() ? true : false);
    }
  }

  useEffect(() => {
    if (Object.keys(data)?.length === 0 && selectedDtype === 'boolean') {
      form.setFieldsValue({
        is_default: true,
        [`${selectedDtype}:value`]: 'true',
      });
      setShowDefaultValue(true);
    }
  }, [selectedDtype]);


  const haveDefaultVal = [
    'text',
    'select',
    'multi_select',
    'boolean',
    'price',
    'integer',
    'uom',
    'size',
  ];

  const haveDefaultBanner = [
    'uom',
    'select',
    'multi_select',
    'boolean',
    'composition',
    'decimal',
  ];

  const haveMandatory = ['boolean'];

  const onChange = (e) => {
    setShowDefaultValue(e.target.checked);
  };

  return (
    <div>
      <Title level={5}>Add values & tweak settings</Title>
      <p>Choose the defaults and specifications for attribute</p>
      <Form
        style={{ marginTop: '2rem' }}
        form={form}
        layout={'vertical'}
        className="attribute-settings-checkbox">
        {haveDefaultBanner?.includes(selectedDtype) && (
          <FormBanner
            selectedDtype={selectedDtype}
            attributeState={attributeState}
            setAttributeState={setAttributeState}
            uomData={uomData}
            form={form}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
        {haveDefaultVal?.includes(selectedDtype) &&
          attributeState?.selectedOption !== 'allow' && (
            <div>
              <Form.Item valuePropName="checked" name={'is_default'}>
                <Checkbox
                disabled={selectedDtype === 'boolean'}
                onChange={onChange}>
                  <Text style={{ fontWeight: 700 }}>Set a default Value</Text>
                </Checkbox>
              </Form.Item>
              <p style={{ marginLeft: '2em' , marginTop: '-0.5em' }}>
                Defaults are pre-filled data values that get added on data
                creation.
                </p>
              {showDefaultValue && (
                <DefaultsValue
                  selectedDtype={selectedDtype}
                  dropdownValues={attributeState?.dropdownValues}
                  selectedOption={attributeState?.selectedOption}
                  uomData={uomData}
                />
              )}
            </div>
          )}
        <Form.Item
          valuePropName="checked"
          name={'is_internal'}
          initialValue={false}>
          <Checkbox>
            <Text style={{ fontWeight: 700 }}>Set as Internal</Text>
          </Checkbox>
        </Form.Item>
        <p style={{ marginLeft: '2em', marginTop: '-0.5em' }}>
          Internal attributes are visible only to users and would not occur for
          external sharing
        </p>

        {!haveMandatory?.includes(selectedDtype) && (
          <div>
            <Form.Item
              valuePropName="checked"
              name={'is_mandatory'}
              initialValue={false}>
              <Checkbox>
                <Text style={{ fontWeight: 700 }}>Set as Mandatory</Text>
              </Checkbox>
            </Form.Item>
            <p style={{ marginLeft: '2em', marginTop: '-0.5em' }}>
              Make filling this value to be mandatory for product creation
            </p>
          </div>
        )}
        <Form.Item
          valuePropName="checked"
          name={'is_filter'}
          initialValue={false}>
          <Checkbox>
            <Text style={{ fontWeight: 700 }}>Set as Filterable</Text>
          </Checkbox>
        </Form.Item>
        <p style={{ marginLeft: '2em' }}>
          This attribute will appear among the filters for selection
        </p>
      </Form>
    </div>
  );
}

export default ValuesForm;
