import { Button, DatePicker, Table, Tag, Tooltip } from "antd";
import Title from "antd/lib/skeleton/Title";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import style from "styled-components";

const Subdiv = style.div`
	flexDirection: "row";
	border: 1px solid #E6E7E7;
	margin : 1rem 0 1rem 0;
	padding: 0 0.5rem 0.5rem 0.2rem;
    width: 100%;
    mix-blend-mode: normal;
    border-radius: 0.5rem;
`;
export default function PlanLineitems({ selectedOrg, addUpdatePlanLineItem, user }) {
    const [planLineItems, setPlanLineItems] = useState([]);
    const [customerPlanLineItems, setCustomerPlanLineItems] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchLineItems = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_INTERNAL_API}plan/customer_line_items`,
        {
            headers:{
                "access-token": `${user?.access_token}`
            },
            params: {
                "access_token": `${user?.access_token}`,
                "m_id": `${selectedOrg}`
            }
        }).then((res) => {
            if(res && res?.status === 200) {
                const plan_line_items = res?.data?.plan_line_items;
                const cust_line_items = res?.data?.customer_plan_line_items;
                let cust_line_items_dict = customerPlanLineItems;
                const final_selected_keys = cust_line_items?.map(item => {
                    cust_line_items_dict = {...cust_line_items_dict, [item?.plan_line_item_id]:item};
                    return item?.plan_line_item_id
                });
                const final_plan_items = plan_line_items?.map(item => {
                    let temp = cust_line_items_dict[item.id]?cust_line_items_dict[item.id]:{};
                    if (temp?.id){
                        temp["customer_plan_line_item_id"] = temp?.id
                    }
                    delete temp?.id;
                    delete temp?.status;
                    return {
                        ...item,
                        ...temp
                    }
                });
                
                setSelectedRowKeys(final_selected_keys);
                setCustomerPlanLineItems(cust_line_items_dict);
                setPlanLineItems(final_plan_items);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }
    useEffect(()=>{
        fetchLineItems();
    }, [selectedOrg]);
    const columns = [
        {
            title: "",
            key: "add_line_item",
            align: "center",
            render: (text, record) => (
                    <Button onClick={()=>{addUpdatePlanLineItem(record);}} style={{cursor:"pointer"}} >
                        {record?.customer_plan_id ? "Edit":"Add to Plan"}
                    </Button>
            ),
          },
        { title: "Id", dataIndex: "id", key: "id", ellipsis: true, align: "center"},
        {
          title: "Item Name",
          dataIndex: "item_name",
          key: "item_name",
          align: "center",
          width:"300px",
          render: (text, record) => (
            <Tooltip title={record?.service_id?.description} >
                <Text>
                {record?.service_id?.label}
                </Text>
            </Tooltip>
          ),
        },
        { title: "Plan Type", dataIndex: "plan_type", key: "plan_type",align: "center"},
        { title: "Plan Status", dataIndex: "plan_status", key: "plan_status",align: "center",
        filters: [
            {
              text: 'Active',
              value: 'active',
            },
            {
                text: 'Inactive',
                value: 'inactive',
              },
            {
              text: 'Expired',
              value: 'expired',
            },
          ],
          onFilter: (value, record) => record.plan_status.indexOf(value) === 0,
    },
        { title: "Limit Type", dataIndex: "limit_type", key: "limit_type",align: "center"},
        { title: "Limit", dataIndex: "limit", key: "limit",align: "center", width:"100px"},
        { title: "Duration", dataIndex: "duration_in_days", key: "duration_in_days",align: "center"},
        { title: "Last Reminder Date", dataIndex: "last_reminder_date", key: "last_reminder_date",align: "center"},
    ];

    return (
        <>
            <Title level={5}>
                    Current Plan Line Items
                </Title>
        <Subdiv>
            <Table 
                columns={columns}
                dataSource={planLineItems}
                loading={loading}
                sticky={true}
                scroll={{ y: 400 }}
            />
        </Subdiv>
        </>
    )
}