import SidebarActionTypes from '../actions/sidebarActions';
const initialState = {
    selectedMenuItem: "1"
};
const { ActionTypes } = SidebarActionTypes;
export default function reducer(state = initialState, action) {
switch (action.type) {
    case ActionTypes.SELECT_TAB: {
        const { key } = action.payload;
        if (key){
            return {
                ...state,
                selectedMenuItem: key
            };
        }else{
            return state;
        }
    }
    default:
    return state;
}
}
