import { Button, Modal, Space } from "antd";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import SelectPlanBody from "./SelectPlanBody";

export default function SelectPlanModal ({
    visiblePlanModal, 
    onCloseModal, 
    onPlanOk, 
    onCancel, 
    selectedOrg, 
    selectedRow, 
    setSelectedRow,
    selectedRowId,
    isPlanUpdating,
}) {
    const [finalData, setFinalData] = useState({});
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);
    useEffect(()=>{
        if(isPlanUpdating && visiblePlanModal){
            setFinalData({});
            axios.get(
                (`${process.env.REACT_APP_INTERNAL_API}plan/current`),
                {
                    params: {
                        "m_id":selectedOrg,
                        "access_token": user?.access_token,
                    },
                    headers:{
                        "access-token":user?.access_token
                    }
                }
            ).then((res)=>{
                if(res){
                    setSelectedRow(res?.data?.plan_id);
                    setFinalData({
                        customer_plan_id:res?.data?.id,
                        start_date: moment(res?.data?.start_date).format('YYYY-MM-DD'),
                        end_date: moment(res?.data?.end_date).format('YYYY-MM-DD'),
                        meta: res?.data?.meta,
                        amount: res?.data?.amount,
                        currency_id: res?.data?.currency_id,
                        discount: res?.data?.discount,
                        customer_type: res?.data?.customer_type
                    });
                }
            })
        }
    },[isPlanUpdating]);
    return (
        <Modal
            title={
                <div>
                    <Space direction="vertical">
                        <Text style={{fontSize:18, fontWeight:700}} >
                            Resolve request
                        </Text>
                        <Text type="secondary" style={{fontSize:16}} >
                            Add details for the plan request
                        </Text>
                    </Space>
                </div>
            }
            visible={visiblePlanModal}
            okText="Add"
            closable={false}
            onCancel={onCancel}
            onOk={()=>{
                console.log(finalData, "mMRMMRRM"); 
                 
                onPlanOk(finalData);
            }}
            width={400}
            centered
            afterClose={onCloseModal}
            footer={[
                <Space direction="horizontal" style={{justifyContent:"space-between", width:"100%"}} >
                    <Button
                        key="cancel" 
                        style={{width:104, height:40, backgroundColor:"#FFFFFF", color:"#4F5655", left:0, alignSelf:"end "}} 
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button 
                        key="resolve" 
                        style={{width:104, height:40, backgroundColor:"#1D2624", color:"#FFFFFF", right:0, alignSelf:"start"}} 
                        onClick={()=>{onPlanOk(finalData);}}
                    >
                        Resolve
                    </Button>
                </Space>
                ]}
        >
            <SelectPlanBody
                selectedOrg={selectedOrg} 
                selectedRow={selectedRow} 
                setSelectedRow={setSelectedRow} 
                setFinalData={setFinalData} 
                finalData={finalData}
                selectedRowId={selectedRowId} 
                isPlanUpdating={isPlanUpdating}
            />
        </Modal>
    )
}