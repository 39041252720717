import React, { useState } from 'react';
import { Form, Radio, Row, Select, Typography, Input, List } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { toggleRadioButtonValue } from '../../../redux/actions/attributeSettingActions';
import Button from '../AttributeSettingComponents/Button';
import AddingDropdownValue from '../../../assets/images/Adding-Dropdown-Values.svg';
import { CloseCircleFilled } from '@ant-design/icons';
import { IoMdCloseCircle } from 'react-icons/io';
import { openNotification } from '../AttributeSettingComponents/Notification';
import '../index.less';


const { Option } = Select;
const { Text, Title } = Typography;

function FormBanner({
  selectedDtype,
  attributeState,
  setAttributeState,
  uomData,
  form,
  errorMessage,
  setErrorMessage,
  data,
}) {
  const initialValue = useSelector((state) => state.attributeSetting.selectedOption);
  const { enteredValue, dropdownValues, precision} = attributeState;

  const dispatch = useDispatch();
  const onChange = (e) => {
    setAttributeState((state) => {
      return {
        ...state,
        selectedOption: e.target.value,
      };
    });
    dispatch(toggleRadioButtonValue(e.target.value));
  };

  const clearListHandler = () => {
    setAttributeState((state) => {
      return {
        ...state,
        dropdownValues: [],
      };
    });
    if (selectedDtype === 'multi_select') {
      form.setFieldsValue({
        [`select:value`]: [],
      });
    } else {
      form.setFieldsValue({
        [`${selectedDtype}:value`]: '',
      });
    }
  };

  const addNewDropdownValue = () => {
    if (enteredValue?.trim()?.length === 0) {
      return setErrorMessage('Please enter a value');
    }

    if (dropdownValues.includes(enteredValue)) {
      return setErrorMessage('This Value Already existed');
    }

    if (selectedDtype === 'composition') {
      if (dropdownValues?.length >= 8) {
        return setErrorMessage('Cannot add more than 8 values');
      }
    }

    setAttributeState((state) => {
      return {
        ...state,
        dropdownValues: [...state.dropdownValues, enteredValue],
        enteredValue: '',
      };
    });

    setErrorMessage('');
  };

  const handleDeleteValue = (value) => {
    setAttributeState((state) => {
      return {
        ...state,
        dropdownValues: state?.dropdownValues?.filter((item) => item !== value),
      };
    });
    if (selectedDtype === 'multi_select') {
      form.setFieldsValue({
        [`select:value`]: [],
      });
    } else {
      form.setFieldsValue({
        [`${selectedDtype}:value`]: '',
      });
    }
  };

  const handlePrecision = (type) => {
    switch (type) {
      case 'decrease':
        if (precision === 1) return;
        setAttributeState((state) => {
          return {
            ...state,
            precision: precision - 1,
          };
        });
        break;
      case 'increase':
        if (precision === 8) {
          return openNotification(
            'top',
            'Cannot Exceed more than 8',
            <IoMdCloseCircle style={{ color: 'red' }} />
          );
        }
        setAttributeState((state) => {
          return {
            ...state,
            precision: precision + 1,
          };
        });
        break;
      default:
        break;
    }
  };

  switch (selectedDtype) {
    case 'uom':
      return (
        <div className="attribute-banner">
          <p style={{ marginLeft: '2rem', paddingTop: '10px' }}>
            Select UOM type
          </p>
          <Form.Item
            style={{
              width: 350,
              marginLeft: '2rem',
              marginRight: '1rem',
            }}>
            <Select
              disabled={data?.id}
              onChange={(item) => {
                setAttributeState((state) => {
                  return {
                    ...state,
                    selectedOption: item,
                  };
                });
                form.setFieldsValue({
                  [`${selectedDtype}:unit`]: '',
                });
              }}
              value={attributeState?.selectedOption}
              placeholder="Select value">
              {Object.keys(uomData)?.map(
                (item, index) =>
                  item && (
                    <Option key={`${index}${item}`} value={item}>
                      {item}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
        </div>
      );
    case 'select':
    case 'multi_select':
    case 'composition':
      return (
        <div>
          <Form.Item
            name="dropdown"
            rules={[
              {
                required: !initialValue && true,
                message: 'Please select value',
              },
            ]}
          label="Adding dropdown values">
           <Radio.Group onChange={onChange} defaultValue={initialValue}>
              <Row>
                <Radio
                  className={
                    initialValue === 'allow'
                      ? 'attribute-setting-radio-box-checked'
                      : 'attribute-setting-radio-box'
                  }
                  value="allow">
                  <Text style={{ fontWeight: 700, fontSize: '14px' }}>
                    {selectedDtype === 'composition' ? 'Open Text' : 'Allow'}
                  </Text>
                </Radio>
                <Radio
                  className={
                    initialValue === 'restrict'
                      ? 'attribute-setting-radio-box-checked'
                      : 'attribute-setting-radio-box'
                  }
                  value="restrict">
                  <Text style={{ fontWeight: 700, fontSize: '14px' }}>
                    {selectedDtype === 'composition'
                      ? 'Dropdown Values'
                      : 'Restrict'}
                  </Text>
                </Radio>
              </Row>
            </Radio.Group>
          </Form.Item>
          {initialValue === 'restrict' && (
            <div className="dropdown-values-banner">
              <Title
                level={5}
                style={{ marginLeft: '1rem', paddingTop: '10px' }}>
                Dropdown Values
              </Title>
              <Row style={{ marginLeft: '1rem', paddingBottom: '1rem' }}>
                <Input
                  placeholder="Enter Dropdown Value"
                  style={{ width: 250 }}
                  onChange={(e) =>
                    setAttributeState((state) => {
                      return {
                        ...state,
                        enteredValue: e.target.value,
                      };
                    })
                  }
                  value={enteredValue}
                />
                <Button
                  onClick={() => addNewDropdownValue()}
                  variant="primary"
                  style={{ marginLeft: '20px' }}>
                  Add
                </Button>
              </Row>
              {errorMessage && (
                <div
                  style={{
                    marginLeft: '1rem',
                    marginTop: '-10px',
                    color: 'red',
                  }}>
                  {errorMessage}
                </div>
              )}
              {dropdownValues?.length === 0 && (
                <div className="add-dropdown-value">
                  <img
                    style={{ height: '120px', width: '120px' }}
                    src={AddingDropdownValue}
                    alt="No Dropdown Value"
                  />
                  <Title level={5}>No options added for dropdown</Title>
                  <Text>Start adding options</Text>
                </div>
              )}
              {dropdownValues?.length !== 0 && (
                <div style={{ marginLeft: '1rem', paddingBottom: '1rem' }}>
                  <Text> Added Options</Text>
                  <Text
                    onClick={() => clearListHandler()}
                    style={{
                      marginLeft: '17rem',
                      fontWeight: 700,
                      color: 'red',
                      cursor: 'pointer',
                    }}>
                    Clear List
                  </Text>
                  <List
                    size="small"
                    bordered
                    style={{
                      width: 450,
                    }}
                    dataSource={dropdownValues}
                    renderItem={(item) => (
                      <Row>
                        <List.Item
                          style={{
                            borderBottom: '1px solid #9B9F9E',
                            width: '100%',
                          }}>
                          {item}
                        </List.Item>
                        <CloseCircleFilled
                          onClick={() => handleDeleteValue(item)}
                          style={{
                            fontSize: '1rem',
                            marginLeft: 'auto',
                            marginTop: '-1.7rem',
                            marginRight: '1rem',
                            color: '#9B9F9E',
                            cursor: 'pointer',
                          }}
                        />
                      </Row>
                    )}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      );
    case 'boolean':
      return (
        <div className="attribute-banner-boolean">
          <Title style={{ marginLeft: '1rem', paddingTop: '1rem' }} level={5}>
            {' '}
            Enter Toggle Value{' '}
          </Title>
          <Form.Item style={{ width: 350, marginLeft: '1rem' }} label="False">
            <Input
              onChange={(e) =>
                setAttributeState((state) => {
                  return {
                    ...state,
                    boolFalseVal: e.target.value,
                  };
                })
              }
              value={attributeState?.boolFalseVal}
            />
          </Form.Item>
          <Form.Item style={{ width: 350, marginLeft: '1rem' }} label="True">
            <Input
              onChange={(e) =>{
                setAttributeState((state) => {
                  return {
                    ...state,
                    boolTrueVal: e.target.value,
                  };
                });
                if (e.target.value === attributeState?.boolFalseVal) {
                  setErrorMessage('Both the values should not be same');
                } else {
                  setErrorMessage('');
                }
              }}
              type="text"
              value={attributeState?.boolTrueVal}
            />
             {errorMessage && (
              <div
                style={{
                  marginLeft: '1rem',
                  color: 'red',
                }}>
                {errorMessage}
              </div>
            )}
          </Form.Item>
        </div>
      );
    case 'decimal':
      return (
        <div className="attribute-banner">
          <Row style={{ paddingTop: '1.5rem' }}>
            <p style={{ marginLeft: '1rem', paddingTop: '10px' }}>
              Select decimal place(s)
            </p>
            <Button
              onClick={() => handlePrecision('decrease')}
              style={{ margin: '0 1rem ' }}
              type={'primary'}>
              -
            </Button>
            <Form.Item>
              <Input
                style={{ width: '80px', height: '40px', marginTop: '-10px' }}
                value={parseInt('1').toFixed(precision)}
              />
            </Form.Item>
            <Button
              onClick={() => handlePrecision('increase')}
              style={{ marginLeft: '1rem ' }}
              type={'primary'}>
              +
            </Button>
          </Row>
          <Row style={{ marginLeft: '1rem', marginTop: '-7px' }}>
            <Text>
              *The values will be rounded off to select decimal place(s)
            </Text>
          </Row>
        </div>
      );
    default:
      return <div></div>;
  }
}

export default FormBanner;
