import React from "react";

import { Modal, Typography, Input } from "antd";

const CollectionLinkModal = ({
  handleOk,
  handleCancel,
  showModal,
  setShowModal,
  setCollectionLink,
  collectionLink,
  defaultCollectionID,
}) => {
  const { Text } = Typography;
  return (
    <div>
      <Modal
        centered
        visible={showModal}
        onCancel={handleCancel}
        onOk={handleOk}
        // onCancel={handleCancelVariant}
        // footer={null}
        // width={"80%"}
        closable={false}
        closeIcon={null}
      >
        <Text>Enter the collection link you want to download a PPT for:</Text>
        <br />
        <Input
          addonBefore="https://catalogue.sourcewiz.co/"
          defaultValue={collectionLink}
          onBlur={(e) => {
            setCollectionLink(e.target.value);
          }}
        ></Input>

        <Text type="secondary">
          Default Collection Link: {defaultCollectionID}
        </Text>
      </Modal>
    </div>
  );
};
export default CollectionLinkModal;
