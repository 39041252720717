
export const defaultOptions = {
  price: {
    currency: ['USD', 'INR', 'GBP', 'EUR', 'RMB', 'AUD'],
    unit: [
      'per-piece',
      'per-feet',
      'per-inch',
      'per-cm',
      'per-metre',
      'per-1000-metres',
      'per-gram',
      'per-kilogram',
      'per-metric-ton',
      'per sq. feet',
      'per sq. metre',
    ],
  },
  size: {
    unit: ['cm', 'inches'],
  },
  packaging: {
    unit: ['cm', 'inch', 'mm', 'ft', 'm', 'km'],
  },
};

const tabs = ['production', 'internal', 'production_detail'];

const getTabName = [
  {
    tabname: 'production',
    label: 'Product Info',
  },
  {
    tabname: 'internal',
    label: 'Internal Details',
  },
  {
    tabname: 'production_detail',
    label: 'Production Details',
  },
];


const getPayloadBasedOnDType = (data) => {
  const { dtype = '' } = data;
  switch (dtype) {
    case 'price':
      return {
        dtype: dtype,
        default_values: {
          composite: {
            currency: data[`${dtype}:currency`],
            unit: data[`${dtype}:unit`],
          },
          values: data[`${dtype}:value`],
        },
        default: {
          currency: defaultOptions?.price?.currency,
          unit: defaultOptions?.price?.unit,
        },
      };
    case 'composition':
      return {
        dtype: dtype,
        default: {
          maxCount: 8,
          renderType: data?.selectedOption === 'restrict' ? 'select' : 'text',
          options: data?.dropdownValues,
        },
      };
    case 'size':
      const sizeObj = {
        length: data[`${dtype}:length`],
        width: data[`${dtype}:width`],
        height: data[`${dtype}:height`],
      };
      const sizeUnit = data[`${dtype}:unit`];

      const parsedValue = `${Object.values(sizeObj)
        .filter(Boolean)
        .join('x')} ${sizeUnit || ''}`;
      return {
        dtype: 'size',
        default_values: {
          composite: {
            length: sizeObj?.length,
            width: sizeObj?.width,
            height: sizeObj?.height,
            unit: sizeUnit,
          },
          values: parsedValue,
        },
        default: {
          unit: defaultOptions?.size?.unit,
        },
      };
    case 'select':
      return {
        dtype: 'select',
        default: {
          mode: dtype === 'select' ? 'single' : 'multiple',
          options_type: data?.selectedOption === 'restrict' ? 'options' : 'algolia',
          options: data?.dropdownValues || [],
          allow_adding_new: data?.selectedOption === 'restrict' ? false : true,
        },
        default_values: {
          composite: {},
          values: data[`select:value`]?.toString(),
        },
      };
      case 'multi_select':
        if (data?.selectedOption === 'allow') {
          return {
            dtype: 'select',
            default: {
              mode: 'multiple',
              options_type: 'algolia',
              options: [],
              allow_adding_new: true,
            },
          };
        } else {
          return {
            dtype: 'select',
            default: {
              mode: 'multiple',
              options_type: 'options',
              options: data?.dropdownValues || [],
              allow_adding_new: false,
            },
            default_values: {
              composite: {},
              values: data[`select:value`]?.toString(),
            },
          };
        }
    case 'boolean':
      return {
        dtype: dtype,
        default: {
          label: {
            true: data?.boolTrueVal || 'No',
            false: data?.boolFalseVal || 'Yes',
          },
        },
        default_values: {
          composite: {},
          values: data[`${dtype}:value`],
        },
      };
    case 'decimal':
      return {
        dtype: dtype,
        default: {
          precision: data?.precision || 2,
        },
      };
    case 'integer':
      return {
        dtype: 'decimal',
        default_values: {
          composite: {},
          values: data[`${dtype}:value`],
        },
        default: {
          precision: 0,
        },
      };
    case 'uom':
      const uomValue = {
        value: data[`${dtype}:value`],
        unit: data[`${dtype}:unit`],
      };
      return {
        dtype: dtype,
        default: {
          uomFamily: data?.selectedOption,
        },
        default_values: {
          composite: {
            value: uomValue?.value,
            unit: uomValue?.unit,
          },
          values: `${uomValue?.value || ''} ${uomValue?.unit || ''}`,
        },
      };
    case 'text':
      return {
        dtype: dtype,
        default_values: {
          composite: {},
          values: data[`${dtype}:value`],
        },
      };
    default:
      return {
        dtype: dtype === 'paragraph' ? 'textarea' : dtype,
      };
  }
};

const getAttributeSettingPayload = (data) => {
  const values = getPayloadBasedOnDType(data);
  return {
    name: data?.name || '',
    type: 'product',
    is_internal: data?.is_internal || false,
    is_filter: data?.is_filter || false,
    compulsory: data?.is_mandatory || false,
    tabname: data?.is_internal ? 'internal' : data?.section || '',
    status: 'published',
    ...values,
  };
};

const getAttributeFormValues = (data) => {
  const haveDefaultValue = data?.default_values?.values;
  switch (data?.dtype) {
    case 'select':
    case 'multi_select':
      return {
        is_default: haveDefaultValue ? true : false,
        [`${data?.dtype}:value`]: data?.default_values?.values
          ? data?.default_values?.values?.split(',')
          : '',
      };
      case 'boolean':
      case 'text':
      return {
        is_default: haveDefaultValue ? true : false,
        [`${data?.dtype}:value`]: data?.default_values?.values
        ? data?.default_values?.values
        : '',
      };
      case 'decimal':
      if (data?.default?.precision === 0) {
        return {
          is_default: haveDefaultValue ? true : false,
          [`integer:value`]: data?.default_values?.values || '',
        };
      }
    case 'price':
      return {
        is_default: haveDefaultValue ? true : false,
        [`${data?.dtype}:value`]: data?.default_values?.values || '',
        [`${data?.dtype}:unit`]: data?.default_values?.composite?.unit || '',
        [`${data?.dtype}:currency`]:
          data?.default_values?.composite?.currency || '',
      };
    case 'size':
      return {
        is_default: haveDefaultValue ? true : false,
        [`${data?.dtype}:unit`]: data?.default_values?.composite?.unit || '',
        [`${data?.dtype}:length`]:
          data?.default_values?.composite?.length || '',
        [`${data?.dtype}:width`]: data?.default_values?.composite?.width || '',
        [`${data?.dtype}:height`]:
          data?.default_values?.composite?.height || '',
      };
    case 'uom':
      return {
        is_default: haveDefaultValue?.trim() ? true : false,
        [`${data?.dtype}:unit`]: data?.default_values?.composite?.unit || '',
        [`${data?.dtype}:value`]: data?.default_values?.composite?.value || '',
      };
    default:
      break;
  }
};

const getDefaultsValue = (data) => {
  switch (data?.dtype) {
    case 'select':
    case 'multi_select':
      if (data?.default) {
        return {
          selectedOption: data?.default?.allow_adding_new
            ? 'allow'
            : 'restrict',
          dropdownValues: data?.default?.options || [],
        };
      }
      return {
        selectedOption: 'allow',
      };
      case 'composition':
        return {
          selectedOption:
            data?.default?.renderType === 'text' ? 'allow' : 'restrict',
          dropdownValues: data?.default?.options || [],
        };
    case 'csv':
      return {
        selectedOption: 'allow',
      };
    case 'boolean':
      return {
        boolFalseVal: data?.default?.label?.[false] || 'Yes',
        boolTrueVal: data?.default?.label?.[true] || 'No',
      };
    case 'uom':
      return {
        selectedOption: data?.default?.uomFamily,
      };
    case 'decimal':
      return {
        precision: data?.default?.precision,
      };
    default:
      return {};
  }
};

const getSelectedDType = (data) => {
  switch (data?.dtype) {
    case 'textarea':
      return 'paragraph';
    case 'csv':
      return 'multi_select';
    case 'decimal':
      if (data?.default?.precision >= 1) {
        return 'decimal';
      }
      return 'integer';
    case 'select':
      if (data?.default?.mode === 'multiple') {
        return 'multi_select';
      }
      return 'select';
    default:
      return data?.dtype;
  }
};

export {
  getAttributeSettingPayload,
  getAttributeFormValues,
  getDefaultsValue,
  getSelectedDType,
  getTabName,
  tabs
};
