import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Progress,
  Button,
  Skeleton,
  Divider,
  Spin,
  Drawer,
  Tag,
  Space
} from "antd";
import style from "styled-components";
import "./activeplan.styles.css";
import { numberWithCommas } from "./numberWithCommas";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { PaymentAndInvoice } from "./PaymentInvoice";
import { CloseOutlined } from "@ant-design/icons";
import PlanLineitems from "./PlanLineItems";
import AddUpdatePlanLineItem from "./AddUpdatePlanLineItem";
const { Title, Text } = Typography;
const Subdiv = style.div`
	flexDirection: "row";
	border: 1px solid #E6E7E7;
	box-sizing: border-box;
	margin : 1rem 0 1rem 0;
	padding: 1.3rem;
    width: 97%;
	opacity: 1;
    height: 380px;
  mix-blend-mode: normal;
  border-radius: 8px;
  overflow: scroll;
`;
const PlanDiv = style.div`
    width: 100%;
    margin: auto;
    margin-top: 1rem;
    padding: 1.7rem;
    border-radius: 8px;
    background: linear-gradient(90.61deg, rgb(230, 223, 251, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
    mix-blend-mode: normal;
`;
export const CurrentPlans = ({
  selectedOrg,
  visibleAddonModal,
  visiblePlanModal,
  setVisibleAddonModal,
  setVisiblePlanModal,
  selectedRow,
  setSelectedRow,
  selectedRowId,
  setSelectedRowId,
  setVisibleModal,
  onCloseModal,
  onAddonOk,
  onCancel,
  onPlanOk,
  moduleId,
  setModuleId,
  isPlanUpdating,
  setIsPlanUpdating,
}) => {
  const [planData, setPlanData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [lineItem, setLineItem] = useState({});
  const [visibleLineItemModal, setVisibleLineItemModal] = useState(false);
  const userData = localStorage.getItem("userData");
  const user = userData && JSON.parse(userData);

  useEffect(() => {
    getCurrentPlanDetails();
  }, [selectedOrg]);
  const getCurrentPlanDetails = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_INTERNAL_API}plan/current`, {
        params: {
          access_token: `${user?.access_token}`,
          m_id: `${selectedOrg}`,
        },
        headers: {
          "access-token": `${user?.access_token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setPlanData(res?.data);
        }
      })
      .catch((err) => {
        setPlanData({});
        setLoading(false);
        console.log(err);
      });
  };
  const handleUpdatePlan = () => {
    setLoadingButton(true);
    setVisiblePlanModal(true);
    setSelectedRowId(uuid());
    setIsPlanUpdating(true);
    setLoadingButton(false);
  };
  const handleUpgradePlan = () => {
    setLoadingButton(true);
    axios
      .get(`${process.env.REACT_APP_INTERNAL_API}plan/next_upgrade`, {
        headers: {
          "access-token": user?.access_token,
        },
        params: {
          access_token: user?.access_token,
          m_id: selectedOrg,
        },
      })
      .then((res) => {
        if (res && res?.status === 200) {
          setSelectedRow(res?.data?.id);
          // const row_id = uuid();
          // setSelectedRowId(row_id);
          setVisiblePlanModal(true);
          setLoadingButton(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingButton(false);
        setSelectedRow(undefined);
        setSelectedRowId(undefined);
      });
  };
  const handleUpgradeAddon = (selected) => {
    setModuleId(selected?.module_id);
    setLoadingButton(true);
    axios
      .get(
        `${process.env.REACT_APP_INTERNAL_API}upgrade/${selected?.module_id}`,
        {
          headers: {
            "access-token": user?.access_token,
          },
          params: {
            access_token: user?.access_token,
            m_id: selectedOrg,
          },
        }
      )
      .then((res) => {
        if (res && res?.status === 200) {
          const addon_id = res?.data?.available_addon?.addon_details?.id;
          setSelectedRow(addon_id);
          // const row_id = uuid();
          // setSelectedRowId(row_id);
          setVisibleAddonModal(true);
          setLoadingButton(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingButton(false);
      });
  };
  const addUpdatePlanLineItem = (record) => {
    setLineItem(record);
    setVisibleLineItemModal(true);
  };
  // const handleUpgradeAddon = (selected) => {

  //   const isRequestPending = planData?.pending_requests?.filter(
  //     (e) =>
  //       e?.feature_identifier === selected?.module_id && e?.status === "pending"
  //   );
  //   if (isRequestPending?.length === 0) {
  //     setModuleId(selected?.module_id);
  //     setAddOnRequest(true);
  //   } else {
  //     setRequestStatusModal(true);
  //   }
  // };
  return loading ? (
    <>
      <Row>
        <Skeleton active />
      </Row>
      <Row>
        <Skeleton active />
      </Row>
      <Row>
        <Skeleton active />
      </Row>
    </>
  ) : (
    <>
      <Drawer
        title={
          <Row justify="space-between">
            <Col>
              <Text style={{ fontSize: 20, fontWeight: 700 }}>
                Payment & invoices
              </Text>
            </Col>
            <Col>
              <CloseOutlined
                onClick={() => {
                  setVisibleDrawer(false);
                }}
              />
            </Col>
          </Row>
        }
        visible={visibleDrawer}
        width={750}
        closable={false}
        forceRender={true}
        onClose={() => {
          if (visibleDrawer) {
            setVisibleDrawer(false);
          }
        }}
      >
        <PaymentAndInvoice selectedOrg={selectedOrg} user={user} />
      </Drawer>
      <AddUpdatePlanLineItem
        lineItem={lineItem}
        selectedOrg={selectedOrg}
        setVisibleModal={setVisibleLineItemModal}
        visibleModal={visibleLineItemModal}
        key="add_update_line_item"
      />
      <Row justify="space-between">
        {/* Active Plan Details */}
        <Col span={12}>
          <Subdiv>
            <Row justify="space-between">
              <Col span={10}>
                <Row
                  justify="space-between"
                  style={{ display: "flex", alignItems: "center" }}
                ><Col>
                <Text level={3} style={{ fontFamily: "Gilroy" }}>
            # {planData?.customer_id}
              </Text>
                
                </Col>
                  <Col>Current plan</Col>
                  
                  <Col>
                    {planData?.status === "published" ? (
                      <Tag color="green" style={{ marginRight: "10px" }}>
                        Active
                      </Tag>
                    ) : (
                      <Tag color="red" style={{ marginRight: "10px" }}>
                        Expired
                      </Tag>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row >
                  <Space>
                  <a
                    onClick={handleUpdatePlan}
                    style={{ display: "inline-block" }}
                  >
                    Edit
                  </a>
                  <Spin spinning={loadingButton}>
                    <a onClick={handleUpgradePlan}>Upgrade</a>
                  </Spin>
                  </Space>
                </Row>
                
              </Col>
            </Row>
            <PlanDiv>
              <Row justify="space-between">
                <Col>
                  <Title level={5}>{planData?.plan?.label}</Title>
                </Col>
                <Col>
                  <Title level={5}>
                    ₹ {numberWithCommas(planData?.amount)} / year
                  </Title>
                </Col>
              </Row>

              {/* <Row justify="space-between">
            <Col>
              <Text type="secondary">Discount</Text>
            </Col>
            <Col>
              <Text type="secondary">Exclusive of GST</Text>
            </Col>
          </Row> */}
              <Divider style={{ background: "#CDCFCE" }} />
              <Row justify="space-between" style={{ marginBottom: 10 }}>
                <Col>
                  <Text style={{ color: "#363E3D" }}>Start Date</Text>
                </Col>
                <Col>
                  <Text>
                    {new Date(planData?.start_date)?.toLocaleString("en-IN", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text style={{ color: "#363E3D" }}>End Date</Text>
                </Col>
                <Col>
                  <Text style={{ color: "#363E3D", fontWeight: 1000 }}>
                    {new Date(planData?.end_date)?.toLocaleString("en-IN", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </Text>
                </Col>
              </Row>
            </PlanDiv>
            <Row justify="center">
              <Text
                className="text-tab-change"
                onClick={() => {
                  setVisibleDrawer(true);
                }}
              >
                View payment details and invoices
              </Text>
            </Row>
          </Subdiv>
        </Col>
        {/* Plan Usage Section */}
        <Col span={12}>
          <Subdiv style={{ paddingTop: 0 }}>
            <Row justify="space-between" className="planusage-title-row">
              <Col>
                Plan usage
                {/* {percentUsed && (
                <Text style={{ color: "#4F5655" }}>
                    You have exhausted {percentUsed}% of all your limits
                </Text>
                )} */}
              </Col>
              {/* <Col>
                <Button size="large" onClick={handleUpgradePlan}>
                Upgrade
                </Button>
            </Col> */}
            </Row>
            {planData?.usage
              ?.filter((e) => e?.has_limit === true)
              ?.map((el) => {
                return (
                  <Col>
                    <Row justify="space-between">
                      <Col span={15}>
                        <Row>
                          <Col span={12} style={{ marginRight: 10 }}>
                            <Row>{el?.module_label}</Row>
                            <Row>
                              <Progress
                                style={{ width: "100px" }}
                                percent={el?.percent_used}
                                strokeColor={`${
                                  el?.percent_used === 100
                                    ? "linear-gradient(269.71deg, #F34D4D 13.22%, #FFA42B 95.4%)"
                                    : el?.percent_used >= 90
                                    ? "linear-gradient(269.83deg, #FFB34D 7.26%, #7DB8AD 136.36%)"
                                    : "linear-gradient(269.73deg, #7DB8AD -12.63%, rgba(120, 153, 26, 0.5) 100%)"
                                }`}
                                showInfo={false}
                              />
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Button
                              className="plan-usage-btn-upgrade"
                              onClick={() => handleUpgradeAddon(el)}
                              loading={loadingButton}
                              disabled={loadingButton}
                            >
                              Upgrade
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row style={{ fontWeight: "bold" }} justify="end">
                          {el?.usage} / {el?.limit}
                        </Row>
                        <Row style={{ marginTop: "0.5rem" }} justify="end">
                          <Col>
                            <Text style={{ fontSize: 12 }}>
                              {el?.remaining} left
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                  </Col>
                );
              })}
          </Subdiv>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <PlanLineitems
            selectedOrg={selectedOrg}
            addUpdatePlanLineItem={addUpdatePlanLineItem}
            user={user}
          />
        </Col>
      </Row>

      {/* Benefits of active plan */}
      {/* <Subdiv>
        <Title level={5} style={{ marginBottom: "1.5rem" }}>
          Benefits of {planData?.plan?.label && planData?.plan?.label} plan
        </Title>
        {planData?.plan?.plan_line_items &&
          planData?.plan?.plan_line_items
            ?.filter((e) => e?.type !== "coming-soon")
            ?.map((e) => {
              return (
                <>
                  <Row>
                    <Col>
                      <img src={greenTick} />{" "}
                    </Col>
                    <Col className="subscription-title-font">
                      {e?.service_id?.label}
                    </Col>
                  </Row>
                  <Row>
                    <Text className="subscription-subtitle-font" style={{}}>
                      {e?.service_id?.description}
                    </Text>
                  </Row>
                  <br />
                </>
              );
            })}
      </Subdiv> */}
      {/* <AddOnSuccess
        addOnData={addOnDataUsers}
        isModalVisible={addOnRequest}
        setIsModalVisible={setAddOnRequest}
      /> */}
      {/* <RequestStatusModal
        data={PostRequestTexts}
        requestStatusModal={requestStatusModal}
        setRequestStatusModal={setRequestStatusModal}
      /> */}
    </>
  );
};
