import { AiOutlineColumnHeight } from 'react-icons/ai';
import { BsUiChecks } from 'react-icons/bs';
import {
  MdDateRange,
  MdOutlinePriceChange,
  MdOutlineWorkspaces,
  MdTextRotationNone,
} from 'react-icons/md';
import { RiParagraph } from 'react-icons/ri';
import { RxDropdownMenu } from 'react-icons/rx';
import { FiLink, FiToggleRight } from 'react-icons/fi';
import { TbBrandHtml5, TbDimensions, TbNumbers } from 'react-icons/tb';
import { ImListNumbered } from 'react-icons/im';

export const dtypes = [
  {
    name: 'text',
    label: 'Text',
    icon: <MdTextRotationNone size={24} />,
  },
  {
    name: 'paragraph',
    label: 'Long text',
    icon: <RiParagraph size={24} />,
  },
  {
    name: 'date',
    label: 'Date',
    icon: <MdDateRange size={24} />,
  },
  {
    name: 'select',
    label: 'Single select',
    icon: <RxDropdownMenu size={24} />,
  },
  {
    name: 'price',
    label:'Price',
    icon: <MdOutlinePriceChange size={24} />,
  },
  {
    name: 'multi_select',
    label: 'Multi Select',
    icon: <BsUiChecks size={24} />,
  },
  {
    name: 'size',
    label: 'Dimension',
    icon: <TbDimensions size={24} />,
  },
  {
    name: 'uom',
    label: 'UOM',
    icon: <AiOutlineColumnHeight size={24} />,
  },
  {
    name: 'url',
    label:'URL',
    icon: <FiLink size={24} />,
  },
  {
    name: 'html',
    label: 'HTML',
    icon: <TbBrandHtml5 size={24} />,
  },
  {
    name: 'composition',
    label: 'Composition',
    icon: <MdOutlineWorkspaces size={24} />,
  },
  {
    name: 'integer',
    label: 'Integer',
    icon: <ImListNumbered size={24} />,
  },
  {
    name: 'decimal',
    label: 'Decimal',
    icon: <TbNumbers size={24} />,
  },
];
