import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";

const TemplateActions = ({
  handleEditTemplate,
  handleDeleteTemplate,
}) => {
  return (
    <>
      <BiEdit size={22} onClick={handleEditTemplate} />
      <MdDelete size={22} onClick={handleDeleteTemplate} />
    </>
  );
};

export default TemplateActions;
