import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Select,
  PageHeader,
  Divider,
  message,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import API_KEY from "../../api";
import { CurrentPlans } from "./CurrentPlans";
import { Requests } from "./Requests";
import SelectAddonModal from "./SelectAddonModal";
import SelectPlanModal from "./SelectPlanModal";
import axios from "axios";
import moment from "moment";
import { v4 as uuid } from "uuid";
import sidebarActions from "../../redux/actions/sidebarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Title, Text } = Typography;

const Plans = ({ _, actions, allHits }) => {
  const params = useParams();
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(
    params?.selectedOrg || params?.selectedOrg !== undefined
      ? params?.selectedOrg
      : null
  );
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [visibleAddonModal, setVisibleAddonModal] = useState(false);
  const [visiblePlanModal, setVisiblePlanModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [selectedRowId, setSelectedRowId] = useState(undefined);
  const [moduleId, setModuleId] = useState(undefined);
  const [isPlanUpdating, setIsPlanUpdating] = useState(false);
  const userData = localStorage.getItem("userData");
  const user = userData && JSON.parse(userData);
  const navigate = useNavigate();
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };
  const setVisibleModal = (show, record) => {
    if (show) {
      setSelectedRow(record?.feature_identifier);
      setSelectedRowId(record?.id);
    }
    if (record?.feature_type === "addon") {
      setVisibleAddonModal(show);
    } else if (record?.feature_type === "plan") {
      setVisiblePlanModal(show);
    }
  };
  const onCancel = () => {
    setVisibleAddonModal(false);
    setVisiblePlanModal(false);
    setSelectedRow("");
    setModuleId(undefined);
  };
  const onAddonOk = (finalData) => {
    console.log(finalData);
    const payload = {
      customer_id: selectedOrg,
      addons: [
        {
          add_on_id: finalData?.plan_addon?.add_on_id,
          start_date: finalData?.start_date
            ? moment(finalData?.start_date).format("YYYY-MM-DD")
            : undefined,
          end_date: finalData?.end_date
            ? moment(finalData?.end_date).format("YYYY-MM-DD")
            : undefined,
          last_reminder_date: finalData?.plan_addon?.last_reminder_date
            ? moment(finalData?.plan_addon?.last_reminder_date).format(
                "YYYY-MM-DD"
              )
            : moment(Date.now()).format("YYYY-MM-DD"),
          has_limit: !!finalData?.plan_addon?.has_limit,
          type: finalData?.plan_addon?.type,
          limit_type: finalData?.plan_addon?.limit_type,
          limit: finalData?.plan_addon?.limit,
          currency_id: finalData?.plan_addon?.currency_id,
          amount: finalData?.plan_addon?.amount,
          discount_id: finalData?.plan_addon?.discount,
        },
      ],
    };
    axios
      .post(
        `${process.env.REACT_APP_INTERNAL_API}account/create/addon`,
        payload,
        {
          params: {
            request_id: selectedRowId,
          },
          headers: {
            "access-token": `${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        if (res && res.status === 200) {
          message.success("Your request is sent for approval");
          const tempOrg = selectedOrg;
          setSelectedOrg(undefined);
          setSelectedOrg(tempOrg);
          // resolveRequest(selectedRowId);
        }
      })
      .catch((err) => {
        console.log(err);
        message.warning(`Got error as ${err}`);
      });

    setSelectedRow("");
    setVisibleAddonModal(false);
    setVisiblePlanModal(false);

    setModuleId(undefined);
  };
  const onPlanOk = (finalData) => {
    const plan_line_items = finalData?.plan_line_items
      ? finalData?.plan_line_items
      : [];
    if (finalData?.plan_line_items) {
      delete finalData.plan_line_items;
    }
    if (!isPlanUpdating) {
      finalData["plan_id"] = finalData?.id;
    }
    finalData["meta"] = finalData?.meta
      ? JSON.stringify(finalData?.meta)
      : JSON.stringify({});
    finalData["discount_id"] = finalData?.discount
      ? finalData?.discount
      : undefined;
    const payload = {
      customer_id: selectedOrg,
      plan: finalData,
    };
    if (isPlanUpdating) {
      axios
        .post(
          `${process.env.REACT_APP_INTERNAL_API}account/update/plan`,
          payload,
          {
            headers: {
              "access-token": user?.access_token,
            },
            params: {
              access_token: user?.access_token,
            },
          }
        )
        .then((res) => {
          if (res && res.status === 200) {
            message.success("Your request is sent for approval");
            const tempOrg = selectedOrg;
            setSelectedOrg(undefined);
            setSelectedOrg(tempOrg);
            // resolveRequest(selectedRowId);
          }
        })
        .catch((err) => {
          console.log(err);
          message.warning(`Got error as ${err}`);
        });
    } else {
      payload["plan_line_items"] = plan_line_items?.map((item) => {
        return {
          ...item,
          plan_line_item_id: item?.id,
          start_date: finalData?.start_date,
          type: finalData?.type,
          end_date: moment(finalData?.start_date)
            .add(item?.duration_in_days, "days")
            .isBefore(moment(finalData?.end_date))
            ? moment(finalData?.start_date)
                .add(item?.duration_in_days, "days")
                .format("YYYY-MM-DD")
            : moment(finalData?.start_date).format("YYYY-MM-DD"),
          last_reminder_date: moment(Date.now()).format("YYYY-MM-DD"),
          plan_status: "active",
        };
      });

      axios
        .post(
          `${process.env.REACT_APP_INTERNAL_API}account/upgrade/plan`,
          payload,
          {
            headers: {
              "access-token": user?.access_token,
            },
            params: {
              request_id: selectedRowId,
            },
          }
        )
        .then((res) => {
          if (res && res.status === 200) {
            message.success("Your request is sent for approval");
            const tempOrg = selectedOrg;
            setSelectedOrg(undefined);
            setSelectedOrg(tempOrg);
            // resolveRequest(selectedRowId);
          }
        })
        .catch((err) => {
          console.log(err);
          message.warning(`Got error as ${err}`);
        });
    }
    setSelectedRow("");
    setVisibleAddonModal(false);
    setVisiblePlanModal(false);
    setModuleId(undefined);
  };
  const onCloseModal = () => {
    setSelectedRow("");
    setModuleId(undefined);
    setIsPlanUpdating(false);
  };
  const resolveRequest = (requestId) => {
    axios
      .patch(
        `${process.env.REACT_APP_INTERNAL_API}user_request/update`,
        {
          user_request_id: requestId,
          customer_id: selectedOrg,
          status: "approved",
        },
        {
          headers: {
            "access-token": user?.access_token,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          // setSelectedRowId(uuid());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        meta: "*",
        limit: -1,
        fields: "id,name",
        filter: {
          _and: [
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },
            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
            {
              name: {
                _ncontains: "test",
              },
            },
          ],
        },
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
        setOrgs(
          res?.data?.data.map((org) => {
            return {
              label: `${org.name}`,
              value: org.id,
              key: org.id,
            };
          })
        );
      }
    });
    actions.selectTab("11");
  }, []);
  return (
    <>
      <SelectAddonModal
        {...{
          visibleAddonModal,
          onCloseModal,
          onAddonOk,
          onCancel,
          selectedOrg,
          selectedRow,
          setSelectedRow,
          selectedRowId,
          moduleId,
        }}
      />

      <SelectPlanModal
        {...{
          visiblePlanModal,
          onCloseModal,
          onPlanOk,
          onCancel,
          selectedOrg,
          selectedRow,
          setSelectedRow,
          selectedRowId,
          isPlanUpdating,
          setIsPlanUpdating,
        }}
      />
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={
              <Row>
                <Title level={3} style={{ fontFamily: "Gilroy" }}>
                  Plans
                </Title>
              </Row>
            }
            subTitle={"List of all plans of users"}
          />
        </Col>
      </Row>

      <Row>
        <Col span={6}>
          <Select
            showSearch
            style={{ width: "80%" }}
            placeholder="Search by MID or name"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value.toString() === input
            }
            options={orgs}
            onChange={handleOrgChange}
            value={selectedOrg && parseInt(selectedOrg)}
          ></Select>
        </Col>
      </Row>
      <Divider />
      {selectedOrg && (
        <Row justify="space-between">
          <Col span={24}>
            <CurrentPlans
              {...{
                selectedOrg,
                visibleAddonModal,
                visiblePlanModal,
                setVisibleAddonModal,
                setVisiblePlanModal,
                selectedRow,
                setSelectedRow,
                selectedRowId,
                setSelectedRowId,
                setVisibleModal,
                onCloseModal,
                onAddonOk,
                onCancel,
                onPlanOk,
                moduleId,
                setModuleId,
                isPlanUpdating,
                setIsPlanUpdating,
              }}
            />
          </Col>
        </Row>
      )}
      {/* <Col span={1}>
        <Divider type='vertical'/>
        </Col> */}
      {selectedOrg && (
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Requests
              {...{
                selectedOrg,
                visibleAddonModal,
                visiblePlanModal,
                selectedRow,
                selectedRowId,
                setVisibleModal,
                onCloseModal,
                onAddonOk,
                onCancel,
                setSelectedRow,
                setSelectedRowId,
                onPlanOk,
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedMenuItem: state.manageSideBar?.selectedMenuItem,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      selectTab: sidebarActions.selectTab,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(Plans);
