import axios from "axios";

const userData = localStorage.getItem("userData");
const user = userData && JSON.parse(userData);

const API_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_KEY,
    params: {
      access_token: user?.access_token,
      // access_token: "mayur23",
    },
  }),
  path: {
    users: "users",
    files: "files",
    manufacturer: "items/manufacturer",
    secondary_category: "items/secondary_category",
    products: "items/products",
    categories: "items/manufacturer_secondary_category",
    attributes: "items/secondary_category_attributes",
    collections: "items/collections",
    login: "auth/login",
    logout: "auth/logout",
  },
};

export default API_KEY;
