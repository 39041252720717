import React from 'react';
import { Skeleton } from 'antd';

function SkeletonLoader({ row }) {
  return (
    <Skeleton
      paragraph={{
        rows: row,
      }}
    />
  );
}

export default SkeletonLoader;
