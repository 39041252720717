export const SIZE_FIELD = {
  Size: [
    {
      id: `inches--1`,
      name: "inches",
      short_name: "inches",
    },
    {
      id: `cm--2`,
      name: "cm",
      short_name: "cm",
    },
  ],
};

export const getTemplateModalTypeText = (mode = "create", forBtn = false) => {
  switch (mode) {
    case "delete": {
      return "Delete";
    }
    case "create": {
      return "Create";
    }
    case "edit": {
      return forBtn ? "Update" : "Edit";
    }
    default: {
      return "Create";
    }
  }
};

export const getImageUrl = (
  height = 200,
  width = 200,
  quality = 40,
  assetId
) => {
  if (assetId)
    return `${process.env.REACT_APP_KEY}assets/${assetId}?transforms=[["resize",{"width":${width},"height":${height},"quality":${quality},"fit":"contain", "background": "white"}]]`;
  return `https://placehold.jp/24/eff0f2/808990/214x214.png?text=Image%20Not%20Found`;
};

export const defaultPreviewImg = `https://placehold.jp/24/eff0f2/808990/214x214.png?text=Image%20Not%20Found`;
