import { Checkbox, Row, Space, Typography } from "antd";

const { Text } = Typography;

const DeleteTemplate = ({ setIsChecked }) => {
  return (
    <Row className="excel-delete-template-subtext">
      <Text>
        This action can't be undone. Are you sure you want to delete this
        template?
      </Text>
      <Space className="excel-template-delete-checkbox">
        <Checkbox
          size="large"
          onChange={(event) => setIsChecked(event?.target?.checked)}
        >
          Yes, I'm sure
        </Checkbox>
      </Space>
    </Row>
  );
};

export default DeleteTemplate;
