import React from "react";
import s3ls from "s3-ls";
import AWS from "aws-sdk";
import s3tree from "s3-tree";
import traverse from "traverse";
import fs from "fs";
import { Row, Col, Card, Skeleton, Typography, Button, Input } from "antd";
import { saveToLocalFile } from "../../utils/common";
import PouchDB from "pouchdb-browser";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
// const parser = require("@rafaelcsva/parse_s3_to_tree_view");
const BUCKET_NAME = "sourcewizpublic";
const BUCKET_REGION = "sgp1";
const ACCESS_KEY_ID = "OHYWUSFSEPPUNKI6NPKV";
const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";
const SECRET_ACCESS_KEY = "mM5l7rMb3HsLRx7ZtlasF+xg9TUF+8AYQPhzzw7po74";

const Index = () => {
  const navigateTo = useNavigate();
  const { Title, Text } = Typography;
  const { Search } = Input;
  const spacesEndpoint = new AWS.Endpoint(ENDPOINT);
  AWS.config.update({
    endpoint: spacesEndpoint,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3();
  const generator = s3tree({ bucket: BUCKET_NAME, s3: s3 });

  const db = new PouchDB("directories");

  const [folders, setFolders] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const initDirectories = () => {
    getDirectories().then((res) => {
      const directories = res.map((item) => ({
        name: item,
        path: item,
      }));

      db.put({
        _id: "root",
        directories: directories,
      })
        .then(function (response) {
          // handle response
        })
        .catch(function (err) {
          console.log(err);
        });

      setFolders(directories);
      setAllFolders(directories);
    });
  };

  useEffect(() => {
    db.get("root")
      .then(function (doc) {
        if (doc.directories) {
          setFolders(doc.directories);
          setAllFolders(doc.directories);
        } else {
          initDirectories();
        }
        // handle doc
      })
      .catch(function (err) {
        initDirectories();
        console.log(err);
      });
  }, []);

  const getDirectories = async () => {
    const tree = await generator.generate("", 0);
    const folders = Object.keys(tree);
    return folders;
  };

  const syncFolders = async () => {
    setIsLoading(true);
    getDirectories().then((res) => {
      const directories = res.map((item) => ({
        name: item,
        path: item,
      }));

      db.get("root")
        .then(function (doc) {
          return db.put({
            _id: "root",
            _rev: doc._rev,
            directories: directories,
          });
        })
        .then(function (response) {
          setIsLoading(false);
          setFolders(directories);
          setAllFolders(directories);
        })
        .catch(function (err) {
          setIsLoading(false);
          console.log(err);
        });
    });
  };

  const searchFolders = (search) => {
    if (search) {
      const filteredFolders =
        allFolders.filter((item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        }) || [];

      setFolders(filteredFolders);
      // setIsLoading(true);
      // const matchingImages = paths.filter((item) => {
      //   const imageDelimiter = item.lastIndexOf("/");
      //   const imageName = item.substring(imageDelimiter, item.length - 1);
      //   return imageName.toLowerCase().includes(search.toLowerCase());
      // });
      // setFilteredResults(matchingImages);
      setIsLoading(false);
    } else {
      setFolders(allFolders);
    }
  };

  return folders.length ? (
    !loading ? (
      <>
        <Row justify="space-between">
          <Col>
            <Search onSearch={searchFolders} />
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              size="large"
              loading={loading}
              onClick={syncFolders}
            >
              Sync Folders
            </Button>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <Row gutter={[16, 16]}>
              {folders.map((folder) => (
                <Col key={folder.name} span={6} style={{ marginTop: "0.5em" }}>
                  <Card
                    // onClick={() => handleCardClick(user?.value)}
                    key={folder?.path}
                    hoverable
                    bordered
                    style={{ border: " 1px solid #CDCFCE" }}
                    onClick={() => {
                      navigateTo(`/image-search/${folder?.path}`);
                    }}
                  >
                    <Title level={4}>{folder?.name}</Title>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </>
    ) : (
      <Skeleton />
    )
  ) : (
    <Skeleton />
  );
};

export default Index;
