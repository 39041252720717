import { Button, Col, DatePicker, Input, InputNumber, Modal, message, Radio, Row, Select, Space, Spin, Table, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import Title from "antd/lib/typography/Title";
import { CodePipeline } from "aws-sdk";
import moment from "moment";

export default function SelectAddonBody({ selectedOrg, selectedRow, setSelectedRow, setFinalData, finalData, selectedRowId, onCancel, moduleId }) {
    const [addons, setAddons] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [data, setData] = useState([]);
    const [currentPlan, setCurrentPlan] = useState(undefined);
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = localStorage.getItem("userData");
    const user = userData && JSON.parse(userData);
    const fetchAddons = (selectedOrg) => {
        setLoading(true);
        setAddons([]);
        axios.get(`${process.env.REACT_APP_INTERNAL_API}plan/current`, 
                { 
                    params: {
                        "m_id":selectedOrg,
                        "access_token": user?.access_token
                    },
                    headers:{
                        "access-token":user?.access_token
                    }
                }
            ).then((res) =>{
                const plan_data = res?.data;
                if (res?.status === 200 && data){
                    setCurrentPlan(plan_data);
                    const final_temp = {"start_date":moment(plan_data?.start_date), "end_date":moment(plan_data?.end_date)};
                    setLoading(true);
                    axios.get(
                            `${process.env.REACT_APP_INTERNAL_API}addon/all/${plan_data?.plan?.id}`, 
                            {
                                headers:{
                                    "access-token":user?.access_token
                                },
                                params:{
                                    "module_id":moduleId
                                }
                            }
                        ).then((addon_res) => {
                        let addons_data = addon_res?.data
                        if(res?.status === 200 && addons_data && Array.isArray(addons_data)){
                            let unsorted_temp_data = {};
                            const sorted_addons_data = addons_data?.sort((a, b)=>{
                                return b?.core_addon?.priority - a?.core_addon?.priority
                            })
                            setData([]);
                            const addon_list = sorted_addons_data.map(addon => {                                
                                
                                unsorted_temp_data[addon?.core_addon?.id] = addon;
                                return {
                                    label: addon?.core_addon?.description,
                                    value: addon?.core_addon?.id,
                                    key: addon?.core_addon?.id,
                                }
                            });
                            if (addon_list === []){
                                onCancel();
                                message.warning("No addons avaialable in this plan.");
                                return
                            }
                            setLoading(false);
                            setAddons(addon_list);
                            setData(unsorted_temp_data);
                            setSelectedRow(selectedRow);
                            setFinalData({...final_temp,...unsorted_temp_data[selectedRow]});
                            //getting discounts
                            axios.get(`${process.env.REACT_APP_INTERNAL_API}discount/`, 
                                { 
                                    params: {
                                        "access_token": user?.access_token
                                    },
                                    headers:{
                                        "access-token":user?.access_token
                                    }
                                }
                            ).then((res) => {
                                if(res?.status===200){
                                    const discount_data = res?.data;
                                    const discount_list = discount_data.map((item)=>{
                                        return {
                                            label: `${item?.name} (${item?.description})`,
                                            value: item?.id,
                                            key: item?.id,
                                        }
                                    });
                                    setDiscounts(discount_list);
                                }
                            }).catch((err)=>{
                                console.log(err);
                            });

                            //getting currencies
                            axios.get(`${process.env.REACT_APP_INTERNAL_API}currency/`, 
                                    { 
                                        params: {
                                            "access_token": user?.access_token
                                        },
                                        headers:{
                                            "access-token":user?.access_token
                                        }
                                    }
                                ).then((res) => {
                                    if(res?.status===200){
                                        const currency_data = res?.data;
                                        const currency_list = currency_data.map((item)=>{
                                            return {
                                                label: `${item?.code} (${item?.symbol})`,
                                                value: item?.id,
                                                key: item?.id,
                                            }
                                        });
                                        setCurrencies(currency_list);
                                    }
                                }).catch((err)=>{
                                    console.log(err);
                                });
                            }
                            }).catch((err) => {
                                console.log(err,"In get Addon");
                                onCancel();
                                message.warning("No addons avaialable in this plan.");
                                setLoading(false);
                            })
                            setLoading(false);
                }
        }).catch((err) => {
            console.log(err);
            onCancel();
            setLoading(false);
        })
    }
    useEffect(() => {
        setAddons([]);
        setSelectedRow(selectedRow);
        fetchAddons(selectedOrg);
    }, [selectedRowId]);

    useEffect(()=>{
        if (moduleId){
            fetchAddons(selectedOrg);
        }
    }, [moduleId]);
    
    useEffect(() => {
        if (selectedRow in data){
            setFinalData({...finalData, ...data[selectedRow]});
        }
    }, [selectedRow]);

    const updateFinalData = (key, val) => {
        let newData = finalData;
        if (key==="start_date" || key==="end_date"){
            newData = {...newData,[key]:moment(val).format("YYYY-MM-DD")};
        }else{
            if (newData?.plan_addon){
                newData["plan_addon"] = {...newData?.plan_addon,[key]:val}
            }else{
                newData["plan_addon"] = {[key]:val}
            }
        }
        setFinalData({...finalData,...newData});
    }

    const updateData = (key, val) => {
        if (val && key && data[selectedRow]){
            let newData = data[selectedRow];
            if(newData["plan_addon"]){
                newData["plan_addon"] = {...newData["plan_addon"], [key]:val}
            }else{
                newData["plan_addon"] = {[key]:val}
            }
            setData({...data,selectedRow:{...data[selectedRow],...newData}});
        }
    }

    return (
        <Spin spinning={loading}>
            <Space direction="vertical" style={{width:"100%", justifyContent:"space-between", lineHeight:2}} >
                <Row className="modal-row">
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                                ID:
                            </Text>
                        </Row>
                        <Row>
                            <Input value={data[selectedRow]?.plan_addon?.id} style={{width:"100%"}} disabled />
                        </Row>
                    </Col>
                </Row>

                <Row className="modal-row" >
                    <Col>
                        <Row>
                            <Text type="secondary" >
                                Add On:
                            </Text>
                        </Row>
                        <Row>
                            <Select 
                                options={addons} 
                                value={selectedRow} 
                                onChange={(e)=>{
                                    setSelectedRow(e);
                                    if(data[selectedRow]){
                                        setFinalData({...finalData,...data[selectedRow]})
                                    }else{
                                        setFinalData({start_date:finalData?.start_date, end_date:finalData?.end_date});
                                    }
                                }}
                                style={{width:"100%"}} 
                                disabled={loading}
                                placeholder="Select Addon" />
                        </Row>
                    </Col>
                </Row>

                <Row className="modal-row" >
                    <Col span={24}>
                        <Row>
                            <Text type="secondary" >
                                Add Type:
                            </Text>
                        </Row>
                        <Row>
                            <Radio.Group 
                                value={data[selectedRow]?.plan_addon?.type} 
                                style={{ 
                                    width:"100%", 
                                    justifyContent:"space-between", 
                                    alignContent:"center", 
                                    height:35
                                }}
                                disabled={true}
                                // onChange={
                                //     (e)=>{
                                //         updateFinalData("type",e?.target?.value);
                                //         updateData("type",e?.target?.value)
                                //     }
                                // } 
                            >
                                    <Row style={{marginTop:5, justifyContent:"space-between", height:"100%"}}>
                                        <Col span={11} className="modal-radio" >
                                            <Radio value="recurring" className="radio-class" style={{ display:"block"}} >Recurring</Radio>
                                        </Col>
                                        <Col span={11} className="modal-radio" >
                                            <Radio value="one-time" style={{ display:"block"}}>One time</Radio>
                                        </Col>
                                    </Row>
                            </Radio.Group>
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col>
                        <Row>
                            <Text type="secondary" >
                                Discount:
                            </Text>
                        </Row>
                        <Row>
                            <div style={{width:"100%", minWidth:"100%"}} >
                            <Select 
                                options={discounts} 
                                style={{width:"100%", minWidth:"100%"}}
                                placeholder="Select Discount"
                                value={finalData?.plan_addon?.discount}
                                disabled={loading}
                                onChange={(e)=>{
                                    updateFinalData("discount",e);
                                }}
                            />    
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-between" className="modal-row" >
                    <Col span={12} style={{paddingRight:5}} >
                        <Row>
                            <Text type="secondary" >
                                Start Date
                            </Text>
                        </Row>
                        <Row>
                            <DatePicker 
                                showToday 
                                style={{width:"100%"}} 
                                value={finalData?.start_date? moment(finalData?.start_date):""} 
                                disabled={loading}
                                onChange={(date, dateString)=>{
                                    if(date){
                                        updateFinalData("start_date", date);
                                    }
                                }} />
                        </Row>
                    </Col>
                    <Col span={12} style={{paddingLeft:5}} >
                        <Row>
                            <Text type="secondary" >
                                End Date
                            </Text>
                        </Row>
                        <Row>
                            <DatePicker 
                                showToday 
                                style={{width:"100%"}} 
                                value={finalData?.start_date? moment(finalData?.end_date):""} 
                                disabled={loading}
                                onChange={(date, dateString)=>{
                                    if(date){
                                        updateFinalData("end_date", date);
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col style={{width:"100%"}}>
                        <Row>
                            <Text type="secondary" >
                                Amount:
                            </Text>
                        </Row>
                        <Row>
                            <Input.Group compact >
                                <InputNumber 
                                    value={parseFloat(data[selectedRow]?.plan_addon?.amount)} 
                                    style={{width:"70%"}} 
                                    controls={false}
                                    disabled={loading}
                                    onChange={(val)=>{
                                        updateFinalData("amount", val);
                                        updateData("amount",val);
                                    }}
                                    stringMode />
                                <Select 
                                    options={currencies} 
                                    style={{width:"30%"}}
                                    value={data[selectedRow]?.plan_addon?.currency_id}
                                    disabled={loading}
                                    onChange={(value)=>{
                                        updateFinalData("currency_id",value);
                                        updateData("currency_id",value);
                                    }}
                                />
                            </Input.Group>
                        </Row>
                    </Col>
                </Row>
                <Row className="modal-row" >
                    <Col style={{width:"100%"}}>
                        <Row>
                            <Text type="secondary" >
                                Limit:
                            </Text>
                        </Row>
                        <Row>
                            <InputNumber 
                                value={parseInt(data[selectedRow]?.plan_addon?.limit)} 
                                style={{width:"100%"}} 
                                controls={false} 
                                disabled={loading}
                                onChange={(val)=>{
                                    updateData("limit", val);
                                    updateFinalData("limit", val);
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
            </Space>
        </Spin>
    )
}