import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Typography,
  Select,
  Spin,
  Modal,
  Popconfirm,
  message,
} from "antd";
import { DeleteFilled, SettingFilled } from "@ant-design/icons";
import API_KEY from "../../api";
import { useNavigate } from "react-router-dom";
import { adminMails, adminMailsForOtp } from "../../assets/Email";
import { allowedUsers } from "../Approvals/allowedUsers";
const { Title, Text } = Typography;
const { Option } = Select;
export default function Index({ orgs }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const navigate = useNavigate();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [mappedOrgs, setMappedOrgs] = useState([]);

  const [email, setEmail] = useState({});
  const [otpEnabledDetail, setOtpEnabledDetails] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpVerification, setOtpVerification] = useState(null);
  const [otpSettingsChanged, setOtpSettingsChanged] = useState(false);

  const [isAllowed, setIsAllowed] = useState(false);

  const localMail = JSON.parse(localStorage.getItem("userLog"));
  useEffect(() => {
    const output = mappedOrgs.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: users
          .filter((user) => {
            return curr.key === user.orgId;
          })
          .map((item) => item.email),
      };
    }, {});

    const genKey = Object.entries(output).filter((res) => {
      if (res[1].length !== 0) {
        return true;
      }
      return false;
    });
    setEmail(Object.fromEntries(genKey));
  }, [mappedOrgs, users]);

  useEffect(() => {
    if (localMail && allowedUsers?.includes(localMail)) {
      setIsAllowed(true);
    }
  }, [localMail]);

  useEffect(() => {
    setMappedOrgs(
      orgs.map((item) => {
        return {
          label: `${item.name}`,
          value: item.id,
          key: item.id,
        };
      })
    );
  }, [orgs]);

  const getUsers = () => {
    setLoading(true);
    API_KEY.URL.get(API_KEY.path.users, {
      params: {
        fields:
          "org.email,org.id,org.name,id,first_name,last_name,email,is_admin,is_system_user,status,meta",
        limit: -1,
        filter: selectedOrg ? { org: { _eq: selectedOrg } } : null,
      },
    })
      .then((res) => {
        if (res?.data?.data) {
          setUsers(
            res?.data?.data.map((user) => {
              return {
                orgId: user?.org?.id,
                id: user?.id,
                first_name: user?.first_name ?? " -- ",
                last_name: user?.last_name ?? " -- ",
                email: user?.email ?? " -- ",
                orgnization: user?.org?.name ?? " -- ",
                is_system_user: user?.is_system_user ? "Yes" : "No",
                is_admin: user?.is_admin ? "Yes" : "No",
                status: user.status,
                otp_enabled:
                  user?.meta?.otp_verification === false
                    ? "Disabled"
                    : "Enabled" ?? "Enabled",
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };
  useEffect(() => {
    getUsers(selectedOrg);
  }, [selectedOrg, otpSettingsChanged]);

  const handleOtpEnabledModal = (record) => {
    setOtpEnabledDetails(record);
    setOrganizationId(record.id);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Mapped Organization ID",
      dataIndex: "orgId",
      key: "orgId",
      align: "center",
      isRender: true,
    },

    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      isRender: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      isRender: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",
      isRender: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      align: "center",
      isRender: true,
    },
    {
      title: "Admin",
      dataIndex: "is_admin",
      key: "is_admin",
      align: "center",
      isRender: true,
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      isRender: true,
    },

    {
      title: "System User",
      dataIndex: "is_system_user",
      key: "is_system_user",
      align: "center",
      isRender: true,
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      title: "Otp Enabled",
      dataIndex: "otp_enabled",
      key: "otp_enabled",
      align: "center",
      isRender: adminMailsForOtp?.includes(localMail),
      render: (_, record) => {
        return (
          <Button
            style={{ margin: 0, padding: 0 }}
            type="link"
            onClick={() => {
              handleOtpEnabledModal(record);
            }}
          >
            {record?.otp_enabled}
          </Button>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "operation",
      isRender: adminMails?.includes(localMail),
      render: (_, record) => {
        return (
          <>
            <Row justify="space-around">
              <Popconfirm
                title="Sure to Delete?"
                disabled={
                  record.status === "archived" &&
                  (record.is_system_user === true ||
                    record.is_system_user === "Yes")
                    ? true
                    : false
                }
                onConfirm={() => handleDelete(record)}
              >
                <DeleteFilled
                  style={{
                    fontSize: 22,
                    color: "gray",
                    cursor:
                      record.status === "archived" &&
                      (record.is_system_user === true ||
                        record.is_system_user === "Yes")
                        ? "not-allowed"
                        : "pointer",
                    color:
                      record.status === "archived" &&
                      (record.is_system_user === true ||
                        record.is_system_user === "Yes") &&
                      "#D5D5D5",
                  }}
                />
              </Popconfirm>
            </Row>
          </>
        );
      },
    },
  ];

  const filteredColumns = () => {
    return columns.filter((item) => {
      return item.isRender;
    });
  };

  const handleDelete = (record) => {
    API_KEY.URL.patch(`${API_KEY.path.users + "/" + record?.id}`, {
      status: "archived",
      is_system_user: true,
    })
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          message.success("User successfully deleted!");
          getUsers();
        } else {
          message.success("Something went wrong!");
        }
      })
      .catch(() => {
        message.success("Something went wrong!");
      });
  };

  const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };

  const handleCloseModal = () => {
    setOtpEnabledDetails(null);
    setOrganizationId(null);
    setIsModalOpen(false);
    setOtpVerification(null);
  };

  const handleOtpChanges = () => {
    API_KEY.URL.patch(`${API_KEY.path.users}/${organizationId}`, {
      meta: {
        otp_verification: otpVerification,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          handleCloseModal();
          setOtpSettingsChanged((prev) => !prev);
        }
      })
      .catch((err) => console.error(err));
  };

  const changeOtpDetails = (value) => {
    let otpDetail = value === "Enabled" ? true : false;
    setOtpVerification(otpDetail);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            onBack={() => null}
            title={
              <Row>
                <Title level={3}>Users</Title>
                <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
                  ({users.length})
                </Text>
              </Row>
            }
            subTitle="List of all users in the system and you can do operations on them"
            extra={
              <>
                <Button
                  type="primary"
                  onClick={() => navigate("/create-new-user")}
                >
                  Create New
                </Button>

                {adminMails.includes(localMail) ? (
                  <Button
                    type="primary"
                    onClick={() => navigate("/users/change-asociated-account")}
                  >
                    Change Associated Account
                  </Button>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Search by MID, Manufacturer, or user"
            options={mappedOrgs}
            onChange={handleOrgChange}
            filterOption={(input, option) => {
              return (
                option?.label.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0 ||
                (email[option["key"]] || []).includes(input) ||
                option.value.toString() === input
              );
            }}
            allowClear
            onClear={() => setSelectedOrg("")}
          ></Select>
        </Col>
      </Row>
      <Divider />

      <Row>
        <Col span={24}>
          <Spin spinning={loading}>
            <Table
              dataSource={users}
              columns={filteredColumns()}
              bordered
              size="large"
              pagination={{ pageSize: pageSize }}
              onChange={(pagination, filters, sorter) => {
                setPageSize(pagination?.pageSize);
              }}
            />
          </Spin>
        </Col>
      </Row>
      <Modal
        visible={isModalOpen}
        title={"OTP Enable/Disable"}
        onCancel={handleCloseModal}
        onOk={handleOtpChanges}
      >
        <Select
          placeholder={`Toggle Otp Enabled/Disbaled`}
          style={{ width: 200 }}
          onChange={changeOtpDetails}
        >
          {[
            { label: "Enable", value: "Enabled" },
            { label: "Disable", value: "Disabled" },
          ].map((item) => {
            return (
              <Option
                value={item.value}
                disabled={item.value === otpEnabledDetail?.otp_enabled}
              >
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Modal>
    </div>
  );
}
