import PYTHON_KEY from "./pythonApis"

const userData = localStorage.getItem("userData");
const user = userData && JSON.parse(userData);
const pythonBaseUrl = process.env.REACT_APP_INTERNAL_API;

export const getAllAttribute = (id) => {
  let url = `?types=product&status=published&manufacturer_id=${id}`
  return PYTHON_KEY.URL.get(`${PYTHON_KEY.path.attributes}/${url}`, {
    headers: { 'access-token': `${user?.access_token}` },
  });
};

export const deleteAttribute = (id,type,manufacturer_id) => {
  return PYTHON_KEY.URL.delete(
    `${PYTHON_KEY.path.attributes}/?id=${id}&type=${type}&manufacturer_id=${manufacturer_id}`,
    {
      headers: { 'access-token': `${user?.access_token}` },
    }
  );
};

export const updateAttribute = (data,manufacturer_id) => {
  return PYTHON_KEY.URL.patch(`${PYTHON_KEY.path.attributes}/batch?manufacturer_id=${manufacturer_id}`, data, {
    headers: { 'access-token': `${user?.access_token}` },
  });
};

export const createNewAttribute = (payload,manufacturer_id) => {
  return PYTHON_KEY.URL.post(`${PYTHON_KEY.path.attributes}/?manufacturer_id=${manufacturer_id}`, payload, {
    headers: { 'access-token': `${user?.access_token}` },
  });
};

export const editAttribute = (payload,id,manufacturer_id) => {
  return PYTHON_KEY.URL.patch(
    `${PYTHON_KEY.path.attributes}?id=${id}&manufacturer_id=${manufacturer_id}`,
    payload,
    {
      headers: { 'access-token': `${user?.access_token}` },
    }
  );
};

export const getMeasurements = () => {
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}uom/uom?access_token=${user?.access_token}`);
};
