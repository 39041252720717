import Layout from "./components/Layout/index";
import { BrowserRouter } from "react-router-dom";
import SignIn from "./components/SingIn/index";
import { useGlobal } from "./store/index";
import { Provider } from 'react-redux';
import { store } from "./store";

function App() {
  const [globalState, globalActions] = useGlobal();

  const { isLoggedIn } = globalState;
  return <Provider store={store}><BrowserRouter>{isLoggedIn ? <Layout /> : <SignIn />}</BrowserRouter></Provider>;
}

export default App;
