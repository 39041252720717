import React, { useState, useEffect, useCallback } from "react";
import {
  PageHeader,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Typography,
  Popconfirm,
  Spin,
  Modal,
  Select,
  Menu,
  Input,
} from "antd";
import { DeleteFilled, SettingFilled, DownOutlined } from "@ant-design/icons";
import API_KEY from "../../api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Types } from "aws-sdk/clients/cloudformation";
const { Title, Text } = Typography;
const { Option } = Select;
export default function Index({ orgs }) {
  const [org, setorg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  // const [orgs, setOrgs] = useState([]);
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subCatModal, setShowSubCatModal] = useState(false);
  const [subCatDetails, setSubCatDetails] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [selectOrganization, setSelectOrganization] = useState("");
  const [subCategory, setSubCategory] = useState(null);
  const [slugModal, setShowSlugModal] = useState(false);
  const [manufacturerSlug, setSlug] = useState("");

  // Modals
  const showModal = (id) => {
    setIsModalVisible(true);

    setOrganizationId(id);
    console.log(id);
  };

  const handleSlug = (record) => {
    setSlug(record.slug && record.slug !== "-" ? record.slug : "");
    setOrganizationId(record.id);
    setShowSlugModal(true);
  };

  const handleViewSubCat = (record) => {
    setSubCatDetails(record);
    setOrganizationId(record.id);
    setShowSubCatModal(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    console.log(organizationId);
    // Patch call
    API_KEY.URL.patch(`${API_KEY.path.manufacturer}/${organizationId.id}`, {
      type: selectOrganization,
    })
      .then((res) => {
        if (selectOrganization === organizationId.type) {
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveSubCategory = () => {
    if (subCategory === null) {
      cancelSubCategory();
      return;
    }
    // Patch call
    API_KEY.URL.patch(`${API_KEY.path.manufacturer}/${organizationId}`, {
      recommendation_preferences: {
        ...subCatDetails.recommendation_preferences,
        sub_category: subCategory,
      },
    })
      .then((res) => {
        cancelSubCategory();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveSlug = () => {
    if (!manufacturerSlug || manufacturerSlug === "-") {
      cancelSlug();
      return;
    }
    // Patch call
    API_KEY.URL.patch(`${API_KEY.path.manufacturer}/${organizationId}`, {
      slug: manufacturerSlug,
    })
      .then((res) => {
        cancelSlug();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeSubCat = (value) => {
    setSubCategory(value);
  };

  const slugify = (event) => {
    const slugifiedText = convertToSlug(event?.target?.value || "");
    setSlug(slugifiedText);
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const cancelSubCategory = () => {
    setSubCatDetails(null);
    setSubCategory(null);
    setOrganizationId(null);
    setShowSubCatModal(false);
  };
  const cancelSlug = () => {
    setSlug(null);
    setOrganizationId(null);
    setShowSlugModal(false);
  };
  const filters = useCallback(() => {
    var filters;
    filters = {
      _and: [
        {
          status: {
            _nin: ["archived"],
          },
        },
        {
          id: {
            _gt: 53,
          },
        },
        {
          name: {
            _ncontains: "Deepam",
          },
        },
        {
          name: {
            _ncontains: "Release",
          },
        },
        {
          name: {
            _ncontains: "Testing",
          },
        },
      ],
    };
    if (selectedOrg) {
      filters._and.push({
        id: {
          _eq: selectedOrg || "",
        },
      });
    }
    return filters;
  }, [selectedOrg]);
  const fetchOrgs = useCallback(() => {
    const mappedData = orgs.map((org, index) => {
      return {
        key: index,
        id: org?.id,
        name: org?.name ?? ' -- ',
        city: org?.city ?? ' -- ',
        country: org?.country ?? ' -- ',
        type: org?.type ?? '--',
        slug: org.slug ?? '-',
        recommendation_preferences: {
          ...org.recommendation_preferences,
          sub_category: org?.recommendation_preferences?.sub_category || false,
        },
      };
    });
    setorg(mappedData);
    setAllOrgsData(mappedData);
  }, [orgs]);

  /// get all org api call
  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs]);

  // create cloumns for table
  const columns = [
    {
      title: " ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      align: "center",
      filters: org
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.city === value.city)
        )
        .filter((res) => res.city !== " -- ")
        .map((res) => {
          return {
            text: res.city,
            value: res.city,
          };
        }),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.city.startsWith(value),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
    },
    {
      title: "Slug (Branded Link)",
      dataIndex: "slug",
      key: "slug",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            style={{ margin: 0, padding: 0 }}
            type="link"
            onClick={() => {
              handleSlug(record);
            }}
          >
            {record.slug === "-" ? "-Add New-" : record.slug}
          </Button>
        );
      },
    },

    {
      title: "Sub Category Enabled",
      dataIndex: "sub_category",
      key: "sub_category",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            style={{ margin: 0, padding: 0 }}
            type="link"
            onClick={() => {
              handleViewSubCat(record);
            }}
          >
            {record?.recommendation_preferences?.sub_category ? "Yes" : "No"}
          </Button>
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (record, id) => {
        return <a onClick={() => showModal(id)}>{record}</a>;
      },
    },
  ];

  // handle orgnization change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    setSelectedCategory(null);

    if (value) {
      setorg((state) => {
        return state.filter((item) => item.id === value);
      });
    } else {
      setorg(allOrgsData);
    }
  };

  // handle category change
  // const handleCategoryChange = (value) => {
  //   setSelectedCategory(value);
  // };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRows(selectedRows);
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === 'Disabled User',
  //     // Column configuration not to be checked
  //     key: record.key,
  //   }),
  // };

  const handleSelected = (value) => {
    setSelectOrganization(value);
  };

  // Dropdown Items
  const menu = (
    <Menu onClick={handleSelected}>
      <Menu.Item key="internal_demo">
        <Text>internal_demo</Text>
      </Menu.Item>
      <Menu.Item key="demo">
        <Text>demo</Text>
      </Menu.Item>
      <Menu.Item key="customer_account">
        <Text>customer_account</Text>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      {/* page header starts here */}
      <Modal
        title="Organization type"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="500px"
        height="1500px"
      >
        <Select
          placeholder="Choose organization type"
          style={{ width: 200 }}
          onChange={handleSelected}
        >
          <Option value="demo">demo</Option>
          <Option value="internal_demo">internal_demo</Option>
          <Option value="customer_account">customer_account</Option>
        </Select>
      </Modal>
      <Modal
        title="Enable/Disable Sub category"
        visible={subCatModal}
        onOk={saveSubCategory}
        onCancel={cancelSubCategory}
      >
        <Select
          placeholder={`Toggle Sub Category`}
          style={{ width: 200 }}
          onChange={changeSubCat}
        >
          {[
            { label: "Enable", value: true },
            { label: "Disable", value: false },
          ].map((item) => {
            return (
              <Option
                value={item.value}
                disabled={
                  item.value ===
                  subCatDetails?.recommendation_preferences?.sub_category
                }
              >
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Modal>
      <Modal
        title="Edit Manufacturer Slug/ Branded Link"
        visible={slugModal}
        onOk={saveSlug}
        onCancel={cancelSlug}
      >
        <Input
          placeholder={`Spaces and special characters not allowed`}
          onChange={slugify}
          value={manufacturerSlug}
        />
      </Modal>
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={
              <Row>
                <Title level={3} style={{ fontFamily: "Gilroy" }}>
                  Organisations
                </Title>
                <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
                  ({total})
                </Text>
              </Row>
            }
            subTitle="List of all organisations in the system and you can do operations on them"
            extra={
              <Button
                type="primary"
                onClick={() => navigate("/organization-form")}
              >
                Create New
              </Button>
            }
          />
        </Col>
      </Row>
      {/* page header ends here */}
      <Row>
        <Col span={6}>
          <Select
            showSearch
            style={{ width: "80%" }}
            placeholder="Search by MID or name"
            // options={orgs}
            onChange={handleOrgChange}
            filterOption={(input, option) => {
              return (
                option?.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0 || option.value.toString() === input
              );
            }}
            allowClear
            onClear={() => setSelectedOrg("")}
          >
            {org.map((org) => (
              <Option key={org?.id} value={org?.id}>
                {org?.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Divider />
      {/* table header starts here */}
      <Row>
        <Col span={24}>
          <Spin spinning={loading} size="large">
            <Table
              dataSource={org}
              columns={columns}
              bordered
              size="large"
              pagination={{ pageSize: pageSize, current: page, total: total }}
              onChange={(pagination, filters, sorter, extra) => {
                setPageSize(pagination?.pageSize);
                setPage(pagination?.current);
              }}
            />
          </Spin>
        </Col>
      </Row>
      {/* table header ends here */}
    </div>
  );
}
