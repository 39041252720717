import { Button, Form, Radio, Row, Space, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const ExcelComponent = ({
  excelFileType,
  setExcelFileType,
  mode,
  setExcelFile,
  excelFile,
}) => {
  const [defaultExcel, setDefaultExcel] = useState(excelFile);

  useEffect(() => {
    if (excelFile?.url) {
      setDefaultExcel(excelFile);
    }
  }, [excelFile]);

  const handleChangeExcelType = (e) => {
    const value = e?.target?.value;
    setExcelFileType(value);
    if (value === "existing") {
      setExcelFile(defaultExcel);
    }
  };

  const handleFileChange = ({ file }) => {
    setExcelFile(file?.originFileObj);
  };

  return (
    <>
      {mode === "edit" && (
        <>
          {excelFileType === "existing" && (
            <Row className="excel-template-upload-file-row">Upload Excel</Row>
          )}
          <Row className="excel-template-upload-file-row">
            <Radio.Group
              value={excelFileType}
              onChange={(e) => handleChangeExcelType(e)}
            >
              <Radio value="existing">Select Existing</Radio>
              <Radio value="new">Upload new</Radio>
            </Radio.Group>
          </Row>
        </>
      )}
      <Form.Item
        name="excel_file"
        label={`${excelFileType === "existing" ? "Uploaded" : "Upload"} Excel`}
        rules={[
          {
            required: excelFileType === "new" ? true : false,
            message: "Please upload excel file!",
          },
        ]}
      >
        <Upload
          accept=".xlsx"
          onChange={handleFileChange}
          fileList={excelFile ? [excelFile] : []}
          listType="picture"
          showUploadList={{
            showRemoveIcon: excelFileType === "existing" ? false : true,
          }}
          onRemove={() => setExcelFile(null)}
          customRequest={({ onSuccess }) => onSuccess("ok")}
        >
          {excelFileType === "new" && (
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          )}
        </Upload>
      </Form.Item>
    </>
  );
};

export default ExcelComponent;
