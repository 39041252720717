import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Typography,
  Popconfirm,
  Spin,
  Select,
  notification,
  Modal,
  Form,
  Checkbox,
  message,
} from "antd";
import { DeleteFilled, SettingFilled } from "@ant-design/icons";
import API_KEY from "../../api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
const { Title, Text } = Typography;

export default function Index() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportConfig, setExportConfig] = useState({
    mid: "",
    render_images: false,
    category_wise_sheets: false,
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [orgs, setOrgs] = useState([]);
  const [allOrgsData, setAllOrgsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const filters = () => {
    var filters;
    if (selectedCategory) {
      filters = { secondary_category: { id: { _eq: selectedCategory } } };
    }
    if (selectedOrg) {
      filters = {
        _and: [
          {
            status: {
              _in: ["published", "mobile-published"],
            },
          },
          {
            type: {
              _eq: "product",
            },
          },
          {
            manufacturer: { _eq: selectedOrg },
          },
        ],
      };
    }

    if (selectedCategory && selectedOrg) {
      filters = {
        // "product_id": { "_eq": "12023" },
        secondary_category: { id: { _eq: selectedCategory } },
        manufacturer: { _eq: selectedOrg },
      };
    }
    return filters;
  };

  const fetchProducts = (page, selectedCategory, selectedOrg) => {
    setLoading(true);
    API_KEY.URL.get(API_KEY.path.products, {
      params: {
        fields:
          "id,product_images.directus_files_id,product_id,secondary_category.name,date_created,date_updated,manufacturer",
        // limit: -1,
        meta: "*",
        sort: "-date_created",
        page: page,
        filter: filters(),
      },
    })
      .then((res) => {
        if (res?.data?.meta) {
          setTotal(res.data.meta.filter_count);
        }
        if (res?.data?.data) {
          console.log("data", res?.data?.data);
          setProducts(
            res?.data?.data.map((product, index) => {
              return {
                key: index,
                id: product?.id,
                product_images: product?.product_images ?? " -- ",
                product_id: product?.product_id ?? " -- ",
                secondary_category: product?.secondary_category?.name ?? " -- ",
                date_created: product?.date_created
                  ? moment(product?.date_created).startOf("hour").fromNow()
                  : " -- ",
                date_updated: product?.date_updated
                  ? moment(product?.date_updated).startOf("hour").fromNow()
                  : " -- ",
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  /// get all products api call
  useEffect(() => {
    fetchProducts(page, selectedCategory, selectedOrg);
  }, [page, selectedCategory, selectedOrg]);

  // get all orgnizations api call
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        fields:
          "id,name,secondary_categories.secondary_category_id.name,secondary_categories.secondary_category_id.id",
        limit: -1,
        meta: "*",
        // sort:"sort,-date_created",
        page: page,
        // filter: filters(),
        filter: {
          _and: [
            {
              status: {
                _nin: ["archived"],
              },
            },
            {
              id: {
                _gt: 53,
              },
            },
            {
              name: {
                _ncontains: "Deepam",
              },
            },
            {
              name: {
                _ncontains: "Release",
              },
            },
            {
              name: {
                _ncontains: "Testing",
              },
            },
          ],
        },
      },
    }).then((res) => {
      if (res?.data?.data) {
        setAllOrgsData(res?.data?.data);
        // setOrgs(
        //   res?.data?.data.map((org) => {
        //     return {
        //       label: `${org.name}`,
        //       value: org.id,
        //       key: org.id,
        //     };
        //   })
        // );
      }
    });
  }, []);

  // create cloumns for table
  const columns = [
    {
      title: "Image",
      dataIndex: "product_images",
      key: "product_images",
      align: "center",
      render: (text, record) => (
        <img
          style={{ borderRadius: 8 }}
          alt="product"
          src={
            record?.product_images?.[0]?.directus_files_id
              ? `${process.env.REACT_APP_KEY}assets/${record?.product_images?.[0]?.directus_files_id}?transforms=[["resize",{"width":120,"height":120,"fit":"contain","background":{"r":242,"g":242,"b":242}}]]`
              : "http://placehold.jp/24/0a8f8d/ffffff/120x120.png?text=Product Image"
          }
        />
      ),
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
      align: "center",
    },
    {
      title: "Secondary Category",
      dataIndex: "secondary_category",
      key: "secondary_category",
      align: "center",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      align: "center",
    },
    {
      title: "Date Updated",
      dataIndex: "date_updated",
      key: "date_updated",
      align: "center",
    },
    // {
    //   title: "Operation",
    //   dataIndex: "operation",
    //   align: "center",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row justify="space-around">
    //           <Popconfirm
    //             title="Sure to Delete?"
    //             onConfirm={() => handleDelete(record)}
    //           >
    //             <DeleteFilled style={{ fontSize: 22, color: "gray" }} />
    //           </Popconfirm>
    //           <SettingFilled style={{ fontSize: 22, color: "gray" }} />
    //         </Row>
    //       </>
    //     );
    //   },
    // },
  ];

  // handle delete product
  const handleDelete = (record) => {
    // API_KEY.URL.delete(API_KEY.path.products + "/" + record?.id).then((res) => {
    //   console.log(res);
    //   fetchProducts(page, selectedCategory, selectedOrg);
    // });
  };

  const handleOrgChangeForExport = (key, value) => {
    setExportConfig((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  // handle orgnization change
  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    setSelectedCategory(null);

    const findCategories = allOrgsData.find((org) => org.id === value);

    setCategories(
      findCategories.secondary_categories.map((category) => {
        return {
          label: category?.secondary_category_id?.name,
          value: category?.secondary_category_id?.id,
          key: category?.secondary_category_id?.id,
        };
      })
    );
  };

  // handle category change
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      key: record.key,
    }),
  };

  const handleExportSubmit = () => {
    const currentEmail = JSON.parse(localStorage.getItem("userLog"));

    if (!exportConfig.mid) {
      message.error("Please select manufacturer.");
      return;
    } else {
      setExportLoading(true);
      axios
        .post(`${process.env.REACT_APP_IMPORTLIB_API}bulk-export/excel?`, {
          ...exportConfig,
          notifyEmail: currentEmail,
        })
        .then((response) => {
          if (response.status === 200) {
            setExportLoading(false);
            message.success(
              "Export started. Exported data will be sent to your Sourcewize email."
            );
            setShowExportModal(false);
            setExportConfig((state) => {
              return {
                mid: "",
                category_wise_sheets: false,
                render_images: false,
              };
            });
          }
        })
        .catch((err) => {
          setExportLoading(false);
          message.error("Error exporting data. Please try again");
        });
    }
  };
  const handleDeleteAll = () => {
    notification.info({
      message: "Coming Soon",
      description: "This feature is coming disabled for now",
    });
    // setLoading(true);
    // API_KEY.URL.delete(API_KEY.path.products, {
    //   data:
    //  selectedRows.map((row) => row.id),

    // }).then((res) => {
    //   setLoading(false);
    //   console.log(res);
    //   setSelectedRows([]);
    //   fetchProducts(page, selectedCategory, selectedOrg);

    // }).catch(
    //   (err) => {
    //     setLoading(false);
    //     console.log(err);
    //   }
    // )
  };
  const isBulkActionDisabled = process.env.REACT_APP_BULK_ACTION_DISABLED === "true";
  return (
    <div>
      <Modal
        visible={showExportModal}
        closable
        confirmLoading={exportLoading}
        onCancel={() => {
          setShowExportModal(false);
        }}
        onOk={handleExportSubmit}
      >
        <Select
          style={{ width: "80%" }}
          placeholder="Select Organization"
          onChange={(value) => {
            handleOrgChangeForExport("mid", value);
          }}
          showSearch
          filterOption={(input, option) => {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        >
          {allOrgsData.map((org) => {
            return (
              <Select.Option value={org?.id} key={org?.id}>
                {org?.name}
              </Select.Option>
            );
          })}
        </Select>
        <br />
        <br />
        {/* <Form.Item name="render_images" label="Export with images?"> */}
        <Checkbox
          defaultChecked={false}
          onChange={(e) => {
            handleOrgChangeForExport("render_images", e.target.checked);
          }}
        >
          Export with images?
        </Checkbox>
        {/* </Form.Item> */}
        {/* <Form.Item name="category_wise_sheets" label="Split Excel by Category?"> */}
        <Checkbox
          onChange={(e) => {
            handleOrgChangeForExport("category_wise_sheets", e.target.checked);
          }}
          defaultChecked={false}
        >
          Category wise excel sheets?
        </Checkbox>
        {/* </Form.Item> */}
      </Modal>
      {/* page header starts here */}
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={
              <Row>
                <Title level={3} style={{ fontFamily: "Gilroy" }}>
                  Products
                </Title>
                <Text type="secondary" style={{ margin: 4, fontSize: 14 }}>
                  ({total})
                </Text>
              </Row>
            }
            subTitle="List of all products in the system and you can do operations on them"
            extra={
              <>
                <Button type="primary" onClick={() => setShowExportModal(true)} disabled={isBulkActionDisabled}>
                  Export Excel
                </Button>
                <Button type="primary" onClick={() => navigate("/update-form")} disabled={isBulkActionDisabled}>
                  Bulk Update
                </Button>
                <Button
                  type="primary"
                  onClick={() => navigate("/product-form")}
                  disabled={isBulkActionDisabled}
                >
                  Bulk Upload
                </Button>
              </>
            }
          />
        </Col>
      </Row>
      {/* page header ends here */}
      {/* filter header starts here */}
      <Row>
        <Col span={6}>
          <Select
            style={{ width: "80%" }}
            placeholder="Select Organization"
            // options={orgs}
            onChange={handleOrgChange}
            showSearch
            filterOption={(input, option) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {allOrgsData.map((org) => {
              return (
                <Select.Option value={org?.id} key={org?.id}>
                  {org?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        {/* <Col span={6}>
          <Select
            style={{ width: "80%" }}
            placeholder="Select categories"
            options={categories}
            onChange={handleCategoryChange}
            value={selectedCategory}
          ></Select>
        </Col> */}
        <Col span={6}>
          {/* <Button onClick={()=>handleDeleteAll()} disabled={selectedRows.length > 0? false:true}>
            Delete{selectedRows.length > 0 ? `(${selectedRows.length})` : null}
          </Button> */}
        </Col>
      </Row>
      {/* filter header ends here */}
      <Divider />
      {/* table header starts here */}
      <Row>
        <Col span={24}>
          <Spin spinning={loading} size="large">
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              dataSource={products}
              columns={columns}
              bordered
              size="large"
              pagination={{ pageSize: pageSize, current: page, total: total }}
              onChange={(pagination, filters, sorter) => {
                setPageSize(pagination?.pageSize);
                setPage(pagination?.current);
              }}
            />
          </Spin>
        </Col>
      </Row>
      {/* table header ends here */}
    </div>
  );
}
